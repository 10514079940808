<template lang="pug">
  
  
  .home
   
    .row.mt-1
      .col-md-2
       
        ProjectFilter()
      .col-md-10
        
        b-card.mt-1
            h3 Text Rank
            
            b-table(:items="$store.getters.project.analysis", sortable, :fields="fields")
             
        
        
       
                    

</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import Config from '@/config'

import ProjectFilter from '@/views/ProjectFilter'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Analsis',
  
  data: function () {
    return {
        fields: [
          { key: 'text', label: 'Keyphrase', sortable: true, sortDirection: 'desc' },
          { key: 'count', label: 'Count', sortable: true, sortDirection: 'desc' },
          { key: 'rank', label: 'Text-Rank', sortable: true, sortDirection: 'desc' },
        
        ],
      currentPage: 0,
      s: {},
      q: "",
      query: "",
      uuid: null,
      event_results: [],
      selected: [],
      selectedNode: {},
      colors: {"retrieval": "rgba(200, 200, 200, 0.8)", "extraction": "rgba(200, 200, 200, 0.8)", "knowledge": "rgba(200, 200, 200, 0.8)", undefined: "rgba(200, 200, 200, 0.8)"},
      sizes: {"Org": 70, "Text": 10, "Topic": 20, "Website": 25, "Webpage": 15, "ImprintInfos": 10},
      current_actions: [{"label": "Remove", "name": "remove"}],
      graphName: ""
    }
  },

  mounted: function() {
    console.log(Config);
    this.host = Config.config.base_host;

    this.s = new window.sigma({                                                                                      
        renderer: {                                                        
            container: "gcontainer",                                        
            type: "canvas"
        },                                                                 
        settings: {
            labelThreshold: 2,
            //minArrowSize: 1,
            minEdgeSize: 1,
            maxEdgeSize: 3,
            maxNodeSize: 100,
            edgeLabelSize: 'proportional'
       }                                                                  
    });

    

  },


   computed: {

        ...mapFields([
            'query.keywords'
        ]),
  },

  methods: {

     display() {
            let height = window.innerHeight-70;
            height = window.innerHeight/2;
            
            this.$refs.sigmacontainer.style.cssText = "height: " + (height) + "px;";
            
            this.s.kill();
    
            this.s = new window.sigma({                                                                                      
                renderer: {                                                        
                    container: "gcontainer",                                        
                    type: "canvas"
                },  

                settings: {
                    labelThreshold: 1,
                    minArrowSize: 1,
                    minEdgeSize: 1,
                    maxEdgeSize: 3,
                    maxNodeSize: 30,
                    edgeLabelSize: 'proportional'                
                }                                                                  
            });

            this.s.bind('clickNode doubleClickNode rightClickNode', (e) => {
              this.selectedNode = e.data.node.data;
              this.showNodeActions(this.selectedNode);
            });

            console.log(this.$store.getters.project.data);

            if(this.$store.getters.project.data) {
              
              for(let n of this.$store.getters.project.data) {
                  console.log(this.$store.getters.project.data);
                  let size = 30;
                  if(n.count) {
                      console.log(n.count);
                      size = n.count;
                  }
                  this.s.graph.addNode({
                          // Main attributes:
                          id: n.uuid.replace(/ /g, ""),
                          label: n.name,
                          data: n,
                          // Display attributes:
                          x: Math.random()*2,
                          y: Math.random(),
                          size: size,
                          color: this.colors[0]
                  });
              }

            }


            /*for(let n of this.edges) {
               
                let label = "" + n.weight;
                
                this.s.graph.addEdge({
                        // Main attributes:
                        id: n.id.replace(/ /g, "") + Math.random(),
                        source: n.src.replace(/ /g, ""),
                        target: n.dst.replace(/ /g, ""),
                        weight: n.weight || 1,
                        label: label,
                        type: 'curve',
                        size: n.weight * 50,                       
                });

            }*/

            this.current_actions = [{"label": "Remove", "name": "remove"}];
          
            this.s.refresh();
            this.layoutGraph();

      },


    getData() {
        console.log(this.$store)
        this.$store.dispatch("getData");
        this.display();
    },

     layoutGraph: function() {
          this.s.startForceAtlas2({scalingRatio: 1, worker: true, barnesHutOptimize: true, edgeWeightInfluence: 1});
          setTimeout(()=>{
              this.s.stopForceAtlas2();
          }, 200 + this.$store.getters.project.data.length * 20);

      },


    signIn() {
        window.auth2.grantOfflineAccess().then(this.signInCallback);
    },

    signInCallback(authResult) {
        if (authResult['code']) {  
            this.confirmOAuth(authResult['code']).then(() => {
                location.href = "/search";
            });
        }
    },

    confirmOAuth(data) {
      
      let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Google-OAuth2-Type': 'server'
      };
      this.$store.commit("LOADING", true);
      return Vue.http.post(this.host+'/swap_token', data, {headers}).then((resp) => {
          localStorage.setItem('id_token', resp.data.access_token)
          Vue.http.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('id_token');   
          this.$store.commit("LOADING", false);
          return resp.data;
      });

    },



  },
  components: {

      ProjectFilter
  }
}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>