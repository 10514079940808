<template lang="pug">
    
  .home
    .row.mt-1
      .col-md-2
        ProjectFilter() 
      .col-md-2
      .col-md-4
        
        b-card.mt-1
          h3 Clustering {{$store.getters.project.data.length}} data points

          b-form()
            
            b-form-group(id="input-group-1", label="Cluster on fields:", label-for="cluster-target",  label-size="sm")
              v-select(:options="$store.getters.project.fields" v-model="selected_fields", :multiple="true")
             
                  b-form-checkbox(v-model="selected_fields", :value="option", :key="option").d-inline-block {{option}}

            b-form-group(id="input-group-1", label="Write clusters to columns:", label-for="cluster-target",  label-size="sm")
              b-input(v-model="target_field", id="cluster-target")
            
              
            b-form-group(id="input-group-1", label="Method", label-for="cluster-target",  label-size="sm")
              v-select(:options="method_options" v-model="method")
            
            b-form-group(id="input-group-1", label="Number of clusters", label-for="cluster-target",  label-size="sm", :description="'Runs on ' + $store.getters.project.data.length + ' items'")
              b-input(type="number", v-model="number_of_clusters", id="cluster-number")

            .float-right
              b-button(variant="primary") Run clustering

</template>

<script>
// @ is an alias to /src

//import Vue from 'vue'
import Config from '@/config'

import ProjectFilter from '@/views/ProjectFilter'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Cluster',
  
  data: function () {
    return {
        target_field: "cluster1",
        number_of_clusters: 20,
        method: "DBSCAN",
        method_options: ["DBSCAN", "K-MEANS"],
        selected_fields: []
     }
  },

  mounted: function() {

    this.host = Config.config.base_host;

  },

   computed: {

        ...mapFields([
            
           
        ]),
  },

  methods: {

    

  },
  components: {

      ProjectFilter
  }
}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>