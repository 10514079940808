<template lang="pug">

  .overview.sticky-container(data-v-sticky-container)

    .row
      .col-3(id="searchmenu_orient")
        .sidemenu-search(id="searchmenu")
              ProjectOverviewFilter(@search="getData()")
        
        
      .col-9()#list-group
        //.row
          .col-12
              .float-right(style="opacity: 0")
                  b-form-group(label="Sort by", label-for="sort-select" label-cols="4")
                    b-select(:options="sort_options", v-model="sortBy", style="max-width: 300px", size="sm", @change="getDataClear()")#sort-select
        .ml-3
          div()
            template(v-if="$auth.user.instance == 'hy'")
              b-row()
                b-col()
                  .float-right
                    b-btn-group()
                     
                      b-button(variant="outline-secondary", size="sm", @click="list_view = false", v-if="list_view")
                        i.fas.fa-th
                      b-button(variant="outline-secondary", size="sm", @click="list_view = true", v-if="!list_view")
                        i.fas.fa-list

            template(v-if="list_view")
              b-table( hover :items="projects" :fields="fields" small size="sm", @row-clicked="myRowClickHandler").small.mt-2

            tempale(v-else)    
              b-row(cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="2", cols-xxl="3").mt-2
              
                b-col(v-for="project in projects", v-if="!project.deleted_at").d-flex.align-items-stretch.mb-4.w-100
                    
                    ProjectCard(:project="project", @click="select_project(project)").h-100.w-100

</template>

<script>

import Vue from 'vue'
import Config from '@/config'
import ProjectCard from '@/components/ProjectCard.vue'
import ProjectOverviewFilter from '@/views/projects/ProjectOverviewFilter'
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'
import { mapFields } from 'vuex-map-fields';
import auth from '@/router/auth.js'

export default {
  
  name: 'ProjectOverview',
  
  data: function () {
      return {
        fields: [
        
          { key: 'name', label: 'Name', sortable: true },
          
          { key: 'custom_data.project_type', label: 'Type', sortable: true, formatter: (x)=>{ return Array.isArray(x) ? x.join(", ") : x} },
          { key: 'custom_data.tags', label: 'Tags', sortable: true, formatter: (x)=>{ return Array.isArray(x) ? x.join(", ") : x} },
          { key: 'custom_data.year', label: 'Year', sortable: true }
        ],
        list_view: false,
        auth: auth,
        sticky_options: {
          topSpacing: 100,
          bottomSpacing: 50 
        },
        sort_options: [{"text": "Last updated", "value": "updated_at"}, {"text": "Members", "value": "number_members"},  {"text": "Bookmarks", "value": "number_bookmarks"}, {"text": "Views", "value": "number_views"}],
        sortBy: null,
        projects: null
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.$store.state.sidebar_project.selected_item = null;
    this.filter.project_id = [];
    this.sidebar_visible = false;
    this.getData();
  
  },
  computed: {

    ...mapFields([
        'projects.query.filter.keywords',
        'projects.query.filter.custom_data',
        'projects.query.filter.bookmarked',
        'projects.query.filter.joined',
        'projects.query.filter',
        'sidebar_visible'
    ]),
  },

  methods: {

    myRowClickHandler(item) {
      this.$router.push({path: 'projects/' + item._id + '/dashboard'});
    },

    select_project(project) {
      this.$store.dispatch("start_loading");
      this.$store.state.sidebar_project.selected_item = project;
      Vue.http.get(`${Config.config.base_host}/projects/${project._id}`).then(res => {
        this.$store.state.sidebar_project.selected_item = res.body;
        this.$store.dispatch("stop_loading");
      });

    },

    

    getData() {
      //this.$store.dispatch('getProjects');

      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/projects/search`, this.filter).then(res => {
        this.projects = res.body;
        this.$store.dispatch("stop_loading");
      });

    }
     /* getData() {
       this.$store.dispatch('getProjects', null);
      }*/
     
  },
  directives: {
    "sticky": VueStickyDirective
  },
  components: {
      ProjectCard,
      ProjectOverviewFilter
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>