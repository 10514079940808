<template lang="pug">
  .search-filter
    b-form()
      

        b-card().shadow-sm.mt-1

            
            div(v-if="$store.getters.settings['categorie']")
              b-form-group(id="input-group-1", label="Category", label-for="category1", label-size="sm")                
                b-select(:options="$store.getters.settings['categorie'].values", id="category1", v-model="categorie")

            .float-right
                b-button(size="sm", variant="primary", @click="getData()") Run

</template>

<script>
// @ is an alias to /src

import Config from '@/config'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'ProjectFilter',


  data: function () {
    return {
      selected: 'No similarity search'
    }
  },

  mounted: function() {
  
     this.host = Config.config.base_host;
  
  },


       computed: {

        ...mapFields([
            'project.filter.categorie'
        ]),
  },
  

  methods: {
    
    getData() {
        console.log(this.$store)
        this.$store.dispatch("getData");
        
    },

  },
  components: {
    
  }
}

</script>
<style lang="scss">
/*.search-filter {
    position: fixed;
    margin-top: -50px;
    z-index: 100000;
}*/
</style>
