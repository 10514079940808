<template lang="pug">
  .topic2-modal
    b-modal(id="topic2-modal", title="Edit Table Columns", size="lg", ok-variant="outline-primary", cancel-variant="link", ref="columnref",  @ok="save()")
      .small.mt-1.mb-3 Select the colums which are relevant for your evaluation. You can pick them from different sources or basic information.
      hr
      b-form(size="sm", autocomplete="off")
        .form-row()
          .col-4
            label Data categories
            b-list-group(size="sm")
               b-list-group-item(button) 
                i.far.fa-file-alt.mr-3
                | Basic Info 
               b-list-group-item(button) 
                  i.far.fa-address-card.mr-2
                  | Canvas
               b-list-group-item(button) 
                i.fas.fa-sliders-h.mr-2
                | Evaluations

          .col-4
            label Available Columns
            b-list-group(size="sm")
                b-list-group-item(v-for="field in fields") 
                  b-check() {{field.label}}



          .col-4
            label Selected columns

            draggable(tag="b-list-group" :list="fields_internal", handle=".handle")
              b-list-group-item(v-for="field in fields_internal", :key="field.name") 
                i.fas.fa-grip-vertical.gray-info.mr-1.handle(style="cursor: grab")
                | {{field.label}}

      

        
      template(slot="modal-footer")
            i.small Your column selection will be applied for all users in this project
            b-btn(variant="outline-secondary" @click="$refs['columnref'].hide(false)") Cancel
            b-btn(variant="primary"  @click="save()")#project-modal-save Apply



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import draggable from "vuedraggable";
import TaxonomySelect from '@/components/TaxonomySelect'


import { mapFields } from 'vuex-map-fields';

export default {

  name: 'ColumnsModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
      
        uid: null,
        title: "Add",
        form: {"description": "", parent_ids: [], "name": "", member_ids: [], owner_id: null, stages: ["Watch", "Review", "Closed", "Rejected"], status: 'Ongoing'},
        options: [],
        submitStatus: null,
        fields_internal: [],
        fields: [],
        ok_btn_text: "Add Topic"
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },


 computed: {
      
      ...mapFields([
          'project.project', 
          'instance_config'
      ]),

  },

  methods: {

    open(fields) {
      
      this.fields = fields;
      this.fields_internal = this.$_.cloneDeep(fields);
      this.$refs.columnref.show()
      this.$emit("opened", fields);
    },
    
    save() {

      this.fields = this.$_.cloneDeep(this.fields_internal);
      //this.$emit("save", this.fields);
      //this.$refs.columnref.hide();

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect,
    TaxonomySelect,
    ProjectStageSelect,
    draggable
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
