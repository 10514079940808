<template lang="pug">
  
.home

  //vue-pivottable(
    :data="pivotData"
    aggregatorName='Sum'
    rendererName='Table Heatmap'
    :rows="['Payer Gender']"
    :cols="['Party Size']"
    :vals="['Total Bill']"
    )
  
  vue-pivottable-ui(
    :data="pivotData"
    aggregatorName='Sum'
    rendererName='Stacked Column Chart'
    :rows="['name']"
    :cols="['current_project.stage']"
    :vals="['total_funding_usd']"
  ).small

  
  //b-overlay(:show="$store.state.loading")
    .row.mt-1
      .col-md-3
        .form-row
          .col-12


            label Data fields / Buckets
            
            .form-row(style="max-width: 400px", v-for="(field, i) in aggregation.bucket_fields").mt-1
              .col-10
                b-select(size="sm", :options="bucket_options", label="label", v-model="aggregation.bucket_fields[i]")
              .col-2
                b-btn(size="sm", variant="link", @click="aggregation.bucket_fields=aggregation.bucket_fields.filter((item, idx) => idx !== i)").text-muted X

            .form-row(v-if="aggregation.bucket_fields.length < 2").mt-2
              .col-12
                b-btn(size="sm", variant="outline-secondary", @click="aggregation.bucket_fields.push('')").text-muted Add bucket field
        
        .form-row.mt-4
          .col-12
            label Target fields
            .form-row(style="max-width: 600px", v-for="field, i in aggregation.target_fields").mt-1
              .col-7
                b-select(size="sm", :options="target_options", label="label", v-model="field.data_field_name")
              .col-4
                b-select(size="sm", :options="agg_options", label="label", v-model="field.aggregation_function")
              .col-1
                b-btn(size="sm", variant="link", @click="aggregation.target_fields=aggregation.target_fields.filter((item, idx) => idx !== i)").text-muted X
            .form-row().mt-2
              .col-12
                b-btn(size="sm", variant="outline-secondary", @click="aggregation.target_fields.push({'aggregation_function': 'Sum', 'data_field_name': null})") Add target field
        
        .form-row.mt-4
            b-btn(size="sm", variant="primary", @click="get_data()").mt-2 Show

      .col-md-9
       
               

        b-card.mt-1
          //.row
            .col-12
              .float-right
                b-form(inline)
                  //b-btn-group().mr-1
                    b-btn(size="sm", variant="outline-secondary", @click="get_data()") Table
                    b-btn(size="sm", variant="outline-secondary", @click="get_data()") Chart
                  
          //b-btn(size="sm", variant="outline-secondary", @click="download()") Download
          //bar-chart(:chart-data="chartData", :styles="myStylesSmall", :options="chartOptions", v-if="data", ref="chart")
          b-table(hover :items="data", :fields="fields", small).small.mt-2
             
        
</template>

<script>
// @ is an alias to /src

import Vue from "vue";
import Config from "@/config";

import ProjectFilter from "@/views/projects/ProjectFilter";
import BarChart from "@/components/BarChart"
import { mapFields } from "vuex-map-fields";

import { VuePivottable, VuePivottableUi } from 'vue-pivottable'



export default {
  name: "Analsis",

  data: function() {
    return {

       pivotData: [
 
],

        chartOptions: {scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  },
                  gridLines: {
                    display: true
                  }
                }],
                xAxes: [ {
                  gridLines: {
                    display: false
                  }
                }]
              },
              legend: {
                display: false
              },
              responsive: true,
              maintainAspectRatio: false
      },
      data: {},
      config: {},
      chartData:  {
          labels: [],
          datasets: []    
      },
      aggregation: {"bucket_fields": [], "target_fields": [], "context_type": "Project"},
      bucket_options: [],
      target_options: [],
      agg_options: ["sum", "mean", "count", "min", "max"],

      fields: [
        
      ],
      currentPage: 0
    };
  },

  mounted: function() {
    console.log(Config);
    this.host = Config.config.base_host;
    this.get_data();
    this.get_config();
  },

  computed: {
    ...mapFields(["query.keywords"]),
     
      myStyles () {
            return {
                height: "500px",
                "max-height": "500px",
                position: 'relative'
            }
        },
        myStylesSmall () {
            return {
                height: "300px",
                "max-height": "300px",
                position: 'relative'
            }
        },
   
  },

  methods: {
  
    download() {  
    
      let content = this.data;
    
      var finalVal = '';
      for (var i = 0; i < content.length; i++) {
          var value = content[i];

          for (var j = 0; j < value.length; j++) {
              var innerValue =  value[j]===null?'':value[j].toString();
              
              var result = innerValue.replace(/"/g, '""');
              if (result.search(/("|,|\n)/g) >= 0)
                  result = '"' + result + '"';
              if (j > 0)
                  finalVal += ',';
              finalVal += result;
          }

          finalVal += '\n';
      }

      console.log(finalVal);

      var download = document.createElement('a');
      download.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(finalVal));
      download.setAttribute('download', 'test.csv');
      download.click();


    },

   get_data() {
      const params = {}
      params['project_id'] = [this.$route.params.id];
      params['limit'] = 200;
      
      this.$store.dispatch("start_loading");
      
      Vue.http
        .post(
          `${Config.config.base_host}/organizations/search`,
            {"filter": params}
        )
        .then(res => {
      
          this.data = res.body.rows.map(x => {
            for(let k in x) {
              
              if(k == "index_space" && x[k].hasOwnProperty("projects")) {
                let current_projects = x.index_space.projects.filter(c => c._id == this.$route.params.id);
                if(current_projects.length > 0) {
                  x["current_project"] = current_projects[0];
                }
                // Rewrite#

                x["current_project"]['custom_data'] = this.$auth.rewrite_custom_data(x["current_project"]['custom_data'], this.$store.state.project.project.data_fields_project_organization);
          
              }

              if(k.indexOf("custom_data") > -1 || k == "name" || k == "total_funding_usd") {
                console.log("");
              } else {
                delete x[k]
              }

              if(k == "custom_data") {
                x[k] = this.$auth.rewrite_custom_data(x[k] , this.$store.state.instance_config.data_fields);
              }
            }
            
            return this.flattenObject(x)
          });

          this.pivotData = this.data;


          this.$store.dispatch("stop_loading");
          this.total_rows = res.body.total_rows;
    
        })
        .catch(() => {
          this.data = [];
        });

      return null;
    },
   
  
  flattenObject(ob) {
      var toReturn = {};
      
      for (var i in ob) {
        if (!ob.hasOwnProperty(i)) return;
        
        if ((typeof ob[i]) == 'object' && !Array.isArray(ob[i]) && ob[i]) {
          var flatObject = this.flattenObject(ob[i]);
          for (var x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;
            
            toReturn[i + '.' + x] = flatObject[x];
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
      return toReturn;
    },

    get_config() {
      Vue.http
        .get(`${Config.config.base_host}/projects/${this.$route.params.id}/analytics_config`)
        .then(res => {
          this.config = res.body;
          for(let kit in this.config) {
            let it = this.config[kit];
           
            if(it.is_target_field !== false) {
              this.target_options.push({"value": kit, "text": it.label});
            }
            if(it.is_bucket_field || it.is_bucket_field == null) {
              this.bucket_options.push({"value": kit, "text": it.label});
            }


          }
   
        });
    }
  },
  components: {
    ProjectFilter,
    BarChart,
    VuePivottable,
    VuePivottableUi
  }
};
</script>
<style lang="scss" >

@import '@/_vars.scss';

* {
    box-sizing: border-box
}

.pvtUi {
    width: 100%;
    color: #2a3f5f;
    border-collapse: collapse;
} 

.pvtUi select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtUi td.pvtOutput {

    vertical-align: top;
    border: 1px solid #a2b1c6;
}

table.pvtTable {
    font-size: 8pt;
    text-align: left;
    border-collapse: collapse;

}

table.pvtTable thead tr th,
table.pvtTable tbody tr th {
    background-color: #ebf0f8;
    border: 1px solid #c8d4e3;
}

table.pvtTable .pvtColLabel {
    text-align: center;
}
table.pvtTable .pvtTotalLabel {
    text-align: right;
}

table.pvtTable tbody tr td {
  
    padding: 3px;
    background-color: #fff;
    border: 1px solid $gray-100;
    vertical-align: top;
    text-align: right;
}

.pvtTotal,
.pvtGrandTotal {
    font-weight: bold;
}

.pvtRowOrder,
.pvtColOrder {
    cursor: pointer;
    width: 15px;
    margin-left: 5px;
    display: inline-block;
    user-select: none;
    text-decoration: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtAxisContainer,
.pvtVals {
    border: 1px solid #a2b1c6;
    background: #fff;
    padding: 5px;
    min-width: 20px;
    min-height: 20px;
}
.pvtVals.pvtText {
    vertical-align: middle;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    user-select: none;
    padding-left: 16px;
    text-align: start;
}

.pvtRenderers {
    border: 1px solid #a2b1c6;
    user-select: none;
}

.pvtDropdown {
    border: 1px solid #a2b1c6;
    align-items: center;
    display: block;
    background-color: transparent;
    background-image: none;
    border-radius: 0px;
    padding: 0px 0px 0px 3px;
    height: 40px;
    font-size: 15px;
    font-weight: bold;
    outline: none;
    line-height: 1 !important;
    margin: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtRenderers .pvtDropdown {
    padding: 0px 0px 0px 12px;
    border: none;
    width: 100%;
    margin: 0px;
}

.pvtDropdown:last-child {
    margin-bottom: 0px;
}

.pvtVals {
    white-space: nowrap;
    vertical-align: top;
    padding-bottom: 12px;
}

.pvtRows {
    height: 35px;
}

.pvtAxisContainer li {
    padding: 8px 6px;
    list-style-type: none;
    cursor: move;
}
.pvtAxisContainer li.pvtPlaceholder {
    -webkit-border-radius: 5px;
    padding: 3px 15px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px dashed #a2b1c6;
}
.pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
    display: none;
}

.pvtAxisContainer li span.pvtAttr {
    -webkit-text-size-adjust: 100%;
    background: #f3f6fa;
    border: 2px dashed #c8d4e3;
    padding: 4px 7px;
    white-space: nowrap;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}
.pvtAxisContainer li span.pvtAttr.sortonly {
    border: 1px solid #c8d4e3;
}
.pvtAxisContainer li span.pvtAttr.disabled {
    border: 1px solid #c8d4e3;
    color: #c5c5c5;
    cursor: not-allowed;
}
.pvtTriangle {
    cursor: pointer;
    color: #506784;
}

.pvtHorizList li {
    display: inline-block;
}
.pvtVertList {
    vertical-align: top;
}

.pvtFilteredAttribute {
    font-style: italic;
}

.sortable-chosen .pvtFilterBox {
    display: none !important;
}

.pvtFilterTextClear {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none !important;
}

/* .pvtDragHandle {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 18px;
    cursor: move;
    color: #a2b1c6;
} */


.pvtButton:hover {
    background: #e2e8f0;
    border-color: #a2b1c6;
}

.pvtButton:active {
    background: #d1dae6;
}

.pvtFilterBox input {
    border: 1px solid #c8d4e3;
}

.pvtFilterBox input:focus {
    border-color: #119dff;
    outline: none;
}

.pvtFilterBox {
    z-index: 100;
    border: 1px solid #506784;
    max-width: 600px;
    min-width: 210px;
    background-color: #fff;
    position: absolute;
    padding: 6px;
    user-select: none;
    min-height: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtFilterBox input[type='text'] {
    display: block;
    width: 100%;
    color: #2a3f5f;
    margin-bottom: 5px;
    padding: 6px;
    /* border-radius: 4px; */
}

.pvtFilterBox {
    padding: 3px;
}

.pvtCheckContainer {
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow-y: scroll;
    width: 100%;
    max-height: 30vh;
    border-top: 1px solid #dfe8f3;
}

.pvtCheckContainer p {
    margin: 0;
    margin-bottom: 1px;
    padding: 3px;
    cursor: default;
}

.pvtCheckContainer p.selected {
    background: #ebf0f8;
    min-width: 100%;
    width: max-content;
}

.pvtOnly {
    display: none;
    width: 35px;
    float: right;
    font-size: 12px;
    padding-left: 5px;
    cursor: pointer;
}

.pvtOnlySpacer {
    display: block;
    width: 35px;
    float: right;
}

.pvtCheckContainer p:hover .pvtOnly {
    display: block;
}
.pvtCheckContainer p:hover .pvtOnlySpacer {
    display: none;
}

.pvtRendererArea {
    padding: 5px;
}


</style>