<template lang="pug">
.main
  .loader
    .bar(v-if="$store.getters.loading")
  .container-xxl
      .row.no-gutters
          .col-12
              .mt-2.mb-5.main-view

                | Please select a project    
                ProjectStageSelect(v-model="project_id", :multiple="false", @input="saveProject()")    

</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import ProjectStageSelect from '@/components/ProjectStageSelect.vue'


export default {
  
  name: 'TeamsConfiguration',
  
  data: function () {
      return {
        project_id: null,
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;


   
  
  },


  computed: {

  },

  methods: {

    saveProject() {
        window.microsoftTeams.settings.setValidityState(true);
        window.microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
            console.log(this.project_id);
          
            window.microsoftTeams.settings.setSettings({
                websiteUrl: "https://" +location.host,
                contentUrl: "https://" +location.host + "/projects/" + this.project_id,
                entityId: "esmProjectTap",
                suggestedDisplayName: "Ecosystem"
            });
            saveEvent.notifySuccess();
        });
    },


    getData() { 
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/topics_v1/search`, {"project_id": [this.$route.params.id]}).then(res => {
        this.topics = res.body.rows;
        this.$store.dispatch("stop_loading");
      });
    },

    showModal(topic) {
    
        this.$refs.topicModal.open(topic, this.$store.getters.project);
    },
  },

  components: {
      ProjectStageSelect
     
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>