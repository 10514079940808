  <template lang="pug">
  
  .filter-group.to-hover

    template(v-if="selectMode && options")
    
      v-select(:taggable="false", label="full_name", :selectable="option => deactivate_user_ids.indexOf(option._id) == -1", v-model="internal_value" :options="options",:placeholder="placeholder" :filterable="false", @search="onSearch")
          template(slot="no-options") Type to search
          
          template(slot="option" slot-scope="option")
           
         
            div(v-bind:class="{'v-select-disabled': deactivate_user_ids.indexOf(option._id) > -1}")
              .media.option
                template(v-if="option.profile_pic")
                    b-img( v-set-image-src="option.profile_pic", :style="{width: 20 +'px', height: 20 +'px'}", referrerPolicy="no-referrer").mr-2.feed-image
                template(v-else-if="option.image_payload")
                    b-img(:src="option.image_payload", :style="{width: 20 +'px', height: 20 +'px'}", referrerPolicy="no-referrer").mr-2.feed-image
                template(v-else)
                    avatar(:username="option.full_name", :size="20", :inline="true", :rounded="true").mr-2
                .media-body.text-truncate
                  .small.text-truncate {{ option.full_name }}
                  .small.text-truncate.text-gray {{ option.email }}
          
          template(slot="selected-option" slot-scope="option")
          
            
              .media.option
                  
                  template(v-if="option.profile_pic")
                      b-img(v-set-image-src="option.profile_pic", :style="{width: 20 +'px', height: 20 +'px'}", referrerPolicy="no-referrer").mr-2.feed-image
                  template(v-else-if="option.image_payload")
                      b-img(:src="option.image_payload", :style="{width: 20 +'px', height: 20 +'px'}", referrerPolicy="no-referrer").mr-2.feed-image
                  template(v-else)
                      avatar(:username="option.full_name", :size="20", :inline="true", :rounded="true").mr-2
              .media-body
                .small.text-truncate {{ option.full_name }}
                
 
    
    template(v-if="!selectMode && internal_value")
      a(@click="selectMode = true;")
        .media
          User(:user="internal_value", :size="20", :inline="true").mr-1
          
    
    template(v-if="!selectMode && !internal_value")

        a(@click="selectMode = true;")
          i.far.fa-user-circle.mr-1.gray-info(style="font-size: 20px").mt-1
          .small(style="vertical-align: top; padding-top: 4px").gray-info.to-show No user assigend

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
import User from '@/components/User'


export default {
  name: 'UserSelect',
  props: {
    "value":  {
      default: null
    }, 
    "placeholder":  {
      type: String,
      default: null
    }, 
    "deactivate_user_ids":  {
      type: Array,
      default: () => []
    }, 
    "collpased": {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
        uid: null,
        internal_value: null,
        selectMode: true,
        options: []
    }
  },

  watch: {
        'value': function() {
          this.internal_value = this.value;
        },

        'internal_value': function() {
          if(this.deactivate_user_ids.indexOf(this.internal_value._id) > -1) return;
          this.selectMode = true;
          this.$emit("input", this.internal_value);
        }
  },


  mounted: function() {
    this.uid = this._uid;
    this.internal_value = this.value;  
  },

  methods: {
      onSearch(search, loading) {
        if(search.length) {
          loading(true);
          this.search(loading, search, this);
        }
      },
      search: Vue.prototype.$_.debounce((loading, search, vm) => {

         Vue.http
              .post(
                `${Config.config.base_host}/users/search`,
                {q: search}
              )
              .then((res) => {
                 vm.options = res.body;
                 loading(false);
              });
      
      }, 500)
    
  },


  components: {
    Avatar,
    User  
  }
}

</script>
<style lang="scss">

.v-select-disabled {
  opacity: 0.5;
  cursor: default;
}



</style>
