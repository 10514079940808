import { render, staticRenderFns } from "./Setup.vue?vue&type=template&id=6f6a763a&scoped=true&lang=pug"
import script from "./Setup.vue?vue&type=script&lang=js"
export * from "./Setup.vue?vue&type=script&lang=js"
import style0 from "./Setup.vue?vue&type=style&index=0&id=6f6a763a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6a763a",
  null
  
)

export default component.exports