<template lang="pug">

  .project-card
    b-card(no-body)
        .media
            avatar(:username="topic.name", :size="60", :inline="true", :rounded="false").mr-2
            .media-body.p-2
                .float-right
                  b-btn(variant="link", size="sm")
                    i.fas.fa-ellipsis-h.text-muted
                h5 {{topic.name}}
                .text-muted.small {{topic.description}}
                
        hr.m-0.p-0
        .ml-3.mb-2.mt-1
          template(v-for="kw in topic.keywords")
              .badge.badge-secondary.mr-1 {{kw}}
          

</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
export default {
  name: 'TopicCard',
  props: {
    "topic":  {
      default: null
    }, 
    "collpased": {
      type: Boolean,
      default: false
    }, 
    "selectMode": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
        options: null
    }
  },

  mounted: function() {
     this.uid = this._uid;
     
  },

     
  
  methods: {
    
   onSearch(search, loading) {
      
      //loading(true);
      this.search(loading, search, this);

    },

    search: debounce((loading, search, vm) => {
      
      Vue.http.get(`${Config.config.base_host}/users/search/autocomplete?q=${escape(search)}`).then(res => {
        vm.options = res.body;
        loading(false);
      });

    }, 350)
  },

  components: {
    Avatar
  }
}

</script>
<style lang="scss" scoped>
.option, .selected {
  //width: 300px;
}
</style>
