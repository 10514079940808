  <template lang="pug">
  
  .filter-group
  
    template(v-if="!edit")
        b-badge {{topic_ids}}
        a.small.text-muted(@click="edit = true") No tags

    template(v-else)
        template(v-if="options")
        
            //b-select(:options="options", size="sm", v-model="value")
            v-select(placeholder="Select topic" , :taggable="false", :id="id", :multiple="multiple", :disabled="disabled", label="name", v-model="topic_ids" :options="options", :filterable="true", :reduce=" x => x._id", :close-on-select="closeOnSelect", :select-on-tab="true")
                //template(slot="no-options") Type to search ...
                template(#no-options="{ search, searching, loading }") No topics available.


                 
</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'

export default {
  name: 'UseCaseSelect',
  props: {
    "project_id": {
        default: null,
        required: true
    },
    "show_type": {
        type: Boolean,
       default: false
    },
    "topic_type": {
      type: String,
      default: null
    },
    "value":  {
      default: null
    }, 
    "id":  {
      default: "UserMultiSelect"
    }, 
    "collpased": {
      type: Boolean,
      default: false
    },
    "need_query": {
      type: Boolean,
      default: false
    },
    "closeOnSelect": {
      type: Boolean,
      default: true
    },
    "multiple": {
      type: Boolean,
      default: false
    },
    "edit": {
      type: Boolean,
      default: false
    },
    "disabled": {
      type: Boolean,
      default: false
    },
    "show_follow_only": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
        options: null,
        usecase_id: null,
        topic_ids: null,
        init_loading: false
       
    }
  },


  watch: {
        topic_ids: function(val) {
          
          this.$emit("input", val);
        },

        value: function(val) {
          this.topic_ids = this.value;
          this.cleanSearch();
          this.$emit("input", val);
        },
        
    
  },


  mounted: function() {
     this.topic_ids = this.value;
     this.uid = this._uid;
     this.cleanSearch();
  },

  computed: {
   
  },

  methods: {
    change(event) {
      alert(event);
    },

    cleanSearch() {
        this.init_loading = true;
        let vm = this;
        let options = [];
        Vue.http.get(`${Config.config.base_host}/topics_v1`).then(res => {
          
          options = res.body.filter(x => x.topic_type == vm.topic_type || !vm.topic_type);
          options = options.filter(x => x.full_query && x.full_query.length > 0 || !vm.need_query);
          options = this.$_.sortBy(options, ['name']);

          if(this.show_follow_only) {
             vm.options = options.filter(item => this.$store.state.user.pins.map(x => x.item_id).indexOf(item._id) > -1);
          } else {
            vm.options = options;
          }
          
        });

    },
    onSearch(search, loading) {
        loading(true);
        
        this.search(search, loading, this);
    },

    search: debounce((search, loading, vm) => {
      loading(true);
      let options = [];
      Vue.http.get(`${Config.config.base_host}/topics_v1`).then(res => {
        
          options = res.body.filter(x => x.topic_type == vm.topic_type || !vm.topic_type);
          options = options.filter(x => x.full_query && x.full_query.length > 0 || !vm.need_query);
          options = this.$_.sortBy(options, ['name']);

          if(this.show_follow_only) {
             vm.options = options.filter(item => this.$store.state.user.pins.map(x => x.item_id).indexOf(item._id) > -1);
          } else {
            vm.options = options;
          }
        
        loading(false);
      });

    }, 350)
  },

  components: {
    
  }
}

</script>
<style lang="scss">

</style>
