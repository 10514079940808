<template lang="pug">

  .survey-templates()
    
    .row
        
        .col-3(id="searchmenu_orient", v-if="!selected")
          template(v-if="!selected")
              .sidemenu-search(id="searchmenu").mt-5
                b-btn(variant="outline-primary", size="sm", @click="create_survey_template()", v-if="!selected") Create new survey
                SurveyFilter(@search="getData()")

        .col-9()#list-group
            
            template(v-if="!selected")
                div(v-if="survey_templates && $store.state.user && survey_templates.length == 0") Your search has no results. Click "Create survey" to create your first survey.
                b-overlay(:show="$store.state.loading")
                div(style="overflow-y: auto", v-if="survey_templates != null")
                  div.accordion
                    template(v-for="(template, i) in survey_templates", v-if="template.item_type == 'project_assessment'")
                        b-card(no-body).mt-1

                          b-card-header(header-tag="header"  role="tab").p-2

                              .row
                                .col-3
                                  //b-link(v-b-toggle="'accordion-' + template._id")
                                    //span(v-if="template.relations.project_id") Project {{template.relations.project_id.name}} 
                                  h5 {{template.name}}
                                    
                                  .text-gray.small {{template.description}}
                                .col-1
                                  .kpi.text-center.mr-5
                                    User(:user="template.relations.owner_id", :size="25")
                                    .small.text-gray Creator
                                  
                                .col-4()
                                  .p-2
                                    template(v-if="analytics[template._id]")
                                      b-overlay(:show="!analytics[template._id]")
                                        .h-mouseover-parent.text-center.mr-2
                                          

                                          .kpi.text-center.mr-2
                                            .indicator {{analytics[template._id].sent_at_total || 0}}
                                            .small.text-gray Sent
                                          .kpi.text-center.mr-2
                                            .indicator {{analytics[template._id].opened_at_total || 0}}
                                            .small.text-gray Opened
                                          .kpi.text-center.mr-2 
                                            .indicator {{analytics[template._id].responded_at_total || 0}}
                                            .small.text-gray Responded
                                
                                .col-4
                                  .p-2                    
                                    .float-right
                                      b-btn-group()
                                        b-btn(size="sm", variant="outline-secondary", :to="'survey_templates/'+template._id", v-if="template.item_type == 'project_assessment'") Open
                                        b-btn(size="sm", variant="outline-secondary", :to="'matching?survey_template_id='+template._id", v-if="template.item_type == 'project_assessment'") Tracking
                                        b-btn(size="sm", variant="outline-secondary", :to="'surveys_standard?survey_template_id='+template._id", v-if="template.item_type == 'project_assessment'") Responses
                                   
                          //b-collapse(:id="'accordion-'+template._id" accordion="my-accordion" :visible="i==0", role="tabpanel")
                            b-card-body(v-if="analytics[template._id] && analytics[template._id]['created_at']")
                              b-overlay(:show="!analytics[template._id]")
                                  LineChart(style="height: 200px", :chartData="get_chart_data(analytics[template._id])", :options="line_options")
             
                  
            template(v-if="selected")
              .row
                .col-12
                  
                  b-btn(variant="outline-primary", @click="$refs.template_editor.save()", size="sm") Save survey

                  .float-right
                    b-btn(@click="clone_survey_template(selected)", variant="outline-secondary", size="sm", v-if="selected._id")

                      |  Clone survey
                  
              .row.mt-3
                .col-12(v-if="$store.getters.project")
                  SurveyTemplate(@saved="getData()" v-model="selected", :edit_mode="selected.item_type == 'project_assessments'", ref="template_editor", :project="$store.getters.project")

</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import SurveyTemplate from '@/components/SurveyTemplate.vue'
import LineChart from '@/components/LineChart.vue'
import SurveyFilter from '@/views/surveys/SurveyFilter.vue'
import User from '@/components/User.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'SurveyTemplates',
  
  data: function () {
      return {
         line_options: {
           animation: {duration: 0},
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true
            },
            bezierCurve: false,
          
            scales: {
                xAxes: [{
                  type: 'time',
                  gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                  },
                  time: {
                      unit: 'day',
                      displayFormats: {
                          month: 'MM/YY'
                      }
                  }
                }],
                yAxes: [{
                  gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                  },
                  ticks: {min: 0, stepSize: 1}
                }]
            }
          },
          
          survey_templates: null,
         
          new_template_raw: {groups: [], data_fields: [], name: ""},
          new_template: null,
          analytics: {},
          new_mode: true
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.selected = null;

    this.getData();

    if(this.$route.params.survey_template_id) this.getTemplate(this.$route.params.survey_template_id);

    if(this.$route.params.survey_template_id == "new") {
      this.create_survey_template();
    }

    

  },

  watch: {
      '$route.params.survey_template_id': function (newVal) {
          if(!newVal) this.selected = null;
          else { 
            this.getTemplate(newVal);
          }
      },

      '$route.path': function (newVal) {
          if(newVal == "/surveys_templates") {
            this.selected = null;
          }
          window.dispatchEvent(new Event('resize'));
      }
  },

  computed: {
       
        ...mapFields([
            'survey.selected',
            'survey.query.filter'

        ]),
  },

  
  methods: {
      

      get_chart_data(el) {

        return {'labels': Object.keys(el['created_at']), 
        'datasets': [
          {lineTension: 0.2, borderColor: '#43B02A', pointRadius: 3, 'label': 'Created', data: Object.keys(el['created_at']).map((x) => el['created_at'][x] )},
          {lineTension: 0.2, borderColor: '#26890D', pointRadius: 3, 'label': 'Sent', data: Object.keys(el['sent_at']).map((x) => el['sent_at'][x] )},
          {lineTension: 0.2, borderColor: '#2C5234', pointRadius: 3, 'label': 'Opened', data: Object.keys(el['opened_at']).map((x) => el['opened_at'][x] )},
          {lineTension: 0.2, borderColor: '#0076A8', pointRadius: 3, 'label': 'Responded', data: Object.keys(el['responded_at']).map((x) => el['responded_at'][x] )}
        ]}
      
      },

      create_survey_template() {
        this.new_template = Object.assign({}, this.new_template_raw);
        this.selected = this.new_template;
        this.new_mode = true;
        this.$router.replace({"path": "/projects/"+this.$route.params.id+"/survey_templates/new"});
      },


      clone_survey_template(template) {
        

         this.$bvModal.msgBoxConfirm("Would you like to clone this survey?", {buttonSize: 'sm'}).then((value) => {
           
            if(value) {
                 this.new_template = Object.assign({}, template);
                  this.new_template._id = null;
                  this.new_template.name = "Copy of " + this.new_template.name;
                  this.new_template._rev = null;  
                  this.selected = this.new_template;
                  this.new_mode = true;

                  this.$bvToast.toast("Survey cloned", {
                        title: this.new_template.name + " created",
                        variant: 'outline-primary',
                        autoHideDelay: 1000,
                  });
            }
            
      
        });


      
      },
    
      getData() {
      
        if(this.selected && this.selected._id) this.$router.replace({"path": "/projects/" + this.$route.params.id + "/survey_templates/"+this.selected._id});
        
        this.$store.dispatch("start_loading");
        this.filter = Object.assign({"project_id": [this.$route.params.id]}, this.filter);
        this.filter.project_id = [this.$route.params.id];
        Vue.http.post(`${Config.config.base_host}/survey_templates/search`, this.filter).then(res => {
            this.survey_templates = res.body;
            
            this.survey_templates.forEach(template => {
              console.log(template);
              this.get_analytics(template._id);
            });
            
            this.$store.dispatch("stop_loading");
        });

      },

       getTemplate(id) {
        
        this.$store.dispatch("start_loading");
        
        Vue.http.get(`${Config.config.base_host}/survey_templates/${id}`).then(res => {
            this.selected = res.body;
            this.$store.dispatch("stop_loading");
        });

      },

      get_analytics(tid) {
         //let today = new Date().toISOString().substring(0,10);
         Vue.http.post(`${Config.config.base_host}/surveys/analytics`, {query: {survey_template_id: [tid]}, start_date: "2020-10-01", end_date: "2020-12-01", interval: "D"}).then(res => {
            this.analytics[tid] = res.body;
            this.$forceUpdate();
            this.$store.dispatch("stop_loading");
        });
      }
     
  },
  components: {
      SurveyTemplate,
      User,
      LineChart,
      SurveyFilter
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
    
    .draft {
      background-color: rgba(0,0,0,0.1);
      height: 100%;
      text-align: center;
      padding: 20px;
      
    }
    .kpi {
      min-width: 50px;
      .indicator {
        font-size: 1em;
        font-weight: bold;
      }
      small {
        font-size: 0.75em;
      }
      
      display: inline-block;
    }
</style>