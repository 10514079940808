<template lang="pug">
  
  .home
    Topic2Modal(ref="topicModal")
   
    div(v-if="topics && topics.length == 0") You don't have any evaluated topics in this project yet.

    <svg id="radar"></svg>

</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import TopicCard from '@/components/TopicCard.vue'
import Topic2Modal from '@/components/Topic2Modal.vue'
import TopicOverview from '@/views/topics/Overview.vue'
import radar_visualization from '@/components/radar.js'

export default {
  
  name: 'TopicOverview2',
  
  data: function () {
      return {
        topics: null,
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();


  },


  computed: {

  },

  methods: {

    getData() { 
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/topics_v1/search`, {"project_id": [this.$route.params.id]}).then(res => {
        let quadrants = this.$store.getters.project.groups.filter(x => { return x && x != "Not assigned"}).map(x => {return {name: x}});
        let rings = this.$store.getters.project.topic_stages.filter(x => { return x && x != "Not assigned"}).map(x => {return {name: x}});
        this.topics = res.body.rows;
        this.$store.dispatch("stop_loading");
        let pts = [];

        this.topics.forEach((topic) => {
            
            let pt = topic.index_space.projects.find(x => x._id == this.$route.params.id);
            if(pt.group && pt.group != "Not assigned" && pt.stage && pt.stage != "Not assigned") {
              pt.topic_name = topic.name;
              pt.ring = rings.indexOf(rings.find( x => x.name == pt.stage)) ;
              pt.quadrant = quadrants.indexOf(quadrants.find( x => x.name == pt.group));
              pts.push(pt);
            }
            
        });

        console.log(pts);

        radar_visualization({
            svg_id: "radar",
            width: window.innerWidth-300,
            height: window.innerHeight,
            colors: {
                background: "transparent",
                grid: "#bbb",
                inactive: "#ddd"
            },
            
            quadrants: quadrants,
            rings: rings,
            print_layout: true,
            entries: pts.map(x => { return {"label": x.topic_name, "ring": x.ring, moved: 0, quadrant: x.quadrant} }) 
           
    });


      });
    },

    showModal(topic) {
    
        this.$refs.topicModal.open(topic, this.$store.getters.project);
    },
  },

  components: {
      TopicCard,
      Topic2Modal,
      TopicOverview
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>