<template lang="pug">
    .row
        //.col-3
            ProjectFilter()
        
        .col-12()
            .row
              
                .col-12
                    .float-right()
                        b-btn(@click="do_upsize()", size="sm", variant="outline-secondary") Large View
            .row
                .col-12
                    .main-map(:class="{upsize: upsize}").mt-1
                        .row(v-if="upsize")
                            .col-12
                                .float-right()
                                    b-form().map-form.p-1.pt-2
                                        .form-row
                                            .col-auto
                                                b-input-group()
                                                    label.p-2 Show counts
                                                    b-check(type="text", size="sm", v-model="showCounts")

                                            .col-auto
                                                b-input-group()
                                                    label.p-2 Cluster color
                                                    b-input(type="text", size="sm", v-model="clusterColor", style="width: 100px")

                                            .col-auto
                                                b-input-group()
                                                    label.p-2 Cluster base size
                                                    b-input(type="number", size="sm", v-model="clusterBaseSize", style="width: 50px")

                                            .col-auto
                                                b-input-group()
                                                    label.p-2 Cluster radius
                                                    b-input(type="number", size="sm", v-model="clusterRadius", style="width: 50px")

                                            .col-auto
                                                b-btn(@click="displayMap()", size="sm", variant="primary").ml-5 Update

                                            .col-auto
                                                b-btn(@click="do_upsize()", size="sm").ml-5 X
                        .row
                            .col-12#mymap
                                div(id="mapid", class="m0 p0")
</template>   

<script>
import ProjectFilter from '@/views/projects/ProjectFilter'

export default {

  name: 'Main',
  
  props: [],
  data () {
    return {
      clusterRadius: 30,
      clusterColor: '#003D50',
      showCounts: true,
      clusterBaseSize: 3,
      items: [],
      maps: null,
      layer: null,
      markerClusters: null,
      tags: [],
      upsize: false

    }
  },

  watch: {
      "$store.getters.projectSearch.rows": function() {
          this.displayMap();
      }
  },

  mounted() {
        this.$store.dispatch("projectSearch", this.$route.params.id);
        this.displayMap();
        
  },
    computed: {
        
    },
    methods: {
    
    do_upsize() {
        this.upsize = !this.upsize;  
        this.maps = null;
        this.displayMapInner();
    },
    displayMap() {
        this.items = this.$store.getters.projectSearch.rows;
        setTimeout(()=> { this.displayMapInner(); }, 100);
    },
    
    displayMapInner() {
        let L = window.L;
        
        if(!this.maps) {
            document.getElementById('mymap').innerHTML = "<div id='mapid' style='width: 100%; height: 100%;'></div>";

            if(this.upsize) {
                document.getElementById("mapid").style.height = window.innerHeight + "px";
                document.getElementById("mapid").style.width = window.innerWidth + "px";
            } else {
                document.getElementById("mapid").style.height = window.innerHeight-300 + "px";
            }
            
            this.maps = window.L.map('mapid', { scrollWheelZoom: false, center: [25, 0], zoom: 2});
            this.maps.on('zoomend', () => {
                this.zoom = this.maps.getZoom();
                this.lat = this.maps.getCenter().lat;
                this.lng = this.maps.getCenter().lng;
               
            });
            
            this.maps.on('moveend', () => {
                this.lat = this.maps.getCenter().lat;
                this.lng = this.maps.getCenter().lng;
       
            });
      }
        this.layer = window.L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', { attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>' });
        this.maps.addLayer(this.layer);
        
        //let buckets = {}
        if(this.markerClusters) this.maps.removeLayer(this.markerClusters );
       
        this.markerClusters = window.L.markerClusterGroup({
            iconCreateFunction: (cluster) => {
                var markers = cluster.getAllChildMarkers();
                var dataNum = markers.length;
                var size = this.clusterBaseSize * 2 * Math.ceil(Math.sqrt(dataNum));
                return L.divIcon({ html: '<div class="mc" ref="mc" style="background-color:'+this.clusterColor+'; opacity: 0.9; color: #fff; line-height: '+size+'px">'+(this.showCounts ? dataNum : "")+'</div>', className: 'mycluster', iconSize: L.point(size, size)});
            
            },
            maxClusterRadius: (zoom) => {
                console.log(zoom);
                return this.clusterRadius;
            }
        });
        

         this.items.forEach((ite) => {
            
            let it = ite;
            if(ite.city && ite.city.indexOf("VALUE") == -1) {
                    it['id'] = ite._id;
                    console.log(it);
                    if(it.location_lat && it.location_lon) {
                        
                        let m = window.L.circleMarker([it.location_lat, it.location_lon], {radius: 4, color: this.clusterColor, fillColor: this.clusterColor, fillOpacity: 1});
                        m.data = it;
                        m.bindPopup("<a href=\"/orgs/"+it.id+"\">"+it.name+"</a>")
                        this.markerClusters.addLayer( m );
                        m.on("click", function (event) {
                            //var clickedMarker = event.layer;
                            console.log(event);
                        });
                        
                    }
             }
          
              
        });

        this.maps.addLayer( this.markerClusters );


        
    },


    getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },


    getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
   

        
  },
  components: {
  
        ProjectFilter
    
  }
}
</script>


<style scoped lang="scss">
@import '~@/_vars.scss';

.map-form {
    label {
        color: $light;
    }
}
.feed-image {
    height: 50px;
    width: 50px;
}

.item {
    .links {
        //display: none;
        a{
        
        }
    }
   
}
.vue-tags-input {
  max-width: none;
  width: 100% !important;
}

.upsize {
    background-color: $gray-default;
    position: fixed;
    z-index: 2000;
    height: 100%;
    top: 0;
    left:0;
    width: 100%;

}

.list-group-item.active {
    color: white;
    
}





</style>
