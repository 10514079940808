<template lang="pug">
  
  
  .home
   
    .row.mt-1
      .col-md-2
       
        ProjectFilter()
      .col-md-10
        
        b-card.mt-1.redirect
            center
                .text-muted Opening <a :href="url" target="_blank">{{url}}</a> ...
                template(v-if="stillLoading")
                    .text-muted.small.mt-1 Still loading ...
                
                
        
</template>

<script>
// @ is an alias to /src



export default {
  
  name: 'Redirect',
  
  data: function () {
    return {
        url: null,
        stillLoading: false,
    }
  },

  mounted: function() {
      this.url = this.$route.query.url;
      location.href=this.$route.query.url;
      setTimeout(()=>{ this.stillLoading = true }, 4000)
  },


   computed: {

  
  },

  methods: {




  },
  components: {
    
  }
}

</script>
<style lang="scss" scoped>

.redirect {
    position: fixed;
    top:0;
    z-index: 10000;
    width: 100%;
    left:0;
    height: 100%;
}


</style>