<template lang="pug">

  .home(v-if="project")
    b-overlay(:show="$store.state.loading")
      .row.mt-1
       
        .d-none.d-md-block.col-md-2
          
          b-list-group(v-for="(group, k) in data_field_groups") 
                b-list-group-item(:href="'#'+group.group") {{group.group}}
          
        .col-0.col-md-1
        .col-12.col-md-8(v-if="project")
           
            .mt-0
                label#project_organization Data fields setup

                .text-muted 
                  p You can setup project data fields for your project to individually add additional project related data to a company or rate companies inside your project. The data fields will appear on the company’s profile. The rating option will be available for every member on the project. You can create different labels, choose suitable field types and group them thematically by creating sections.
                  b Follow these steps:
                  ol.list
                    li Create a new section to group the data fields. 
                    li Create the first data field by typing the label and choosing a field type.
                    li Describe your data field in detail.


                .row.mt-5
                    .col-12
                        b-btn(size="sm", variant="outline-secondary", @click="addGroup()")#new_section
                            i.fas.fas-plus
                            | + Create new section

                div(v-for="(group, k) in data_field_groups", :id="group.group").mt-5

                    template(v-if="!group.edit")
                        h3 {{group.group}} 
                            b-btn(@click="editGroup(group)", size="sm", variant="link").text-muted
                                i.fas.fa-pen

                    template(v-else)
                        b-form(inline, :ref="group.name")
                                b-input(v-model="group.group", variant="link", placeholder="Type a section name", ).f-input.mr-2
                                b-btn(@click="saveGroup(group)")#save-group Save name 

                    draggable(handle=".handle" :sort="true", v-model="group.fields", @change="changed(group.group, $event)", style="white-space: normal;" ghost-class="ghost-card" group="fields").column-width.max-height-column
                        b-card(v-for="(field, index) in group.fields", @click="active = field", :id="'id'-field.name", :key="group.group + field.name", :class="{'active': active == field}").mt-1.tasks
                          
                            a().click
                                
                                i.fa.fa-align-justify.handle.mr-2.gray-info
                                label 
                                |  {{field.label ? field.label : "New Field"}} <i v-b-tooltip="field.description" class="far fa-question-circle" v-if="field.description"></i>

                            .text-muted.d-inline
                                .float-right
                                    b-form(inline)
                                            label.mr-3 Field type 
                                            b-dropdown(right :text="data_type_mappings[field.data_type]", variant="outline-secondary", size="sm", style="min-width: 200px")#data_type
                                                template(v-slot:button-content)
                                                        | {{data_type_mappings[field.data_type].split("-")[0]}}

                                                b-dropdown-item(href="#", @click="field.data_type = k" v-for="(it, k) in data_type_mappings")  {{it}}

                            template(v-if="active == field")
                                
                                hr.mt-4
                                b-form
                                .form-row
                                
                                    //.col-6
                                        b-form-group(label="Section")
                                            b-input(v-model="active.group", size="sm", required)
                                    .col-6
                                        b-form-group(label="Label*")
                                            b-input(v-model="active.label", size="sm", placeholder="Type a field label name")#label-name

                                .form-row(v-if="active.data_type=='select'")

                                    .col-6
                                        b-form-group(label="Field type options")
                                            //b-select(:options="['select', 'text', 'textarea', 'slider', 'api_field']", size="sm", v-model="active.data_type")
                                            v-select(v-if="active.data_type=='select'", placeholder="Options", :multiple="true", :taggable="true", :no-drop="true", v-model="active.options").mt-1.small#options

                                .form-row(v-if="active.data_type=='money_amount'")

                                    .col-6
                                        b-form-group(label="Currency")
                                            b-select(:options="['USD', 'EUR']", size="sm", v-model="active.options")#currency
                               
                                .form-row(v-if="active.data_type=='textarea'")

                                    .col-6
                                        b-form-group(label="Rows")
                                            b-input(type="number", size="sm", v-model="active.options.rows", length="4", placeholder="Number of rows for text input")#number-rows
                                           


                                //.form-row
                                    .col-6
                                        b-form-group(label="Name")
                                            b-input(size="sm", v-model="active.name", :disabled="true")
                                    //.col-6
                                        b-form-group(label=" ").mt-4
                                            b-check(v-model="active.required", size="sm") This field is required

                                .form-row
                                    .col-12
                                        b-form-group(label="Description / Help text")
                                            b-textarea(size="sm", v-model="active.description", placeholder="Type a description or short input help text for the data field")#description
                                //.form-row
                                    .col-6
                                        b-check(v-model="active.filterable") Searchable
                                        
                                    //.col-6
                                        b-check()
                                .form-row
                                    .col-12
                                        b-btn(size="sm", variant="link", @click="remove(field)") Delete this field
                                        .float-right
                                            b-btn(size="sm", variant="link", @click="getData()")#cancel.mr-1 Cancel
                                            b-btn(size="sm", variant="primary", @click="save()")#save Save

                    .mt-2
                       
                        b-btn(size="sm", variant="outline-secondary", @click="addField(group.group)")#add-field
                            i.fas.fas-plus
                            | + Create data field
                        

                  
                

</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'

import draggable from "vuedraggable";
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Analytics',
  
  data: function () {
    return {
      project: null,
      custom_group: null,
      active: null,
      data_field_groups: {},
      config: {},
      data_type_mappings: {
        "text": "Short text - Provide a line of text",
        "textarea": "Long text - Provide multiple lines of text",
        "slider": "Rating - Select a score from 0-10",
        "date": "Date - Provide a date chosen from a calender",
        "money_amount": "Money amount - Provide a amount in a currency",
        "select": "Option - Choose from predefined options"
      }
      
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;
    this.getData();
  },

   computed: {
        ...mapFields([
            'query.keywords'
        ]),
        
  },

   watch: {
    
    '$route.params.id': function () {
      this.getData();
      this.get_config();
      
    }
  },


  methods: {
     get_config() {
      Vue.http
        .get(`${Config.config.base_host}/projects/${this.$route.params.id}/analytics_config`)
        .then(res => {
          this.config = res.body;
        });
    },

   generateGuid() {
        var result, i, j;
        result = '';
        for(j=0; j<32; j++) {
            if( j == 8 || j == 12 || j == 16 || j == 20) 
            result = result + '-';
            i = Math.floor(Math.random()*16).toString(16).toUpperCase();
            result = result + i;
        }
        return result;
    },

    editGroup(group) {
        group.edit = true;
    },

    saveGroup(group) {
        this.save();
        group.edit = false;
    },

    addGroup() {
        let name =  this.generateGuid();
        let gname =  "hello";
        this.data_field_groups["New Section"] = {"group": "", "name": gname, "fields": [{"label": "", "data_type": "text", "name": name}], edit: true};
        this.data_field_groups = Object.assign({}, this.data_field_groups);
        //this.active = this.data_field_groups["New Section"]['fields'][0];
        setTimeout(()=> {
            let els = document.getElementsByClassName("f-input");
            els[0].focus();
            window.scrollTo(0,document.body.scrollHeight);
        }, 100);
        
    },


    changed(group, event) {
      
   
      if(event.added) {
        console.log(group, event);   
        this.save();
      }

      if(event.moved) {
        console.log(group, event);
        this.save();
       
      }

       if(event.user) {
        console.log(group, event);
      }
     
    },



    addField(group) {

        let name =  this.generateGuid();
        let newField = {"data_type": "text", "label": "", "group": group, "name": name};
        this.project.data_fields_project_organization.push(newField);
      
        this.data_field_groups = {};

        this.project.data_fields_project_organization.forEach((x)=> {
                    
                if(!this.data_field_groups[x.group]) this.data_field_groups[x.group] = {"group": x.group, "fields": []};
                
                this.data_field_groups[x.group].fields.push(x);
                
        });
        
        this.active = newField;
    
    },
    
    cleanup(text) {
     return text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
    },

    remove(field) {
       this.$bvModal.msgBoxConfirm("Are you sure to remove this field? All entered data for this field will be lost!", {buttonSize: 'sm'}).then((value) => {
           if(value) {
             console.log("Delete", field);
             this.save(field.name);
           }
          
        });
    },

    checkName(active) {
        if(!active.name) active.name  = this.cleanup(active.label);
    },

    updateStruture(project, to_delete=null) {
        let data_fields_project_organization = [];
   
        Object.keys(this.data_field_groups).forEach((group_k) => {
            let group_v = this.data_field_groups[group_k];
           
            group_v.fields.forEach((field_v) => {
                if(to_delete == null || field_v['name'] != to_delete) {
                    field_v['group'] = group_v.group;
                    data_fields_project_organization.push(field_v);
                }
               
            });     
        });

        project.data_fields_project_organization = data_fields_project_organization;
    },

    save(to_delete=null) {

        this.updateStruture(this.project, to_delete);

        this.$store.dispatch('start_loading');
        Vue.http.put(`${Config.config.base_host}/projects/${this.$route.params.id}`, this.project).then(() => {
            this.getData();
            this.$store.dispatch('stop_loading');
        });
    },
    
    getData() {

      this.$store.dispatch('start_loading');
      Vue.http.get(`${Config.config.base_host}/projects/${this.$route.params.id}`).then(res => {
            let project = res.body.project;
            let data_field_groups = {};
            if(!project.data_fields_project_organization) project.data_fields_project_organization = [];

            project.data_fields_project_organization.forEach((x)=> {
                    
                if(!data_field_groups[x.group]) data_field_groups[x.group] = {"group": x.group, "fields": [], "edit": false};
            
                data_field_groups[x.group].fields.push(Object.assign({}, x));
                
            });
            this.data_field_groups = Object.assign({}, data_field_groups)
            this.project = project;
            this.$store.dispatch('stop_loading');
        });
    },

  },

  components: {
    draggable,
  }

}

</script>
<style lang="scss" scoped>
@import '@/_vars.scss';
.column-width {

}

.max-height-column {

}

.active {
   
    box-shadow: 0 3px 12px 0 hsla(0,0%,0%,0.5);
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;

  background: #F7FAFC;
  border: 1px solid #4299e1;
}

.cursor-move, .click{
  cursor: pointer;
}


.card-drag  {
  .remove-project-btn {
    display: none;
  }
}
.card-drag:hover  {
  .remove-project-btn {
    display: inline-block;
  }
}
</style>