<template lang="pug">
    
.repsonses()

  div(v-if="survey_templates && !survey_templates.length") You don't have created any surveys for this project.   
  div(v-if="surveys != null && $store.state.user")
   
    div(v-if="!surveys.length") You have not sent any surveys yet.
    .row.mt-3()
        
        .col-12.col-lg-3
           
            //b-btn(variant="outline-primary", size="sm", to="surveys/new").form-control Create project survey
            b-list-group(style="overflow-y: auto", v-if="surveys != null")
                b-list-group-item(:active="selected_survey == null" button, @click="selected_survey=null").mb-2 
                    b-form(style="max-width: 200px")
                        b-form-group(label="Select Survey", v-if="survey_templates && survey_templates.length > 0")
                        
                            b-select(:options="survey_templates", value-field="_id", text-field="name", size="sm", v-model="selected_template", @input="get_templates()")

                    div
                        h3 {{surveys.length}}
                        .label Responses<br/>
                    
                template(v-for="survey in surveys")
                    b-list-group-item(:active="selected_survey == survey" button,  @click="selected_survey=survey").d-flex.justify-content-between.align-items-center 
                        .media
                            .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + survey.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                            .media-body {{survey.relations.organization_id.name}}
                                .small {{survey.relations.survey_template_id.name}}
                        .text-gray.small {{survey.updated_at | utcFrom}} 

        .col-12.col-lg-9(style="max-width: 1000px")
            
        
            template(v-if="selected_survey == null")
                template(v-if="aggregation")
                    SurveyAggregation(:survey_template="survey_templates.filter(x => x._id == selected_template)[0]", v-model="aggregation")
            
            template(v-if="selected_survey")
                b-card()
                    .media
                        .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + selected_survey.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                        .media-body 
                            a(:href="'/ecosystem/orgs/' + selected_survey.relations.organization_id._id") {{selected_survey.relations.organization_id.name}}, {{selected_survey.relations.organization_id.web_entity}}
                    table.table.small.mt-3

                        tr
                            td() Survey Link
                            td() 
                                b-link(:href="get_survey_link(selected_survey)", target="_blank") {{get_survey_link(selected_survey)}}

                        tr
                            td() Created
                            td() {{selected_survey.created_at | moment("DD.MM.YYYY hh:mm")}}

                        tr
                            td() Sent at
                            td() 
                                | {{selected_survey.sent_at | moment("DD.MM.YYYY hh:mm")}}
                                b-btn(@click="send_surveys([selected_survey])", variant="outline-primary", size="sm", v-if="!selected_survey.sent_at") Send survey
                        
                        tr
                            td() Opened at
                            td() {{selected_survey.opened_at | moment("DD.MM.YYYY hh:mm")}}
                        
                        tr
                            td() Responded at
                            td() {{selected_survey.responded_at | moment("DD.MM.YYYY hh:mm")}}

                        tr
                            td() Matched at
                            td() {{selected_survey.confirmed_at | moment("DD.MM.YYYY hh:mm")}}
                template(v-if="selected_survey.responded_at")
                    h4.mt-5 Results
                    Survey(:survey_template="selected_survey.relations.survey_template_id", @saved="getData()" v-model="selected_survey", :user="selected_survey.relations.owner_id", :edit_mode="false", :editable="false").mt-3
       



</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import Survey from '@/components/Survey.vue'
import SurveyAggregation from '@/components/SurveyAggregation.vue'

import User from '@/components/User.vue'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Assessement',
  
  data: function () {
      return {
          to_send: [],
          to_match: [],
          to_resend: [],
          survey_template: null,
          selected_survey: null,
          aggregation: null,
          surveys: null,
          save_selected_id: null,
          selected_template: null,
          survey_templates: null,
          has_own_assessment: false,
          sort_by: 'name'
    
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    
    if(this.$route.query.survey_id) {
        this.save_selected_id = this.$route.query.survey_id;
    }

    this.get_templates();
    this.getData();
  },

  watch: {
      selected_template: function(val) {
          this.$router.push({query: {survey_template_id: val, "survey_id": this.$route.query.survey_id}});
      },

      "$route.query.survey_template_id": function() {
        this.get_templates();
        this.getData();
    
      },
       "$route.query.survey_id": function(val) {
        this.save_selected_id = val;
        this.get_templates();
        this.getData();
    
      }
  },

  computed: {
       
        ...mapFields([
            'sidebar_project.selected_item'
        ]),
  },

  
  methods: {


    get_templates() {
      
        if(this.selected && this.selected._id) this.$router.replace({"path": "/projects/" + this.$route.params.id + "/survey_templates/"+this.selected._id});
      
        this.$store.dispatch("start_loading");
        this.filter = Object.assign({"project_id": [this.$route.params.id]}, this.filter);
        this.filter.project_id = [this.$route.params.id];
        Vue.http.post(`${Config.config.base_host}/survey_templates/search`, this.filter).then(res => {
            this.survey_templates = res.body;
            
            if(this.$route.query.survey_template_id) {
                this.selected_template = this.$route.query.survey_template_id;
            }

            else if(this.survey_templates && this.survey_templates.length > 0) {
                this.selected_template = this.survey_templates[0]._id;
            }
            
            this.$store.dispatch("stop_loading");
        });

      },

      get_group(status, surveys) {
          
          if(status == 'Considered') return surveys.filter(survey => !survey.sent_at);
          if(status == 'Sent') return surveys.filter(survey => survey.sent_at && !survey.responded_at);
          if(status == 'Responded') return surveys.filter(survey => survey.responded_at && !survey.confirmed_at);
          if(status == 'Matched') return surveys.filter(survey => survey.confirmed_at);
      },

      get_survey_link(survey) {
          return window.location.protocol + "//" + window.location.host + '/surveys_external/' +  survey.link_token;
      },

    send_surveys(surveys, resend = false) {
        this.$store.dispatch("start_loading");
        this.save_selected_id = this.selected_survey._id;
        Vue.http.post(`${Config.config.base_host}/surveys/send_bulk`, {"survey_ids": surveys.map(x => x._id), "resend": resend}).then(() => {
            this.getData();
            this.$store.dispatch("stop_loading");
        });
      },

    match_surveys(surveys) {
        this.$store.dispatch("start_loading");
        this.save_selected_id = this.selected_survey._id;
        Vue.http.post(`${Config.config.base_host}/surveys/match_bulk`, {"survey_ids": surveys.map(x => x._id)}).then(() => {
            this.getData();
            this.$store.dispatch("stop_loading");
        });
      },
    
      getData() {
        
        this.$store.dispatch("start_loading");

        Vue.http.post(`${Config.config.base_host}/surveys/search`, {"project_id": [this.$route.params.id], "survey_template_id": [this.$route.query.survey_template_id], "enrich": ['organization_id', 'survey_template_id']}).then(res => {
            this.surveys = res.body;

            if(this.save_selected_id) {
                try {
                    this.selected_survey = this.surveys.filter(s => this.save_selected_id == s._id)[0];
      
                } catch {
                    this.selected_survey = null;
                }

                this.save_selected_id= null;
            } else {
                this.selected_survey = this.surveys[0];
            }

           
            this.$store.dispatch("stop_loading");
        });
        
        Vue.http.post(`${Config.config.base_host}/surveys/aggregation`, {"survey_template_id": [this.$route.query.survey_template_id], "project_id": [this.$route.params.id]}).then(res => {
            this.aggregation = res.body;
            this.$store.dispatch("stop_loading");
        });
      
      }
     
  },
  components: {
      Survey,
      SurveyAggregation,
      User
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>