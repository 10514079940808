<template lang="pug">

    .home.mt-2(v-if="topics_all && topic")
        .row
            .col-6
                b-btn(variant="outline-primary", size="sm" href="$route.go(-1)") <i class="fas fa-long-arrow-alt-left"></i> Back
                h3.mt-4 {{topic.name}}

                div(v-for="it in topic.custom_data")
                    | {{it.value}}

                div(v-if="topic.parent_ids && topic.parent_ids.length").mt-5
                    div(v-for="ide in topic.parent_ids").mr-1 <label>{{topics_dict[ide].topic_type}}</label> {{topics_dict[ide].name}}
            .col-6
                 b-card(title="Companies" no-body)
                    .p-3
                        b Companies
                        .float-right
                            b-btn(size="sm", variant="outline-primary", href="/ecosystem") Match companies
                    b-list-group()
                        b-list-group-item(v-for="topic in topics_all.slice(10,20)") {{topic.name}}
</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import UseCaseCard from '@/components/UseCaseCard.vue'
import TopicModal from '@/components/TopicModal.vue'
export default {
  
  name: 'TopicOverview',
  
  data: function () {
      return {
        topic: null,
        topics_all: null,
       

        selected_topic: "",
        topics_dict: {}
      }
  },

  mounted: function() {
    
    Vue.http.get(`${Config.config.base_host}/topics_v1`).then((res) => {
        this.topics_all = res.body;
        
        this.topics_all.forEach((it) => {
            this.topics_dict[it._id] = it;
        });

    });

    this.host = Config.config.base_host;
    this.getData();
  },


  computed: {

  },

  methods: {

    getData() { 
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/topics_v1/${escape(this.$route.params.usecase_id)}`).then(res => {
        this.topic = res.body;
        
        this.$store.dispatch("stop_loading");
      });
    },

    showModal(topic) {
      this.$refs.topicModal.open(topic);
    },
  },

  components: {
      UseCaseCard,
      TopicModal
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>