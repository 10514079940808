<script>

import { mixins, Line } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    name: 'LineChart',
    extends: Line,
    mixins: [reactiveProp],
    props: ['options', 'chartdata'],
    
    mounted () {

        this.renderChart(this.chartdata, this.options)    
    },

    watch: {
        chartdata () {
            
            this.renderChart(this.chartdata, this.options);
        }
    },


    data () {
        return {
           
        }
    },
    
    methods: {
    
    }
}
</script>

<style scoped lang="scss">
@import '~@/_vars.scss';



</style>