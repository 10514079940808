<template lang="pug">
  .search-filter()
    b-form(autocomplete="off")

        //FilterGroup(title="Search in" :collpased="true").mt-1
          b-form-group()
            b-check(checked) Ecosystem
            b-check(checked) Scouting Database

        FilterGroup(title="Filter", :collpased="true").mt-3
          //ProjectStageSelect(placeholder="Enter a name and press enter", size="sm", @input="getData()", v-model="project_id", :taggable="true", :multiple="true").small
          
          TopicKeywordSelect(id="keyword", v-model="keywords", @input="getData()", :show-recommendations="false", :advanced_query="false").mt-1


          template(v-for="df in instance_config.project_data_fields", v-if="df.filterable")
            label.mt-3 {{df.label}}
            
            v-select(:id="df.name+'id'", v-model="custom_data[df.name]", @input="getData()", :multiple="true", :options="(df.options == 'tags' ? project_filter_options[df.name + '_options'] : df.options)").small.mt-1
          
          

          .mt-3
           
            b-checkbox(v-model="joined", @input="getData()") Joined by me
            //b-checkbox(v-model="bookmarked", @input="getData()") Bookmarked by me
     
     

        //.mt-3
          
          b-button(variant="outline-secondary", @click="getData()", size="sm")#project-search-button.form-control Search projects
          

</template>

<script>
// @ is an alias to /src

import Config from '@/config'

import ProjectStageSelect from '@/components/ProjectStageSelect'
import FilterGroup from '@/components/FilterGroup'
import TaxonomySelect from '@/components/TaxonomySelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'ProjectFilter',

  data: function () {
    return {

    }
  },

  mounted: function() {
      this.$store.dispatch("get_projectfilter_options");
      this.host = Config.config.base_host;
      if(this.$route.query.keyword) {
        this.keywords = [this.$route.query.keyword];
        this.getData();
      }
  },

  computed: {

    ...mapFields([
        'instance_config',
        'projects.query.filter.keywords',
        'projects.query.filter.project_id',
        'projects.query.filter.custom_data',
        'projects.query.filter.joined',
        'projects.query.filter.bookmarked',
        'project_filter_options'
      
        
    ]),
  },
  
  methods: {
    
    getData() {
        this.$emit('search');
        //this.$store.dispatch("projectSearch", this.$route.params.id);
    },

  },
  components: {
    FilterGroup,
    TaxonomySelect,
    TopicKeywordSelect,
    ProjectStageSelect
  }
}

</script>
<style lang="scss" scoped>

</style>
