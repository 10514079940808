<template lang="pug">
  .project-modal
  
    b-modal(id="project-modal", :title="title", size="lg", :no-close-on-backdrop="true", ok-variant="outline-primary", cancel-variant="link", ref="project-ref",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        .form-row()
        
          .col-12
            b-form-group(id="name-group"  
                        label="Name"
                        size="sm"
                        label-for="name"
                        description="",
                        v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                    b-form-input(id="name"
                                type="text"
                                :state="!submitStatus ? null : !$v.form.name.$invalid"
                                size="sm"
                                placeholder="", v-model="form.name")
                                
          
          //.col-12
            b-form-group(id="description-group"
                        label="Description"
                        label-for="exampleInput1",
                   
                        description="")
                    b-textarea(id="description"
                                type="text"
                                size="sm"
                                placeholder="", @change="", v-model="form.description")

          .col-12
            
                     
            b-form-group(id="description-group"
                        label="Description"
                        label-for="exampleInput1",
                   
                        description="")
                  
                    h-editor(id="description"
                                type="text"
                                size="sm"
                                placeholder="", v-model="form.description")

            template(v-for="df in instance_config.project_data_fields")
              label.mt-3 {{df.label}}

              template(v-if="df.data_type == 'select'")
                v-select(:id="df.name+'id'", v-model="form.custom_data[df.name]", :multiple="false", :options="(df.options == 'tags' ? project_filter_options[df.name + '_options'] : df.options)").small.mt-1
              
              template(v-if="df.data_type == 'multi_select'")
                v-select(:id="df.name+'id'", v-model="form.custom_data[df.name]", :multiple="true", :options="(df.options == 'tags' ? project_filter_options[df.name + '_options'] : df.options)").small.mt-1

              template(v-if="df.data_type == 'tags'")
                v-select(:id="df.name+'id'", v-model="form.custom_data[df.name]", :multiple="true", :taggable="true", :options="project_filter_options[df.name + '_options']").small.mt-1

              template(v-if="df.data_type == 'text'")
                b-input(:id="df.name+'id'", v-model="form.custom_data[df.name]", :placeholder="df.description").small.mt-1
            

          

        .form-row.mt-3

          b-tabs(style="width: 100%" card, size="sm")
       
            b-tab(title="Team", id="tab-info")
              .form-row
                  //.col-12
                     b-form-group(id="owner_id-group"
                                  label="Project Owner"
                                  label-for="exampleInput1"
                                  description="")
                              
                                UserMultiSelect(id="owner_id"
                                          :multiple="false"
                                          :close-on-select="true",
                                          placeholder="", v-model="form.owner_id")
                  //.col-12
                     b-form-group(id="owner_id-group"
                                  label="Project Owner"
                                  label-for="exampleInput1"
                                  description="")
                              
                                UserMultiSelect(id="owner_id"
                                          :multiple="false"
                                          :close-on-select="true",
                                          placeholder="", v-model="form.owner_id", :disabled="true")

                  .col-12
                      b-form-group(id="members-group"
                                  label="Project Member"
                                  label-for="exampleInput1"
                                  description="")
                              
                                UserMultiSelect(id="members"
                                          :close-on-select="true",
                                          placeholder="", v-model="form.member_ids")
        
                  
                  .col-12
                     b-form-group(id="owner_check_group"
                                  label="Visibility and Access"
                                  label-for="owner_check"
                                  description="")
                              
                                b-check(v-model="form.visible_members_only", id="owner_check") Only project members can see and work in the project
                
                                //CountrySelect(v-model="form.location_country_code", id="location_country_code", size="sm")
                                
            b-tab(title="Companies", size="sm", id="tab-investments")
              .row
                .col-12
                  b-form-group(id="stages-group"
                              label="Funnel/ Board Stages"
                              label-for="exampleInput1"
                              description="").mt-2
                          
                          v-select(id="stages"
                                  :options="[]",
                                  :multiple="true"
                                  :taggable="true"
                                  :close-on-select="false"
                                  :no-drop="true"
                                  placeholder="Enter a stage name and press enter"
                                  :select-on-tab="true"
                                  v-model="form.stages")
              .row
                .col-12
                    b-form-group(id="board_show_assign_owner_group"
                                  label=""
                                  label-for="owner_check"
                                  description="")
                              
                                b-check(v-model="form.board_show_assign_owner", id="board_show_assign_owner") Display owner assignment in cards
                    
                    b-form-group(id="board_show_compact_group"
                                label=""
                                label-for="board_show_compact"
                                description="Show minimized version in order to display more cards.")
                            
                              b-check(v-model="form.board_show_compact", id="board_show_compact") Compact Cards
            

            b-tab(title="Topics", size="sm", id="radar-investments")
              
              .row
                .col-12
                  b-form-group(id="radar_quadrants-group"
                              label="Groups"
                              label-for="radar_quadrants"
                              description="For optimal radar display exactly four groups").mt-2
                          
                          v-select(id="radar_quadrants"
                                  :options="[]",
                                  :multiple="true"
                                  :taggable="true"
                                  :close-on-select="false"
                                  :no-drop="true"
                                  placeholder="Enter a group name and press enter"
                                  :select-on-tab="true"
                                  v-model="form.groups")

                  b-form-group(id="radar_stages-group"
                              label="Topic Stages"
                              label-for="radar_quadrants"
                              description="For optimal radar display exactly four groups").mt-2
                          
                          v-select(id="radar_stages"
                                  :options="[]",
                                  :multiple="true"
                                  :taggable="true"
                                  :close-on-select="false"
                                  :no-drop="true"
                                  placeholder="Enter a stage name and press enter"
                                  :select-on-tab="true"
                                  v-model="form.topic_stages")
                  
                  b-form-group(id="radar_input_field-group"
                              label="Radar input field"
                              label-for="radar_input_field"
                              description="").mt-2
                          
                          b-select(id="stages"
                                  :options="['Investment Trend']",
                                  size="sm"
                                  v-model="form.radar_input_field")


            b-tab(title="Ideas", size="sm", id="tab-investmentsd")
              .row
                
                .col-12
                  b-form-group(id="stages-group"
                              label="Item type name"
                              label-for="exampleInput1"
                              description="").mt-2
                          
                          b-input(v-model="form.general_name")
              
              .row
                .col-12
                  b-form-group(id="stages-group"
                              label="Funnel / Board Stages"
                              label-for="exampleInput1"
                              description="").mt-2
                          
                          v-select(id="general_stages"
                                  :options="[]",
                                  :multiple="true"
                                  :taggable="true"
                                  :close-on-select="false"
                                  :no-drop="true"
                                  placeholder="Enter a general stage name and press enter"
                                  :select-on-tab="true"
                                  v-model="form.general_stages")
                        
              .row
                .col-12

                    b-form-group(id="board_show_compact_group"
                              label=""
                              label-for="board_show_compact"
                              description="Display idea board in submenu")
                          
                            b-check(v-model="form.general_use_board", id="general_use_board") Use Idea board 



            // b-tab(title="Data Fields", size="sm", id="tab-investments")
              .row
                .col-12
                  b-form-group(id="stages-group"
                              label="Funnel/ Board Stages"
                              label-for="exampleInput1"
                              description="").mt-2
                          
                          v-select(id="stages"
                                  :options="[]",
                                  :multiple="true"
                                  :taggable="true"
                                  :close-on-select="false"
                                  :no-drop="true"
                                  placeholder="Enter a stage name and press enter"
                                  :select-on-tab="true"
                                  v-model="form.stages")


            //b-tab(title="Evaluation", size="sm", id="tab-investments")
              .row
                .col-12
                  b-form-group(id="stages-group"
                              label="Funnel/ Board Stages"
                              label-for="exampleInput1"
                              description="").mt-2
                          
                          v-select(id="stages"
                                  :options="[]",
                                  :multiple="true"
                                  :taggable="true"
                                  :close-on-select="false"
                                  :no-drop="true"
                                  placeholder="Enter a stage name and press enter"
                                  :select-on-tab="true"
                                  v-model="form.stages")

            //b-tab(title="Social / Profiles", size="sm", id="tab-profiles")
            
              .row.mt-3
                div().col-6

                        b-form-group(id="exampleInputGroup1"
                              label="LinkedIn"
                              size="sm"
                              label-for="linkedin_url"
                              description="")
                          
                          b-form-input(id="linkedin_url"
                                      type="text"
                                      size="sm",  v-model="form.linkedin_url")
                                      
                        b-form-group(id="exampleInputGroup1"
                                label="Crunchbase"
                                label-for="crunchbase_url"
                                description="")

                            b-form-input(id="crunchbase_url"
                                        type="text"
                                        size="sm", v-model="form.crunchbase_url")
                          
                        b-form-group(id="exampleInputGroup1"
                                label="Pitchbook"
                                size="sm"
                                label-for="pitchbook_url"
                                description="")
                            b-form-input(id="pitchbook_url"
                                        type="text"
                                        size="sm", v-model="form.pitchbook_url")
                            
                div().col-6

                        b-form-group(id="exampleInputGroup1"
                                label="Twitter"
                                label-for="twitter_url"
                                description="")

                            b-form-input(id="twitter_url"
                                        type="text"
                                        size="sm", v-model="form.twitter_url")


                        b-form-group(id="exampleInputGroup1"
                              label="Facebook"
                              label-for="facebook_url"
                              description="")

                          b-form-input(id="facebook_url"
                                      type="text"
                                      size="sm", v-model="form.facebook_url")
                       
                        
                        b-form-group(id="exampleInputGroup1"
                                label="AngelList"
                                size="sm"
                                label-for="angel_url"
                                description="")
                            b-form-input(id="angel_url"
                                        type="text"
                                        size="sm", v-model="form.angel_url")

        
      template(slot="modal-footer")
            
            //b-btn(variant="outline-secondary" @click="$refs.modal1.hide(false)") Close
            b-btn(variant="primary"  @click="save()")#project-modal-save {{ok_btn_text}}



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import Config from '@/config'
import Vue from 'vue'
import TaxonomyTagger from '@/components/TaxonomyTagger'
import { mapFields } from 'vuex-map-fields';

export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
        
        uid: null,
        title: "Add a new project",
        form: {"description": "", "name": "", member_ids: [], custom_data: {}, owner_id: null, stages: ["Watch", "Review", "Closed", "Rejected"], status: 'Ongoing', general_use_board: false},
        options: [],
        submitStatus: null,
        ok_btn_text: "Add Project",
        call_origin: null
    }
  },

  mounted: function() {
     this.$store.dispatch("get_projectfilter_options");
     this.uid = this._uid;
  },

   computed: {

    ...mapFields([
        'instance_config',
        'project_filter_options'
    ]),

  },

  methods: {

    open(project, call_origin = "projects") {
      this.call_origin = call_origin;
      if(project) {
        this.form = JSON.parse(JSON.stringify(project));
        this.ok_btn_text = "Save";
        this.title = "Edit " + project.name;
      } else  {
        this.form = {"description": "", "name": "", member_ids: [], owner_id: null, custom_data: {}, stages: ["Review", "Closed", "Rejected"], status: 'Ongoing', general_use_board: false};
        this.form.owner_id = this.$store.state.user.id;
      }
      
      

      return this.$refs['project-ref'].show()
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        Vue.http.post(`${Config.config.base_host}/projects`, this.form).then(res => {
          if(this.call_origin == "ecosystem") {
            this.$refs['project-ref'].hide();
          } else {
            location.href="/projects/" + res.body._id + '/dashboard';
          }
        });
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TaxonomyTagger
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
