<template lang="pug">
  
  .home
    Topic2Modal(ref="topicModal")
    .row
          .col-6
            div(style="max-width: 800px").text-muted 
              | Create a topic and add keywords to it. You’ll get company suggestions and news displayed based on these created topics.
        
          .col-6
              .float-right
                  b-btn(variant="outline-secondary", size="sm", @click="showModal()")#add-topic-btn Add topic    
    .row
        .col-12 
          
            div.mt-2(v-if="topics && !topics.length")
              | You have not created a topic yet. 

    //b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="4").form-row.mt-3
          b-col(v-for="topic in topics").mb-2
              a(@click="showModal(topic)")
                  
                  TopicCard(:topic="topic")
    div(v-if="this.$store.getters.project")
      TopicOverview(:show_industries="false", :project="this.$store.getters.project")

</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import TopicCard from '@/components/TopicCard.vue'
import Topic2Modal from '@/components/Topic2Modal.vue'
import TopicOverview from '@/views/topics/Overview.vue'

export default {
  
  name: 'TopicOverview2',
  
  data: function () {
      return {
        topics: null
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();
  },


  computed: {

  },

  methods: {

    getData() { 
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/topics_v1/search`, {"project_id": [this.$route.params.id]}).then(res => {
        this.topics = res.body.rows;
        this.$store.dispatch("stop_loading");
      });
    },

    showModal(topic) {
    
        this.$refs.topicModal.open(topic, this.$store.getters.project);
    },
  },

  components: {
      TopicCard,
      Topic2Modal,
      TopicOverview
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>