<template lang="pug">

.home(v-if="$store.getters.project")
  b-overlay(:show="$store.state.loading")
    .row.mt-1
      .col-8

        b-form(inline)
          b-form-group(label="From date").mr-3
            b-datepicker(v-model="filter.start_date", :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }")
          b-form-group(label="To date").mr-3
            b-datepicker(v-model="filter.end_date", :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }")
          
          b-form-group(label="Interval").mr-3
            b-select(:options="[{value: 'MS', text: 'Monthly'}, {value: 'D', text: 'Daily'}]", v-model="filter.interval")
          
          
          b-btn(size="sm", @click="get_data()").mt-4 Show
      .col-4
        .float-right
          b-form-group(label="Filter stages").full-width
            b-dropdown(id="dropdown-right" right text="Stages" variant="outline-secondary" size="sm")
              b-dropdown-form(v-for="stage in $store.getters.project.stages", style="min-width: 300px")
                b-check(:label="stage", :value="stage", v-model="stages") {{stage}}
            
            
    .row.mt-1
      .col-md-12        
        b-card.mt-1(v-if="res_data && totals").mt-3
          .row
            .col-2(v-for="total in totals", v-if="stages.includes(total.stage)")
              label {{total.stage}}
              h1 {{total.count}}
              div(style="max-width: 600px; max-height: 200px").mb-5
                //| {{ {'labels': Object.keys(res_data[total.stage]), 'datasets': [{'label': total.stage, 'pointRadius': 0, data: Object.keys(res_data[total.stage]).map((x) => res_data[total.stage][x] )}]} }}
                LineChart(style="max-height: 150px", :chartdata="{'labels': Object.keys(res_data[total.stage]), 'datasets': [{lineTension: 0.4, color: '#aac0d1', backgroundColor: '#aac0d1', pointRadius: 0, 'label': total.stage, data: Object.keys(res_data[total.stage]).map((x) => res_data[total.stage][x] )}]}", :options="line_options")
                //trend(
                  :data="Object.keys(res_data[total.stage]).map((x) => {  return res_data[total.stage][x] })"
                  :gradient="['#6fa8dc', '#42b983', '#2c3e50']",
                  :stroke-width="3"
                  :padding="6"
               
                  :autoDrawDuration="500"
                  :min="0"
                  auto-draw
                  smooth)
                //.small(style="font-size: 0.6em").text-muted &uarr; Count / Time &rarr;

        b-card.mt-4
          label Table overview
          .float-right
            b-btn(@click="download()", size="sm", variant="outline-secondary") download
          
          .table-container(style="overflow-x: auto")
            table.table(hover small, v-if="res_data").small.mt-2
              tr()
                td() Stage {{res_data[0]}}
                td(v-for="(row, key) in res_data[Object.keys(res_data)[0]]").small {{key.substring(0,10)}}

              tr(v-for="(row, key) in res_data")
                td()
                  template(v-if="key == '__totals__'")
                    b Total#
                  template(v-else)
                    b {{key}}
          
                td(v-for="e in row")
                  | {{e}}       
          
</template>

<script>
// @ is an alias to /src

import Vue from "vue";
import Config from "@/config";

import ProjectFilter from "@/views/projects/ProjectFilter";
import BarChart from "@/components/BarChart"
import LineChart from "@/components/LineChart"


import { mapFields } from "vuex-map-fields";


export default {
  name: "Analysis",

  data: function() {
    return {
        line_options: {
          animation: {duration: 0},
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          bezierCurve: true,
         
          scales: {
              xAxes: [{
                type: 'time',
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                time: {
                    unit: 'month',
                    displayFormats: {
                        month: 'MM/YY'
                    }
                }
              }],
              yAxes: [{
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                ticks: {min: 0, stepSize: 1}
              }]
          }
        },
        chartOptions: {scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  },
                  gridLines: {
                    display: true
                  }
                }],
                xAxes: [ {
                  gridLines: {
                    display: false
                  }
                }]
              },
              legend: {
                display: false
              },
              responsive: true,
              maintainAspectRatio: false
      },
      res_data: {},
      totals: {},
      config: {},
      stages: [],
      filter: {
        start_date: null,
        end_date: null,
        interval: "MS"
      },
      chartData:  {
          labels: [],
          datasets: []    
      },
      aggregation: {"bucket_fields": [], "target_fields": [], "context_type": "Project"},
      bucket_options: [],
      target_options: [],
      agg_options: ["sum", "mean", "count", "min", "max"],

      fields: [
        
      ],
      currentPage: 0
    };
  },

  watch: {
    stages: function() {
      localStorage.setItem("esm_stage_analytics_stages", JSON.stringify(this.stages));
    }
  },

  mounted: function() {

    this.stages = this.$store.getters.project.stages;

    console.log(Config);
    let now = new Date();
    this.filter.start_date = new Date(now.getFullYear(), 0, 1);
    this.filter.end_date = now;
    this.host = Config.config.base_host;
    this.get_data();
  },

  computed: {
    ...mapFields(["query.keywords"]),
     
      myStyles () {
            return {
                height: "500px",
                "max-height": "500px",
                position: 'relative'
            }
        },
        myStylesSmall () {
            return {
                height: "300px",
                "max-height": "300px",
                position: 'relative'
            }
        },
   
  },

  methods: {
    createChartData() {
     
      this.data.forEach((it) => {
          
          this.chartData.labels.push(it.country_code);
          this.chartData.datasets.push({fill:false, 'label': it.country_code, fillColor: null, borderColor: '#f87979', data: it.total_funding_usd}); 
          
      });
      this.$refs['chart'].$data._chart.update();
              
    },

    convertToCSV(objArray) {

     const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
     let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}` + '\r\n';

     return array.reduce((str, next) => {
         str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
         return str;
        }, str);
    },



    download() {  
    
      let data_copy = Object.assign({}, this.res_data);

      let content = [];
      Object.keys(data_copy).forEach((section)=>{
        console.log(data_copy[section]);
        data_copy[section]['stage'] = section;
        content.push(data_copy[section]);
      });

      let finalVal = this.convertToCSV(content);

      var download = document.createElement('a');
      download.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(finalVal));
      download.setAttribute('download', 'test.csv');
      download.click();


    },
    get_data() {
      
      this.$store.dispatch("start_loading");
      Vue.http
        .post(`${Config.config.base_host}/projects/${this.$route.params.id}/stage_analytics`, this.filter)
        .then(res => {
          this.totals = res.body.totals;
          this.res_data = res.body.interval;
          
          this.$store.dispatch("stop_loading");
        
        }, () => {
            this.$store.dispatch("stop_loading");
            this.$bvToast.toast(`Invalid aggregation parameters, please check your aggregation configuation.`, {
              title: 'Error',
              variant: 'primary',
              autoHideDelay: 5000,
              });
        });
    }
  },
  components: {
    ProjectFilter,
    BarChart,
    LineChart
  }
};
</script>
<style lang="scss" scoped>
</style>