
<template lang="pug">

  .media
    
      //b-check(v-model="value.selected").mr-2
      
      router-link(:to="'/ecosystem/orgs/'+value.relations.organization_id._id")
        Logo(:web_entity="value.relations.organization_id.web_entity", :name="value.relations.organization_id.name").mr-3

      .media-body
          .row
              .col-9 
                  h5
                      router-link(:to="'/ecosystem/orgs/' + value.relations.organization_id._id") {{value.relations.organization_id.name}} 
                  div.links()
                      a(:href="value.relations.organization_id.homepage_url" target="_blank" v-if="value.relations.organization_id.homepage_url").mr-1.small {{value.relations.organization_id.domain}}
                  
                  .text-muted.small {{value.relations.organization_id.city}} {{value.relations.organization_id.country_code}}
                                          
                  p(style="max-width: 600px") {{value.relations.organization_id.short_description}}
                  .badge.badge-secondary.mr-1(v-for="it in value.relations.organization_id.category_list") {{it}}
                  .badge.badge-secondary.mr-1(v-for="it in value.relations.organization_id.category_groups_list") {{it}}
              
              .col-3
                  .b-card()
                      b-form()
                        b-form-group(id="stages-group"
                            label="Project account owner"
                            label-for="exampleInput1"
                            description="").mt-2
                          UserSelect(v-model="value.owner_id")
                        
                        b-form-group(id="stages-group"
                            label="Stage"
                            label-for="stage"
                            description="").mt-2
                          b-select(id="stage"
                                  :options="stageOptions",
                                  placeholder="Not assigend"
                                  size="sm"
                                  v-model="value.stage")
                  

</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'

import UserSelect from '@/components/UserSelect.vue'
import TagSelect from '@/components/TagSelect.vue'
import Logo from '@/components/Logo.vue'

export default {
  name: 'CompanySelect',
  props: {
    "value":  {
      default: null
    },
   
    "collpased": {
      type: Boolean,
      default: false
    }, 

    "stageOptions": {
      type: Array,
      default: null
    }, 

    "selectMode": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
        options: null
    }
  },

  mounted: function() {
     this.uid = this._uid;
     
  },

  watch: {
        'value.selected': function(val) {
          this.$emit("input", val);
        }
  },
  
  
  methods: {
    
   onSearch(search, loading) {
      
      //loading(true);
      this.search(loading, search, this);

    },

    search: debounce((loading, search, vm) => {
      
      Vue.http.get(`${Config.config.base_host}/users/search/autocomplete?q=${escape(search)}`).then(res => {
        vm.options = res.body;
        loading(false);
      });

    }, 350)
  },

  components: {
    Avatar,
    UserSelect,
    TagSelect,
    Logo
  }
}

</script>
<style lang="scss" scoped>

@import '@/_vars.scss';

.feed-image {
    width: 50px;
    height: 50px;
}

.bg-gray-100 {
  background-color: $gray-500;
}
</style>
