<template lang="pug">
  
.home-s(class="")
    //.row
      //.col-md-2
        ProjectFilter()
      //.col-md-12()
       
          b-button-group(size="sm").mb-2
                b-btn(active, size="sm", @click="detailCols=!detailCols") Quick edit    
                //b-btn(size="sm") Website review   
    //.row
      .col-12
        b-btn(@click="prev()", size="sm", variant="outline-secondary").mr-1 &lt; 
        b-btn(@click="next()", size="sm", variant="outline-secondary").mr-1  &gt;
        .float-right
          
          b-btn(@click="browserWindow()", size="sm", variant="outline-secondary").mr-1 Website review
          //b-btn(@click="save(po.project_id, po.organization_id, po, true)", size="sm") Save & Next
        
    .row(style="margin-top: -30px")
      
      b-col(:cols="12")
          
        b-card(v-if="total_rows==0").mb-1 There are no companies added to the project yet.
        b-overlay(:show="$store.getters.loading" size="sm", top,).small
          b-card(id="table-content", v-if="tableData")
              //b-card
            
              //b-form-checkbox-group(v-model="selectedFields", :options="fieldOptions")
              //vue-virtual-table(
                :config="tableConfig"
                :data="tableData"
                :height="calcH()"
                :itemHeight="30"
                :minWidth="10"
                :selectable="true"
                :enableExport="true"
                v-on:changeSelection="onRowSelected")
             
              #tbl.tbl
                .tblrow.font-weight-bold(style="min-height: 40px; border-bottom: 1px solid #ccc")
                  .cell 
                    h6 List
                  
                  
                  .cell
                    .float-right
                      b-form-group(label="Sort by", label-cols="4", style="min-width: 200px")
                        b-select(:options="[{text: 'Name', value: 'name'}, {text: 'Stage', value: 'stage'}]", size="sm", v-model="sortBy")
                  
                virtual-list(style="overflow-y: auto; height: 100%; width: 100%;"
                  :data-key="'_id'"
                  :keeps="100"
                  :data-sources="tableDataSorted"
                  :data-component="itemComponent"
                  @click="selected_project_organization=true"
                  ref="vscroll"
                  )
              


            
      
      b-col(:cols="po ? 4 : 0")
        #max-h
          div(v-if="po")
            b-card()
              .media

                .media-body
              a(:href="'/ecosystem/orgs/' + po.relations.organization_id._id")
                h6 {{po.relations.organization_id.name}}
              div
                a(:href="po.relations.organization_id.homepage_url" target="_blank" v-if="po.relations.organization_id.homepage_url")#company-profile-web_entity.mr-1.small {{po.relations.organization_id.web_entity}} 
                a(:href="po.relations.organization_id.twitter_url" target="_blank" v-if="po.relations.organization_id.twitter_url").mr-1  <i class="fab fa-twitter-square"></i>
                a(:href="po.relations.organization_id.facebook_url" target="_blank" v-if="po.relations.organization_id.facebook_url").mr-1  <i class="fab fa-facebook-square"></i>
                a(:href="po.relations.organization_id.linkedin_url" target="_blank" v-if="po.relations.organization_id.linkedin_url").mr-1  <i class="fab fa-linkedin"></i>
              .text-muted.small(v-if="po.relations.organization_id.short_description") {{po.relations.organization_id.short_description.substring(0,400)}} 
                template(v-if="po.relations.organization_id.short_description.length > 400") ...
              //.mt-3
                b-select(v-model="po.relations.project_id.stage", :options="project.stages", size="sm", @change="save(po.project_id, po.organization_id, po)")
                hr.mt-1.mb-0
                UserSelect(v-if="po.relations", v-model="po.relations.account_owner_id", @input="save(po.project_id, po.organization_id, po)")

            //div(v-if="po && $store.state.instance_config").mt-3
              SheetEmbedded(:organization_id="po.relations.organization_id._id", :configuration="$store.state.instance_config", @saved="savedSheet", @reload="open(po._id)").small.mb-5

            //div(:id="k" v-for="(fields, k) in custom_groups" v-if="po").small.mt-1

              label(id="project") Project data: {{k}}
              b-card()
                
                .mb-3(v-for="field in fields")
                  b-form-group(label-cols-sm="4", :label="field.label")
                  
                    template(v-if="field.data_type=='textarea'")
                      b-textarea(v-model="po.custom_data[field.name]", :placeholder="field.name['description']")
                    template(v-if="field.data_type=='text'", :placeholder="field.name['description']")
                      b-input(v-model="po.custom_data[field.name]")
                    template(v-if="field.data_type=='select'")
                      v-select(v-model="po.custom_data[field.name]", :options="field.options", :placeholder="field.name['description']")
                    template(v-if="field.data_type=='date'")
                      b-form-datepicker(v-model="po.custom_data[field.name]", :placeholder="field.name['description']")
                    template(v-if="field.data_type=='money_amount'")
                      currency-input(v-model="po.custom_data[field.name]", :placeholder="field.name['description']", )
                    template(v-if="field.data_type=='slider'") 
                      b-input-group(style="max-width: 400px", size="sm")
                        b-form-input(type="range", v-model="po.custom_data[field.name]", size="sm", :min="1", :step="1", :max="10")
                        b-input(v-model="po.custom_data[field.name]", :disabled="true", style="max-width: 40px")
       
                .mt-3.float-right
                  b-btn(variant="primary", size="sm", @click="save(po.project_id, po.organization_id, po)") Save
        

</template>

<script>
// @ is an alias to /src

import Config from "@/config";
import Vue from "vue";
import ProjectFilter from "@/views/projects/ProjectFilter";
import OrgView from "@/components/OrgView.vue";
import SheetEmbedded from "@/views/orgs/SheetEmbedded.vue";
import UserSelect from '@/components/UserSingleSelect.vue'
import VirtualList from 'vue-virtual-scroll-list'
import { mapFields } from "vuex-map-fields";
import Item from '@/components/Item'

export default {
  name: "list-view",

  data: function() {
    return {

      itemComponent: Item,
      sortBy: 'name',
      otherDataAssginToItemComponent: '',

      menuHidden: false,
      detailCols: false,
      project_organizations: null,
      //project: null,
      po: null,
      ctx: {},
      reviewMode: false,
      selectedFields: ["org.name"],
      bookmark: null,
      tableConfig: [],
      tableData: [],
      fields: [
        {
          key: "name",
          sortable: true,
          label: "Name",
          //stickyColumn: true
          //variant: 'secondary'
        },
        {
          key: "actions",
          //sortable: true,
          //label: "Name",
          //stickyColumn: true
          //variant: 'secondary'
        }
      ],
      custom_groups: {},
      perPage: 20,
      total_rows: null,
      currentPage: 1,
      s: {},
      q: "",
      w1: null,
      query: "",
      uuid: null,
      event_results: [],
      selected_project_organization: true,
      selected: [],
  
    };
  },
  created: function(){
      this.$on("list_change_id", (id) => {
       
        this.open(id);
      });

      this.$eventHub.$on('iframe_saved', () => {this.get_data()});
      

  },

  beforeDestroy() {
       this.$off("list_change_id");
       this.$eventHub.$off('iframe_saved')
       document.removeEventListener("keydown", this.keyPress)
  },



  mounted: function() {
  
  
    this.host = Config.config.base_host;
    //this.$store.dispatch("projectSearch", this.$route.params.id);
    this.get_data();
    let t = document.getElementById("tbl");
    
    if(t) {
      document.getElementById("tbl").style.height = (window.innerHeight-220) + "px";
    }
    
    document.addEventListener("keydown", this.keyPress)
   
    
  },

 
  computed: {
    tableDataSorted: function() {
      return this.$_.orderBy(this.tableData, (this.sortBy == 'name' ? ['name'] : ['relations.stage_sort', 'name']), ['asc'])
    },
    fieldOptions: function() {
      return this.fields.map(x => {
        return { text: x.label ? x.key : x.key, value: x.key };
      });
    },
    ...mapFields([
      'instance_config',
      'sidebar_project.selected_item',
      'sidebar_visible',
      'project.project'
    ]),
  },

  methods: {
    savedSheet(obj) {
      
      
      let el = this.tableData.filter((it) => { return it.id == obj._id })[0];
      
      for(let k in obj.custom_data) {
        if(el['custom_data.'+k] !== undefined) {
          el['custom_data.'+k] = obj.custom_data[k];
        }
        
      }
    },

     browserWindow() {
        const width = window.screen.width / 3;
        this.w1 = window.open(window.location.protocol + "//" + window.location.host + "/projects/redirect?url="  + "http://" +  this.po.relations.organization_id.web_entity, "w1", "width="+width+",height="+window.innerHeight);
      },

    showWindow() {
       
        if(this.w1) {
          this.w1.location.href = window.location.protocol + "//" + window.location.host + "/projects/redirect?url=" + "http://" + this.po.relations.organization_id.web_entity;
        }
      
      },


  
    getMapping(name, data_fields) {
      name = name.replace("project.custom_data.", "")
      console.log(name,  data_fields.filter(x=>x.name == name)[0])
      return data_fields.filter(x=>x.name == name)[0].label;
    },

  

   get_data() {
      let ctx = this.ctx;
     

      const params = ctx;
      params['project_id'] = [this.$route.params.id];
     
      params['limit'] = 200;
      //params['sort'] = ctx.sortBy.replace("project.custom_data.", "custom_data.");
      
      this.$store.dispatch("start_loading");
      
      Vue.http
        .post(
          `${Config.config.base_host}/organizations/search`,
            {"filter": params}
        )
        .then(res => {
         
          res.body.rows.forEach(x => {
            console.log(this.project.stages);
            x.relations.stage = x.index_space.projects.find(x => x._id == this.$route.params.id).stage;
            x.relations.stage_sort = ['Not assigned'].concat(this.project.stages).indexOf(x.relations.stage);
          });

          this.tableData = res.body.rows;
          this.$store.dispatch("stop_loading");
          this.total_rows = res.body.total_rows;
          
          
        })
        .catch(() => {
          this.tableData = [];
        });

      return null;
    },


     keyPress(e) {
     
      if(e.keyCode == 38) {
        this.prev();
      } else if(e.keyCode == 40) {
        this.next();
      }
    },

    prev() {
     
      let current = this.tableDataSorted.find((it) => it._id == this.selected_item._id);
      let ind = this.tableDataSorted.indexOf(current);
      
      if(ind > 0) {  
        
        this.open(this.tableDataSorted[ind-1]._id);
        this.$refs['vscroll'].scrollToIndex(ind-2);
      }
      
    },

   

    next() {
       let current = this.tableDataSorted.find((it) => it._id == this.selected_item._id);
      let ind = this.tableDataSorted.indexOf(current);
      
      if(ind < this.tableDataSorted.length-1) {
        
        this.open(this.tableDataSorted[ind+1]._id);
        this.$refs['vscroll'].scrollToIndex(ind);

      }
    },

    open(id) {
      this.tableData.forEach((item) => {

         if(item._id === id) {
           Vue.set(item, "selected", true);
         }  else {
           Vue.set(item, "selected", false);
         }
      
      });
      this.sidebar_visible = true;
      this.selected_item = this.tableData.filter(item => item._id == id)[0];

     
    },


  titleCase(string) {

      var sentence = string.toLowerCase().split(" ");
      for(var i = 0; i< sentence.length; i++){
         sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
  
       return sentence;
   },

    get_project_org(po_id) {
      
      Vue.http
        .get(
          `${Config.config.base_host}/project_organizations/${po_id}`
        )
        .then(res => {
          this.po = res.body;
          //this.project = this.po.relations.project_id;
          this.showWindow();
          this.custom_groups = {};
          if (!this.project.data_fields_project_organization) {
            this.project.data_fields_project_organization = [];
          }
          this.project.data_fields_project_organization.forEach(x => {
            if (!this.custom_groups[x.group]) this.custom_groups[x.group] = [];
            this.custom_groups[x.group].push(x);
          });

          this.$forceUpdate();
          
        });
    },

    save(project_id, org_id, po, next=false) {
      this.$store.dispatch("start_loading");
      Vue.http
        .put(
          `${Config.config.base_host}/project_organizations/${project_id}::${org_id}`,
          po
        )
        .then(() => {
          if(next) {
             
             
            this.next();
          }
          this.$store.dispatch("stop_loading");
           
        });
    }
  },
  components: {
    OrgView,
    ProjectFilter,
    SheetEmbedded,
    UserSelect,
    VirtualList
  }
};
</script>
<style lang="scss" scoped>

@import '@/_vars.scss';

.feed-image {
  width: 10px;
  height: 10px;
}

#max-h {
  overflow-y: scroll;
  overflow-x: hidden;
}

.tbl {
  display: flex;
  flex-direction: column;

  .tblrow {
  display: flex;
  min-height: 20px;
  flex: 0 0 28px;
  &:hover {
      background-color: $highlight-table;
  }
   .cell-s {
    flex: 1;
    text-align: left;
    border: 0px;
   }
   .cell {
    flex: 3;
    text-align: left;
    border: 0px;
   }
}


}

.vue-affix {
  width: 600px;
}

.item-cell-inner {
  justify-content: left;
}

.max-width-200 {
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.scroller {
  overflow-y: scroll;
  height: 600px;
}



</style>