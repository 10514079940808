<template lang="pug">
  .home
    GeneralModal(ref="generalModal", @save="$forceUpdate(); ")
    ColumnModal(ref="columnModal")
    .mt-0(v-if="project")
      //.row
        .col-12
          
          .float-right
            b-form(inline, horizontal)

              //b-input(type="search", size="sm", v-model="quick_search", placeholder="Filter...").mr-2
              b-btn(size="sm", @click="$refs.columnModal.open(fields, project)") Edit Columns
    
    b-table(size="sm", label-size="sm", sticky-header, striped hover responsive small :items="tableProvider", :fields="fields", ref="gtable", style="min-height: calc(100vh - 300px)").w-100.mt-3
      template(#cell(name)="data")
        b-link(:to="data.item._id") {{data.item.name}}
    

              
              
            
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import CompanySelect from '@/components/CompanySelect.vue'
import UserSelect from '@/components/UserSelect.vue'
import Logo from '@/components/Logo.vue'
import draggable from "vuedraggable";
import GeneralModal from '@/components/GeneralModal.vue'
import ColumnModal from '@/components/ColumnModal.vue'
import { mapFields } from 'vuex-map-fields';


export default {
  
  name: 'Dashboard',

    data: function () {
    return {
        stages: {},
        loading: {},
        quick_search: null,
        fields: [
          { key: 'name', label: 'Name', stickyColumn: true },
          { key: 'stage', label: 'Stage' },
          { key: 'index_space.use_case_expert_assessment_aggregation', label: 'index' },
         
         
        ]
     }
  },

 computed: {
      
      ...mapFields([
          'project.project', 
          'instance_config',
          'sidebar_project.selected_item',
          'sidebar_visible'
      ]),

  },
  watch: {
    project (project) {
      this.init(project);
    }
  },

  created: function(){
    
      this.$eventHub.$on('iframe_saved', () => {this.$store.dispatch("loadProject", this.$route.params.id)});

  },


  beforeDestroy() {

       this.$eventHub.$off('iframe_saved')
  },



  mounted: function() {
    if(this.project) {
      this.init(this.project);
    }
    this.mounted = true;
    
  },

  methods: {

    tableProvider () {
      // Here we don't set isBusy prop, so busy state will be
      // handled by table itself
      // this.isBusy = true
      let promise =  Vue.http.post(`${Config.config.base_host}/topics_v1/search`, {project_id: [this.project._id], topic_type: "general", limit: 1000})

      return promise.then((data) => {
        const items = data.body.rows
        // Here we could override the busy state, setting isBusy to false
        // this.isBusy = false
        return(items)
      }).catch(error => {
        alert(error);
        // Here we could override the busy state, setting isBusy to false
        // this.isBusy = false
        // Returning an empty array, allows table to correctly handle
        // internal busy state in case of error
        return []
      })
    },


    init(project) {
      console.log(project);
    },

    closeAdd() {

        this.$refs.generalModal.open();

    },

    selectOrg(org) {
      this.sidebar_visible = true;
      console.log(org);
      this.selected_item = org;
    },

    by_stage(stage){
      
      
      Vue.http.post(`${Config.config.base_host}/topics_v1/search`, {project_id: [this.project._id], topic_type: "general", limit: 1000}).then(res => {
       
        this.stages[stage].items.push(...res.body.rows);
        this.loading[stage] = false;
      
      });

      
    },
        
   
    

    changed(title, event) {
      
    
      if(event.removed) {

        //let po = event.removed.element;
      
        //let index = this.init_stages[this.index_stage[title]].orgs.indexOf(po);
        this.number[title] =  this.number[title] -1 ;
        //this.init_stages[this.index_stage[title]].orgs = this.init_stages[this.index_stage[title]].orgs.slice(index, 1);
        this.$forceUpdate();
      }

      if(event.added) {

        
        let po = event.added.element;
        console.log(po.stage, title);
        po.stage = title;
        po.board_position = event.added.newIndex;
        if(po.relations.account_owner_id && typeof po.relations.account_owner_id == "object") po.account_owner_id = po.relations.account_owner_id._id;
        this.number[title] =  this.number[title] + 1  ;
        this.save_project_organization(po, true);
      }
   


      if(event.moved) {

        let po = event.moved.element;
        console.log(po.stage, title);
        po.stage = title;
        po.board_position = event.moved.newIndex;
        if(po.relations.account_owner_id && typeof po.relations.account_owner_id == "object") po.account_owner_id = po.relations.account_owner_id._id;
        this.save_project_organization(po, true);
      }

       if(event.po) {
        let po = event.po;
          console.log("ddd");
        po.account_owner_id = event.user_id;
        this.save_project_organization(po);
      }
     
    }

  },

  components: {
    draggable,
    CompanySelect,
    UserSelect,
    GeneralModal,
    ColumnModal,
    Logo
  }
}

</script>
<style lang="scss" scoped>
@import '@/_vars.scss';


.column-width {
  min-width: 300px;
  width: 300px;

}

.max-width-200 {
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;

}

.max-height-column {
    margin-bottom: 10px;
    max-height: calc(100vh - 350px);
    min-height: calc(100vh - 380px);

    overflow-x: hidden;
    width: 100%;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;

  background: #F7FAFC;
  border: 1px solid #4299e1;
}

.cursor-move{
  cursor: pointer;
}


.btn-group > .btn, .btn-group-vertical > .btn {
  padding: 0 !important;
  margin: 0 !important;
}


.card-drag  {
  height: 100%;
  
  .remove-project-btn {
   
    
  }
}
.card-drag:hover  {
  .remove-project-btn {
    .btn-sm {
      
    }
    display: inline-block;
  }
}

</style>