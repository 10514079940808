
<template lang="pug">
     
    .main
    
      .container-fluid(v-if="selected_item")
       
        .form-row.mt-0(v-if="$store.getters.project.data")
            
            .col-12
                .item
                    div
                        b-button-group
                            b-btn(@click="prevItem()", :disabled="current_position == 0") Back
                            b-btn(@click="nextItem()", :disabled="current_position > $store.getters.project.data.length-1", ref="nextBtn") Next
                            .small.text-muted.ml-5(v-if="this.$store.getters.project.data") {{current_position+1}} / {{this.$store.getters.project.data.length}}
                        .float-right
                          
                          b-button-group
                            
                            b-input(placeholder="uuid", v-model="uuid")
                            b-btn(@click="findUuid()", :disabled="!$store.getters.project.data", ref="go") Go
                          
                          b-btn(@click="browserWindow()").ml-5 Website View
                        table.table.small.mt-1(v-if="selected_item")
                            tr
                              td()
                                div(class="feed-image" :style="'background: url(https://images.hytechnologies.co/unsafe/200x200/logo.clearbit.com/' + selected_item.domain + ') center 0% / cover no-repeat;'")
                              td() 
                                h4 {{selected_item['Company Name']}}
                            tr(v-for="(v,k) in selected_item")
                                td(width="100px") {{k}}
                                
                                td(v-if="k in field_definitions && field_definitions[k]['type'] == 'button_select_multi_next'")
                                    b-button-group()
                                        b-btn(v-for="val in field_definitions[k]['value']" @click="selected_item[k]=val; nextItem()" :pressed="v==val", variant="outline-primary") {{val}} 
                                
                                td(v-if="k in field_definitions && field_definitions[k]['type'] == 'button_select_multi'")
                                    b-button-group()
                                        b-btn(v-for="val in field_definitions[k]['value']" @click="selected_item[k]=val" :pressed="v==val", variant="outline-primary") {{val}} 

                                
                                td(v-if="!(k in field_definitions)")

                                    
                                    
                                    
                                    template(v-if="k == 'uuid'") <b>{{v}}</b>

                                    template(v-else-if="selected_item[k] && selected_item[k].length > 50")
                                      b-textarea(type="text",  rows="3", size="sm", v-model="selected_item[k]").form-control
                                    template(v-else)
                                      b-input(type="text",  size="sm", v-model="selected_item[k]").form-control                   

      .mt-5
        | &nbsp;

</template>   

<script>


export default {
  name: 'Main',
  watch: {
    
  },
  data () {
    return {
        current_position: 0,
        field_definitions: {},
        selected_item: {"uuid":null},
        current_group: 1,
        current_subgroup: 1,
        uuid: null,
        checkItem: {},
        w1: null,
        w2: null,
        w3: null,
       
    }
  },

  created() {
    this.$store.subscribe((mutation, state = null) => {
      console.log("mm",mutation);
     
      if(mutation.type == "LOAD_GRAPH") {
        
        
        this.getItem(0);
        
        if(this.$store.getters.project.settings) {
        this.$store.getters.project.settings.forEach((row) => {
          
            if(row.component == "browser") {
                console.log(row);
                this.field_definitions[row.field] = row;
                if(typeof this.field_definitions[row.field]["value"] === "string") {
                  this.field_definitions[row.field]["value"] = this.field_definitions[row.field]["value"].split(",");
                }
          }
            
        });
        }
      }
      console.log(state);
      


    });
  },




  mounted() {
      
      if(this.$store.getters.project.data) {
           this.getItem(0);
        
            if(this.$store.getters.project.settings) {
            this.$store.getters.project.settings.forEach((row) => {
              
                if(row.component == "browser") {
                  
                    this.field_definitions[row.field] = row;
                    if(typeof this.field_definitions[row.field]["value"] === "string") {
                      this.field_definitions[row.field]["value"] = this.field_definitions[row.field]["value"].split(",");
                    }
              }
                
            });
            }
          }
   
  },
    computed: {
       
    },
    methods: {
      

      findUuid() {
        
        this.save(this.selected_item);

        this.$store.getters.project.data.forEach((value, k) => { 
          if(this.uuid == value.uuid) {
            this.current_position = k;
            this.getItem(this.current_position);
            this.showWindow();
            this.uuid = null;
          }
        });

        if(this.uuid) alert(this.uuid + " not found");
      },


      save(item) {
        if(JSON.stringify(item) !== JSON.stringify(this.checkItem)) {
          this.$store.dispatch("save_data", item)
        }
      },

      browserWindow() {
        const width = window.screen.width / 3;
        console.log(window.location.protocol + "//" + window.location.host + "/projects/redirect?url="  + "http://" + this.selected_item.domain);
        this.w1 = window.open(window.location.protocol + "//" + window.location.host + "/projects/redirect?url="  + "http://" + this.selected_item.domain, "w1", "height="+ window.height+",width="+width);
        
      },

      showWindow() {
        //var width = window.screen.width - window.innerWidth;
        
        
        if(this.w1) {
          this.w1.location.href = window.location.protocol + "//" + window.location.host + "/projects/redirect?url=" + "http://" + this.$store.getters.project.data[this.current_position].domain;
        }
        
        /*if(!this.w2) {
          this.w2 = window.open("http://" + this.$store.getters.project.data[this.current_position+1].domain, "w2", "height=1,width=2");
        }
        if(!this.w3) {
          this.w3 = window.open("http://" + this.$store.getters.project.data[this.current_position+2].domain, "w3", "height=1,width=2");
        }*/
        
        
        //this.w2.location.href = "http://" + this.$store.getters.project.data[this.current_position+1].domain
        //this.w3.location.href = "http://" + this.$store.getters.project.data[this.current_position+2].domain
        
        
        setTimeout(() => { window.focus(); this.$refs["nextBtn"].focus(); }, 500);
        
    
      },

      nextItem() {
        
        this.save(this.selected_item);
        
        this.getItem(this.current_position+1);

        this.showWindow();

      },

      prevItem() {

        this.save(this.selected_item);
         
        this.getItem(this.current_position-1);

        
        this.showWindow();
      },

        getItem: function(position) {
          
          
          if(!this.$store.getters.project.data) {
            return null;
          }
          
          this.current_position = position;
          this.selected_item = this.$store.getters.project.data[this.current_position];
          this.checkItem = Object.assign({}, this.selected_item);
          console.log("item",this.selected_item);
          this.$forceUpdate();
          
          

        }
    
   
    
  },
  components: {

  }
}
</script>


<style scoped lang="scss">
  .feed-image {
    width: 50px;
    height: 50px;
    
  }
</style>
