<template lang="pug">
  
  .home

    .row
      .col-6
         b-form(inline, horizontal).text-justify
          b-form-group(label="Filter: Name/Email").mr-3
            b-input(size="sm", v-model="filter_name", placeholder="Enter name or email")
         
      .col-6
        .float-right
            b-input-group(inline,horizontal)
                UserSingleSelectSearch(style="min-width: 300px", v-model="user", placeholder="Type to search user")
                b-btn(@click="invite(user)", size="sm", variant="primary") Invite user

    b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="2", cols-xl="3").form-row.mt-4
      b-col(:key="user._id" v-for="user in project.relations.member_ids.filter(x => !filter_name || x.email.toLowerCase().trim().indexOf(filter_name.toLowerCase().trim())>-1 || x.full_name.toLowerCase().trim().indexOf(filter_name.toLowerCase().trim()) > -1).filter(x => filter_active && !x.deleted_at || filter_inactive && x.deleted_at)").mb-2
        b-card(:id="'card-'+user._id")
          .media
            User(:user="user").mr-2

            .media-body.d-flex.flex-rows.justify-content-between
              div
                div.text-truncate {{user.full_name}}
                .small.text-muted.mt-1.text-truncate {{user.email}}

              div
                b-btn(@click="remove_user(user)", size="sm", variant="link", v-if="user._id != project.owner_id", v-b-tooltip.hover title="Remove ")#edit-btn.mt-1
                  .fa.fa-times
                b-badge(v-if="user._id == project.owner_id", variant="primary") Project owner    
              
            

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import APIKeyModal from '@/components/APIKeyModal.vue'

import UserSingleSelectSearch from '@/components/UserSingleSelectSearch.vue'
import UserModal from '@/components/UserModal.vue'
import User from '@/components/User.vue'

import { mapFields } from 'vuex-map-fields';
import auth from '@/router/auth.js'

export default {
  
  name: 'home',
  
  data: function () {
    return {
        reset_done: false,
        filter_name: "",
        filter_active: true,
        filter_inactive: true,
        search: "",
        roles: [],
        users: [],
        auth: auth,
        user: null
    }
  },

  mounted: function() {
    
    if(!this.project) {
       this.$store.dispatch("loadProject", this.$route.params.id);
    }
    
   
    this.$store.state.breadcrumbs = [
            {
              text: 'Projects',
              href: '/projects',
            
            }, 
            {
              text: this.project.name,
              href: '/projects/'+this.project._id,
            
            }, 
            {
              text: 'Members',
              hhref: '/projects/'+this.project._id +'/members',
            
            }]

  },

  computed: {

       
        ...mapFields([
            'project.project'
        ]),
  },

  methods: {
    
 
    remove_user(user) {

        this.$store.dispatch("start_loading");
        this.project.member_ids = this.project.member_ids.filter(x => x != user._id);
        Vue.http.post(`${Config.config.base_host}/projects`, this.project).then(() => {
          this.$store.dispatch("loadProject", this.$route.params.id);
          this.$store.dispatch("stop_loading");
          this.$bvToast.toast("Member has been removed from the project", {
            title: 'User added',
            variant: 'primary',
            autoHideDelay: 10000,
          });
        });
    
    },

    invite(user) {
        this.$store.dispatch("start_loading");
        this.project.member_ids.push(user._id);
        Vue.http.post(`${Config.config.base_host}/projects`, this.project).then(() => {
          this.$store.dispatch("loadProject", this.$route.params.id);
          this.$store.dispatch("stop_loading");
          this.user = null;

          this.$bvToast.toast("New member has been added to the project", {
            title: 'User added',
            variant: 'primary',
            autoHideDelay: 10000,
          });

        });
    },

  },
  components: {
    User,
    UserModal,
    UserSingleSelectSearch,
    APIKeyModal

  }
}

</script>
<style lang="scss" scoped>


</style>