<template lang="pug">


  
.home(v-if="project")

    UseCaseModal(ref="topicModal")
    .row
        .col-6
          div(style="max-width: 800px").text-muted 
           
        .col-6
            .float-right
             
                b-btn(variant="outline-primary", size="sm", @click="showModal({project_id: project._id}, null, project.groups, project)").mr-2#add-topic-btn Create new use case
                b-btn(variant="outline-primary", size="sm", to="/topics/usecases")#add-exist  Add existing use case

    .row
      .col-12 

          div.mt-2(v-if="topics != null && !topics.length")
            | You have not created a use case yet. 

    b-btn(@click="do_add_mode()", variant="outline-secondary", size="sm", v-if="!add_mode") Add cluster
    
    template(v-if="add_mode")
      b-form(@submit.prevent="save_group(group, edit_group)")
        b-input-group(style="max-width: 300px")
          b-input(v-model="edit_group", :required="true")
          
          b-btn( variant="outline-primary", type="submit", size="sm", :disabled="!edit_group") Save


    div(v-for="(group, i) in project.groups", v-if="group == 'Not assigned'")
       
          draggable(handle=".handle-top" ghost-class="ghost-card"  group="tops", :animation="100")
            
            .handle-top.h-mouseover-parent
              .form-row.mt-5
                .col-12 
                  template(v-if="!edit_modes_headlines[group]")
                    h5.mb-0 {{group}}
                
                  template(v-if="edit_modes_headlines[group]")
                    b-form(@submit.prevent="save_group(group, edit_group)")
                      b-input-group(style="max-width: 300px")
                        b-input(v-model="edit_group", required) 
                        b-btn(variant="outline-secondary", size="sm", type="submit", :disabled="!edit_group") Save
                        
              .mt-4
              
                draggable( :animation="100", :component-data="getComponentData()", @change="rebuild(group, $event)" :list="topics_by_group[group]", class="form-row",  tag="div" ghost-class="ghost-card" group="subs")
                    .col-6.mb-2(v-for="pt in topics_by_group[group]", v-if="pt.relations['topic_id'].topic_type == 'use_case'", @click="$store.state.sidebar_project.selected_item = pt.relations['topic_id']")
                      //a(:href="'/topics/usecases/' +topic._id")
                          b-card(no-body, style="cursor:pointer")
                      div(no-body, style="background-color: #303030", :data-name="pt.relations['topic_id'].name")  
                        .row.p-2
                          .col-10
                            b-input-group()
                              b-check(@change="toggle_selected(pt.relations['topic_id'])", :checked="$store.state.sidebar_project.selected_list.map(x=>x._id).indexOf(pt.relations['topic_id']._id) > -1")
                              b.text-nowrap.uc-name {{pt.relations['topic_id'].name}}
                          .col-2
                            .float-right
                              b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
                                template(v-slot:button-content)
                                  i.fas.fa-ellipsis-h.gray-info

                                b-dropdown-item(size="sm" @click="showModal(pt, pt.relations['topic_id'], use_case_cluster, project)") Edit
                                b-dropdown-item(size="sm" @click="removeFromProject(pt)") Delete from project



    div(v-for="(group, i) in project.groups", v-if="group != '_deleted_' && group != 'Not assigned'")
       
          draggable(handle=".handle-top" ghost-class="ghost-card"  group="tops")
            
            .handle-top.h-mouseover-parent
              .form-row.mt-5
                .col-12 
                  template(v-if="!edit_modes_headlines[group]")
                    h5.mb-0 {{group}}
                      b-button-group(v-if="group != 'Not assigned'").ml-3
                        b-btn(variant="link", size="sm", @click="do_edit_group(group)")
                          i.fas.fa-pen.text-muted
                        b-btn(variant="link", size="sm", @click="delete_group(group)")
                          i.far.fa-trash-alt.text-muted
                      
                      .float-right.h-mouseover-show
                        b-btn(@click="showModal({project_id: project._id, group: group}, null, project.groups, project)", size="sm", variant="outline-secondary") Create use case
                  
                  template(v-if="edit_modes_headlines[group]")
                    b-form(@submit.prevent="save_group(group, edit_group)")
                      b-input-group(style="max-width: 300px")
                        b-input(v-model="edit_group", required) 
                        b-btn(variant="outline-secondary", size="sm", type="submit", :disabled="!edit_group") Save
                        
              .mt-4
              
                draggable(:component-data="getComponentData()", @change="rebuild(group, $event)" :list="topics_by_group[group]", class="form-row",  tag="div" ghost-class="ghost-card" group="subs")
                    .col-6.mb-2( v-for="pt in topics_by_group[group]", v-if="pt.relations['topic_id'].topic_type == 'use_case'", @click="$store.state.sidebar_project.selected_item = pt.relations['topic_id']")
                      //a(:href="'/topics/usecases/' +topic._id")
                          b-card(no-body, style="cursor:pointer")
                      div(no-body, style="background-color: #303030", :data-name="pt.relations['topic_id'].name")  
                        .row.p-2
                          .col-10
                            b-input-group()
                              b-check(:data-name="pt.relations['topic_id'].name",@change="toggle_selected(pt.relations['topic_id'])", :checked="$store.state.sidebar_project.selected_list.map(x=>x._id).indexOf(pt.relations['topic_id']._id) > -1")
                              b.text-nowrap.uc-name {{pt.relations['topic_id'].name}}
                          .col-2
                            .float-right
                              b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
                                template(v-slot:button-content)
                                  i.fas.fa-ellipsis-h.gray-info

                                b-dropdown-item(size="sm" @click="showModal(pt, pt.relations['topic_id'], use_case_cluster, project)") Edit
                                b-dropdown-item(size="sm" @click="removeFromProject(pt)") Delete from project
          
</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import UseCaseCard from '@/components/UseCaseCard.vue'
import UseCaseModal from '@/components/UseCaseModal.vue'
import draggable from "vuedraggable";
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'TopicOverview',
  
  data: function () {
      return {
        topics: null,
        pts: null,
        use_case_cluster: [],
        topics_by_group: {},
        edit_group: null,
        edit_modes_headlines: {},
        add_mode: false
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();
    this.$store.state.sidebar_project.selected_item = null;
    
    if(this.project) {
      this.transform_project(this.project);
    }
  },


  computed: {
    
      ...mapFields([
          'project.project', 
      ]),
  },
  watch: {
    project: function(newproject) { 
      if(newproject) {

        this.transform_project(newproject);
        this.$store.state.sidebar.selectedProjectStage = newproject._id;
      }
    }
  },

  methods: {

    save_group(group, edit_group) {
      this.$store.dispatch("start_loading");
        Vue.http.put(`${Config.config.base_host}/projects/${this.project._id}/change_group`, {"from": group, "to": edit_group}).then(() => {
          this.$store.dispatch("loadProject", this.$route.params.id); 
        });
      this.edit_group = null;
      this.add_mode = false;
      Object.keys(this.edit_modes_headlines).forEach((x) => {  
        this.edit_modes_headlines[x] = false;
      }); 

    },


    do_add_mode() {
      this.add_mode = true;

      Object.keys(this.edit_modes_headlines).forEach((x) => {  
        this.edit_modes_headlines[x] = false;
      }); 

    },

    do_edit_group(group) {
      
      Object.keys(this.edit_modes_headlines).forEach((x) => {  
        this.edit_modes_headlines[x] = false;
      }); 

      this.edit_modes_headlines[group] = true;
      
      this.add_mode = false;
      this.edit_group = group;
    },

     toggle_selected(item) {
      this.$store.state.sidebar_project.selected_item = item;
      if(this.$store.state.sidebar_project.selected_list.map((x)=>{return x._id}).indexOf(item._id)>-1) {
       
         var index = this.$store.state.sidebar_project.selected_list.map(x => {
          return x._id;
        }).indexOf(item._id);
        
        this.$store.state.sidebar_project.selected_list.splice(index, 1); 

      } else {
        this.$store.state.sidebar_project.selected_list.push(item);
      }
    },

    transform_project(newproject) {
       
       if(newproject) {

        if(newproject.groups.indexOf("Not assigned") == -1) {
          newproject.groups.push("Not assigned");
        }

        this.edit_modes_headlines["Not assigned"] = false;
        this.topics_by_group["Not assigned"] = [];
    
        newproject.groups.forEach((it) => {
          this.edit_modes_headlines[it] = false;
          this.topics_by_group[it] = [];
        });

      
        this.getData();
          
      }
    },

     getComponentData() {
      return {
       
        attrs:{
          class: "col-6"
        }
       
      };
    },

    rebuild(title, event) {
      console.log(title, event);

      /*if(event.removed) {
      
        let index = this.topics_by_group[title].map(x=>x._id).indexOf(event.removed.element._id);
        console.log(this.topics_by_group[title].map(x=>x._id), index, event.removed.element._id);
        this.topics_by_group[title].splice(index, 1);
      
      }*/

      if(event.added) {
        let pt = Object.assign({}, event.added.element);
       
        pt.group = title;
        pt.dropped = true;
      
        pt.board_position = event.added.newIndex;
         
        Vue.http.put(`${Config.config.base_host}/project_topics/${pt._id}`, pt).then((res) => {
            pt = res.body;
           console.log(res);
          
            this.getData();
        });
      }
     
    },

    delete_group(group) {
        this.$bvModal.msgBoxConfirm("Are you sure? All assigend use cases will be moved to 'Not assigned'", {buttonSize: 'sm'}).then((value) => {
            console.log(value);

            if(value) {
              this.$store.dispatch("start_loading");
              this.save_group(group, null);
            }
            
        });

    },

    removeFromProject(pt) { 
      this.$store.dispatch("start_loading");
      pt.deleted_at = true;
      Vue.http.put(`${Config.config.base_host}/project_topics/${pt._id}`, pt).then((res) => {
        console.log(res);
        this.getData();
      });
    },
        

    getData() { 
      if(!this.project) return;
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/projects/${this.$route.params.id}`).then(res => {
        
        this.pts = res.body.topics;
        this.pts.sort((a, b) => (a.relations['topic_id'].name > b.relations['topic_id'].name) ? 1 : -1)
        this.topics_by_group = {};
        
        this.project.groups.forEach((it) => {
          this.topics_by_group[it] = [];
        });


        this.use_case_cluster = [];
        this.pts.forEach(pt => {

          if(pt) {
            
            if(!("Not assigned" in this.topics_by_group)) {
                this.topics_by_group["Not assigned"] = [];
                this.edit_modes_headlines["Not assigned"] = false;
            }
          
            if(pt['group']){
              
              if(!(pt['group'] in this.topics_by_group)) {
                if(pt.group) this.use_case_cluster.push(pt.group);
                this.edit_modes_headlines[pt.group] = false;
                this.topics_by_group[pt['group']] = [];
              }

              this.topics_by_group[pt['group']].push(pt)
            
            } else {             
              
              if(!("Not assigned" in this.topics_by_group)) {
                this.topics_by_group["Not assigned"] = [];
                this.edit_modes_headlines["Not assigned"] = false;
              }
              this.topics_by_group["Not assigned"].push(pt);
            
            }
          }
        });
           
        this.$store.dispatch("stop_loading");
      });
      this.edit_modes_headlines = Object.assign({},this.edit_modes_headlines)
    },

    addUseCases(project) {
        this.$store.state.sidebar_use_cases.project = project;
    },

    showModal(pt, topic, group, project) {
        this.$refs.topicModal.open(pt, topic, group, project);
    },
  },

  components: {
      UseCaseCard,
      UseCaseModal,
      draggable
      
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
    
    .uc-name {
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 300px;
      display: inline-block;
    }
</style>