<template lang="pug">
  .user-modal
    b-modal(id="user-api-key-modal", :title="title", size="md", ok-variant="outline-primary", cancel-variant="link", ref="user-api-key-modal",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        .form-row()
          .col-12
            | Provisioned API keys for {{user.full_name}} 
            table.table.mt-2.small
              tr(v-for="key_item in api_keys")
                td {{key_item.created_at | utcFrom}}
                td {{key_item.api_key.substring(0,20)}} ...
                td
                  .float-right
                    b-btn(@click="remove_key(key_item.api_key)", size="sm", variant="link") Remove
        
        .form-row().mt-2
          .col-12


            b-btn(@click="new_api_key()", size="sm", variant="outline-primary").form-control.mt-2 Create new API key/ secret pair
            b-card(v-if="new_key").mt-4
              .text-muted.small Your new key/secret pair: Please copy and paste the information and keep the credentials secret.
              b-form-group(label="API Key").mt-4
                b-btn-group(style="width: 100%")
                  b-input(v-model="new_key.api_key", @click="copy_key()" size="sm").small#new_api_key
                  b-btn(@click="copy_key()", size="sm") Copy
              b-form-group(label="Secret")
                b-btn-group(style="width: 100%")
                  b-input(v-model="new_key.unhashed_secret", @click="copy_secret()" size="sm").small#new_api_secret
                  b-btn(@click="copy_secret()", size="sm") Copy


        
      template(slot="modal-footer")
            b-btn(variant="outline-secondary" @click="$refs['user-api-key-modal'].hide(false)") Close
            //b-btn(variant="primary"  @click="close()")#close-modal-save Close

</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength,email } from 'vuelidate/lib/validators'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        full_name: {
            required,
            minLength: minLength(1)
        },
        email: {
            required,
            minLength: minLength(1),
            email
        }
     
    }
  },

  data: function () {
    return {
        
        uid: null,
        title: "API Keys",
        form: {"full_name": "", "email": "", role_id: null, "deleted_at": null, "active": true, "send_confirmation": false},
        user: {},
        options: [],
        api_keys: [],
     
        new_key: null,
        roles: [],
        submitStatus: null,
        ok_btn_text: "Close"
        
    }
  },

  mounted: function() {
    this.uid = this._uid;
    
  },

  methods: {
    get_api_keys() {
      Vue.http.get(`${Config.config.base_host}/v1/users/${this.user._id}/api_keys`).then((res) => {
        this.api_keys = res.body;
      });
        
    },

    copy_secret() {
      document.getElementById('new_api_secret').select();
      let copied = false;
      try
      {
          // Copy the text
          copied = document.execCommand('copy');
      } 
      catch (ex)
      {
          copied = false;  
      }
      return copied;
    },

    copy_key() {
      console.log(document.getElementById('new_api_key'))
      document.getElementById('new_api_key').select();
      let copied = false;
       try
        {
            // Copy the text
            copied = document.execCommand('copy');
        } 
        catch (ex)
        {
            copied = false;  
        }
        return copied;

    },

    open(user) {
      
      this.title = "API Keys";
      this.new_key = null;
      if(user) {
        this.form = JSON.parse(JSON.stringify(user));
        this.user = user;
        this.ok_btn_text = "Save";
        this.title = "API Keys" + user.email;
         this.$refs['user-api-key-modal'].show();
        this.get_api_keys();

      } 

     

    },
    
    new_api_key() {
      console.log(this.user._id);
       Vue.http.post(`${Config.config.base_host}/v1/users/${this.user._id}/api_keys`).then((res) => {
          this.new_key = res.body;
          this.get_api_keys();
       });
    },

    remove_key(api_key_id) {
      console.log(api_key_id);
       Vue.http.delete(`${Config.config.base_host}/v1/users/${this.user._id}/api_keys/${api_key_id}`).then(() => {
          this.get_api_keys();
       });
          
    },

    
  },

  components: {

  }
}

</script>
<style lang="scss" scoped>

</style>