<template lang="pug">
  .home
    GeneralModal(ref="generalModal", @saved="init(project)")
    .mt-0(v-if="project")
      .row
        .col-12
          
          .float-right
            b-form(inline)
              b-input(type="search", size="sm", v-model="quick_search", placeholder="Filter...").mr-2
              
     
      .mt-1(style="overflow-x: auto; width: 100%; white-space: nowrap;", v-if="Object.keys(stages).length > 0")
         
          b-card(v-for="stage in stages" no-body :header="stage.title" class="bg-gray-100 rounded-lg column-width rounded mr-4").d-inline-block.align-top
           
            template(v-slot:header)
              | {{stage.title}} 
            
            b-btn(variant="link", @click="closeAdd(stage);", size="sm").text-muted.form-control + Add
            
            .max-height-column.column-width.more()
               
                draggable(:list="stage.items" :animation="200" style="min-height: 500px; white-space: normal;" ghost-class="ghost-card" :id="'dragdrop-'+stage.title" group="tasks", @change="changed(stage.title, $event)")
                      
                      b-card(v-for="task in stage.items", :key="task._id", no-body, :id="'card-' + task.name", v-if="!task.deleted_at && (!quick_search || task.name.toLowerCase().indexOf(quick_search.toLowerCase()) > -1)").shadow-sm.cursor-move.m-2.p-2.card-drag
                       
                        .form-row
                          
                          .col-10
                            router-link(:to="'/projects/'+$route.params.id+'/general/' + task._id")
                              div(@click="selectOrg(task._id)")
                                h6(style="vertical-align: middle;" v-bind:class="{small: project.board_show_compact}").max-width-200 {{task.name}}
                          
                          .col-2
                            .float-right.small()
                              b-dropdown(id="dropdown-offset" offset="0" text="Offset Dropdown" size="sm" variant="link" no-caret).p-0.remove-project-btn
                                template(v-slot:button-content)
                                  i.fas.fa-ellipsis-h.gray-info.p-0
                                b-dropdown-item(size="sm" @click="$refs.generalModal.open(task, stage.title)") Edit
                                b-dropdown-item(size="sm" @click="delete_connection(task)") Remove from project
                                
                          
                          //template(v-if="project.board_show_assign_owner")
                            hr.mt-1.mb-0
                            
                            UserSelect(v-if="task.index_space", v-model="task.account_owner_id", @input="(val)=>changed(stage.title, {user_id: val ? val._id : null, po: task})").w-100
                
                //.text-center(v-if="loading[stage.title]").text-muted.small.mt-2 Loading ...
              
              
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import CompanySelect from '@/components/CompanySelect.vue'
import UserSelect from '@/components/UserSelect.vue'
import Logo from '@/components/Logo.vue'
import draggable from "vuedraggable";
import GeneralModal from '@/components/GeneralModal.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Dashboard',

    data: function () {
    return {
        stages: {},
        loading: {},
        quick_search: null
     }
  },

 computed: {
      
      ...mapFields([
          'project.project', 
          'instance_config',
          'sidebar_project.selected_item',
          'sidebar_visible'
      ]),

  },
  watch: {
    project (project) {
      if(!this.project.general_use_board) {
        this.$router.push({"path": "/projects/" + this.project._id + "/board"});
      }
      this.init(project);
    }
  },

  created: function(){
    
      this.$eventHub.$on('iframe_saved', () => {this.$store.dispatch("loadProject", this.$route.params.id)});

  },


  beforeDestroy() {

       this.$eventHub.$off('iframe_saved')
  },



  mounted: function() {
    if(this.project) {
      if(!this.project.general_use_board) {
        this.$router.push({"path": "/projects/" + this.project._id + "/board"});
      }
      this.init(this.project);
    }
    this.mounted = true;
    
  },

  methods: {

    init(project) {

      project.general_stages.forEach((stage) => {
        this.loading[stage] = true;

        this.stages[stage] = {"title": stage, "items": [], "add": false};

        this.$forceUpdate();

        this.by_stage(stage);
      });
    },

    closeAdd(stage) {

        this.$refs.generalModal.open(null, stage.title);

    },

    selectOrg(org) {
      this.sidebar_visible = true;
      console.log(org);
      this.selected_item = org;
    },

    by_stage(stage){
      
      this.stages[stage].items = [];
            
      Vue.http.post(`${Config.config.base_host}/topics_v1/search`, {project_id: [this.project._id], stage: [stage], topic_type: "general", limit: 100}).then(res => {

        this.stages[stage].items.push(...res.body.rows);

        this.stages[stage].items.forEach((it) => { 
          it.account_owner_id = it.index_space.projects.find(x => x._id == this.project._id).account_owner_id;
        });

        
      
        this.loading[stage] = false;
        this.$forceUpdate();
      });

      
    },
        
    save_pt(pt) {
      Vue.http.put(`${Config.config.base_host}/project_topics/${pt._id}`, pt).then(() => {
        //this.by_stage(pt.stage);
        this.$forceUpdate();
      });
    },
    

    changed(title, event) {
      
    
      if(event.removed) {

        //let po = event.removed.element;
      
        //let index = this.init_stages[this.index_stage[title]].orgs.indexOf(po);
        
        //this.init_stages[this.index_stage[title]].orgs = this.init_stages[this.index_stage[title]].orgs.slice(index, 1);
        this.$forceUpdate();
      }

      if(event.added) {

       
        let po = {"_id": this.project._id + '::' + event.added.element._id}
       
        po.stage = title;
        po.board_position = event.added.newIndex;
        if(po.account_owner_id && typeof po.account_owner_id == "object") po.account_owner_id = po.account_owner_id._id;
       
        this.save_pt(po, true);

      }
   


      if(event.moved) {

        let po = {"_id": this.project._id + '::' + event.moved.element._id}
      
        po.stage = title;
        po.board_position = event.moved.newIndex;
        if(po.account_owner_id && typeof po.account_owner_id == "object") po.account_owner_id = po.account_owner_id._id;
        this.save_pt(po, true);

      }

      if(event.po) {
        let po = {"_id": this.project._id + '::' + event.po._id}; 
        po.stage = title;
        po.account_owner_id = event.user_id;
        this.save_pt(po);
      }
     
    }

  },

  components: {
    draggable,
    CompanySelect,
    UserSelect,
    GeneralModal,
    Logo
  }
}

</script>
<style lang="scss" scoped>
@import '@/_vars.scss';


.column-width {
  min-width: 300px;
  width: 300px;

}

.max-width-200 {
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;

}

.max-height-column {
    margin-bottom: 10px;
    max-height: calc(100vh - 350px);
    min-height: calc(100vh - 380px);

    overflow-x: hidden;
    width: 100%;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;

  background: #F7FAFC;
  border: 1px solid #4299e1;
}

.cursor-move{
  cursor: pointer;
}


.btn-group > .btn, .btn-group-vertical > .btn {
  padding: 0 !important;
  margin: 0 !important;
}


.card-drag  {
  height: 100%;
  
  .remove-project-btn {
   
    
  }
}
.card-drag:hover  {
  .remove-project-btn {
    .btn-sm {
      
    }
    display: inline-block;
  }
}

</style>