<template lang="pug">
.tblrow.text-left(@click="hi(source)", :class="{active: source.selected}")

    .cell() 
       .media
        div(class="feed-image" :style="'background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + source.web_entity + ') center 0% / cover no-repeat;'").mr-2
        .media-body 
         
          h6.m-0.p-0 {{ source.name }}
          | {{ source.city }}<br>
        
          a(:href="'http://'+source.web_entity", target="_blank") {{ source.web_entity }}
  
    .cell()
      div
      
        b-select(:options="['Not assigned'].concat(project.project.stages)", v-model="source.relations.stage", style="max-width: 200px", size="sm", @change="save(source._id, source.relations.stage)")
        
        //UserSelect(v-model="source.index_space.projects.find(x => x._id == project.project._id).account_owner_id")

    //.cell(style="border-left: 1px solid #eee; border-right: 1px solid #eee; padding: 5px")
      table.w-100
        tr(v-for="df in instance_config.data_fields").p-0
          td(width="50%").p-0.pr-3.align-top
            label() {{df.label}}
          td(width="50%").align-top
            .text-clamp {{$auth.get_formatted(source.custom_data[df.name], df, max_lengt=100) }}
    
    //.cell(style="border-left: 1px solid #eee; border-right: 1px solid #eee; padding: 5px")
      table.w-100
        tr(v-for="df in project.project.data_fields_project_organization").p-0
          td(width="50%").p-0.pr-3.align-top
            label() {{df.label}}
          td(width="50%", v-if="source.index_space.projects && source.index_space.projects.find(x => x._id == project.project._id)").align-top  
            .text-clamp {{ $auth.get_formatted(source.index_space.projects.find(x => x._id == project.project._id).custom_data[df.name], df, max_length=100) }}
    


</template>



<script>
  import { mapFields } from "vuex-map-fields";
  import UserSelect from '@/components/UserSelect.vue';
  import Config from "@/config";
  
  export default {
    components: {
      UserSelect
    },
    name: 'item-component',
    props: {
      index: { // index of current item
        type: Number
      },
      source: { // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default () {
          return {}
        }
      }
    },
      computed: {
    
    ...mapFields([
      'instance_config',
      'project'
    ]),
  },


    methods: {

        save(id, stage) {
           
           this.$http
            .put(
              `${Config.config.base_host}/project_organizations/${this.project.project._id}::${id}`,
                {"stage": stage}
            )
            .then(() => {
            
             
              this.source.relations.stage_sort = ['Not assigned'].concat(this.project.project.stages).indexOf(stage);
              this.$store.dispatch("stop_loading");
              
              
              
            })
            .catch(() => {
              console.log("error");
            });

        },
      
        dispatch(componentName, eventName, ...rest) {
            let parent = this.$parent || this.$root
            let name = parent.$options.name

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent
                if (parent) {
                name = parent.$options.name
                }
            }
            
            if (parent) {
                parent.$emit.apply(parent, [eventName].concat(rest))
                 
            }
        },


        hi(source) {
            this.dispatch("list-view", "list_change_id", source["_id"], source);
        }
    }
  }
</script>

<style lang="scss" scoped>

@import '@/_vars.scss';

.tblrow {
  border-top: 1px solid $secondary;
  padding: 5px 5px 5px 5px;
  &.active{
      border: 2px solid $primary !important;
      //font-weight: bold;
      background-color: $gray-200;
  }
  display: flex;
  min-height: 20px;
  flex: 0 0 28px;
 
  .cell {
    flex: 1;

    text-align: left;
    border: 0px;
   }
}

.text-clamp {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

</style>