<script>

import { mixins, Bar } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    name: 'barChart',
    extends: Bar,
    mixins: [reactiveProp],
    props: ['options', 'chartData'],
    
    mounted () {

        this.renderChart(this.chartData, this.options)    
    },

    watch: {
        chartData () {
            this.renderChart(this.chartData, this.options);
        }
    },


    data () {
        return {
           
        }
    },
    
    methods: {
    
    }
}
</script>

<style scoped lang="scss">
@import '~@/_vars.scss';



</style>