<template lang="pug">
  
.home(id="side-list", style="margin-top: 0px")

  WListModal(ref="sidewlistmodal")
  b-overlay(:show="$store.state.loading").mt-5

    template(v-if="sidebar_help")
      .help
        p <b>Projects</b> are used to collaborate with colleagues and clients. Bookmark your most used projects to have quick access. Filter existing projects or create a new public or private project in the overview. 
        p <b>Inside a project</b>:
        ul
          li If you are the owner of the project you can add or remove members, change the privacy settings or change the project description by clicking the drop-down next to the members list on the top right.
          li Create use cases inside your project or add existing ones and organize them in clusters.
          li Match companies to use cases.
          li Add more insights to improve the startup evaluation process: Create surveys and send them to startups. 
          li Track the startups’ response and create valuable use case/startup-matches.
          li Stay up to date by checking your project’s news section.   
        
      .mt-4
   
    template(v-if="selected_item && selected_item.doc_type=='Topic'  && selected_item.relations.project")
  
      .p-3
       
        b-card( :title="selected_item.name")
            div(v-if="selected_item.parent_ids")
            
              TaxonomyDisplay(topic_type="industry", :topic_ids="selected_item.parent_ids")
            
            label.mt-4(v-if="selected_item.description") Description
            .mt-1
            .text-muted.small {{selected_item.description}}

            div(v-if="selected_item.relations.project && selected_item.relations.project.topic_stages")
              b-form-group(label="Radar Stage")
                b-select(:options="selected_item.relations.project.topic_stages", v-model="selected_item.relations.pt.stage", @change="save_project_topic(selected_item)")
              b-form-group(label="Radar Group")
                 b-select(:options="selected_item.relations.project.groups", v-model="selected_item.relations.pt.group", @change="save_project_topic(selected_item)")
      
        div.mb-1.mt-1(v-if="!selected_item.relations.project.topic_stages.length || !selected_item.relations.project.groups.length").small Please configure radar evaluations in the project settings first.
         
      .mt-4

    template(v-if="selected_item && selected_item.project && selected_item.project.doc_type=='Project'")
   
      b-card(style="", :title="selected_item.project.name")

        b {{selected_item.project.short_description}}
        div(style="overflow-y: auto; height: 100%")
       
          b.text-clamp(style="max-height: 200px") {{selected_item.project.custom_data['short_description']}}
          
          div(v-if="selected_item && selected_item.custom_data")
            div(v-if="selected_item.project.custom_data['industry'] && selected_item.project.custom_data['industry'].length > 0")
              label.mt-3 Industries
              TaxonomyDisplay(topic_type="industry", :topic_ids="selected_item.custom_data['industry']")
          
          div(v-if="selected_item && selected_item.project.custom_data")
            div(v-if="selected_item.project.custom_data['enterprise_function'] && selected_item.project.custom_data['enterprise_function'].length > 0")
              label.mt-3 Enterprise Functions
              TaxonomyDisplay(topic_type="enterprise_function", :topic_ids="selected_item.project.custom_data['enterprise_function']")

          div(v-if="selected_item && selected_item.project.custom_data")
            div(v-if="selected_item.project.custom_data['vertical'] && selected_item.project.custom_data['vertical'].length > 0")
              label.mt-3 Verticals
              TaxonomyDisplay(topic_type="vertical", :topic_ids="selected_item.project.custom_data['vertical']")
          
          div(v-if="selected_item && selected_item.project.custom_data")
            div(v-if="selected_item.project.custom_data['sub_vertical'] && selected_item.project.custom_data['sub_vertical'].length > 0")
              label.mt-3 Sub-Verticals
              TaxonomyDisplay(topic_type="sub_vertical", :topic_ids="selected_item.project.custom_data['sub_vertical']")

        .mt-2
          span {{selected_item.project_organizations.length}} Companies / {{selected_item.topics.filter((x) => {return x.relations['topic_id'].topic_type == 'use_case'}).length}} Use cases

        //.small(v-for="topic in selected_item.topics" v-if="topic.relations['topic_id'].topic_type == 'use_case'") 
          a(:href="'/topics/usecases/'+topic.topic_id")  {{topic.relations['topic_id'].name}}
        
        b-btn(variant="outline-primary", size="sm", :to="'/projects/'+selected_item.project._id +'/dashboard'").mt-3.form-control Open Project

      .mt-4
    
    
    div(v-show="selected_item && selected_item.doc_type=='Organization'")
      
      iframe(src="about:blank" width="100%", height="100%", style="min-height: calc(100vh - 200px); border: 0", border="0", frameborder="0", ref="iframe1")

      //div()
        | {{selected_item}}

        .media
                                  
          router-link(:to="'/ecosystem/orgs/' + selected_item._id")
              div(class="feed-image" :style="'background: url(https://images.hytechnologies.co/unsafe/50x50/logo.clearbit.com/' + selected_item.web_entity + ') center 0% / cover no-repeat;'").mr-3
      
          .media-body 
              
            h5.p-0.m-0 
                router-link(:to="'/ecosystem/orgs/'+selected_item._id") {{selected_item.name}}

            div.links()
                a(:href="'https://' + selected_item.web_entity" target="_blank"  v-if="selected_item.web_entity").mr-1.small {{selected_item.web_entity}}
                a(:href="selected_item.homepage_url" target="_blank" v-else-if="selected_item.homepage_url").mr-1.small {{selected_item.homepage_url}}
        
        .mt-3
          span.small(v-if="selected_item.city") {{selected_item.city}}, {{selected_item.country_code}}
          span.small(v-if="!selected_item.city") {{selected_item.address}} 
          
        .mt-3

        b.mt-3 {{selected_item.short_description}}
                                    
        b-btn(variant="outline-primary", size="sm", :to="'/ecosystem/orgs/'+selected_item._id").mt-3.form-control View Profile
       
      .mt-4

    template(v-if="selected_list && selected_list.length > 0")

      div().mt-3
      .mt-3
          b.text-gray {{selected_list.length}} use cases selected 
          .float-right
          
            b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-droPtemplpdown
                template(v-slot:button-content)
                 
                  i.fas.fa-ellipsis-h.gray-info
                b-dropdown-item(@click="selected_list = []", href="#") Clear list
                b-dropdown-item(size="sm" @click="$refs.sidewlistmodal.open({'name': '', items: selected_list.map(x => x._id)}, 'topic')") Save list
                b-dropdown-item(size="sm" @click="export_items(selected_list.map(x => x._id))") Download list
                b-dropdown-divider()
                b-dropdown-item(size="sm" to="/dashboard") Manage Lists


      div(no-body, style="width: 100%")
        
        b-list-group(size="sm", style="max-height: 300px; overflow-y: auto")
          b-list-group-item( v-for="(item, index) in selected_list").d-flex.justify-content-between.align-items-center.small.m-0.pl-2.pr-2.pt-0.pb-0
            | {{item.name}} 
            b-btn(variant="link", @click="remove_from_list(item)") <i class="fas fa-times" style="opacity: 0.3"></i>
        .p-0
          .mt-1
           
            ProjectStageSelect(id="projectStageAdd" v-model="selectedProjectStage", :create_option="false", style="min-width: 200px").small
            
            b-btn(variant="outline-primary", @click="add_to_project(selectedProjectStage, selected_list)", size="sm", :disabled="!selectedProjectStage")#btn-add-to-project.form-control.mt-1 Add to Project
          
          //.mt-3
            b-btn(variant="outline-primary", @click="", size="sm").form-control.mt-1 Compare Use Cases
            b-btn(variant="outline-primary", @click="", size="sm").form-control.mt-1 Save to list
  
   
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import { mapFields } from 'vuex-map-fields';
import ProjectStageSelect from '@/components/ProjectStageSelect'
import TaxonomyDisplay from '@/components/TaxonomyDisplay'

import WListModal from '@/components/WListModal'
export default {
  
  name: 'SideListPorjects',
  
  data: function () {
      return {
        
      }
  },

  watch: {
    'selected_item': function(newVar, oldVar) {
      
      let old_sidebar = this.sidebar_visible;

      if(!oldVar && newVar) {
        this.sidebar_visible = true;
      }
      
      if(oldVar && newVar && oldVar._id != newVar._id) {
        this.sidebar_visible = true;
      }

      if(newVar == null && (!this.selected_list || this.selected_list.length == 0)) {
        this.sidebar_visible = false;
      }

      if(newVar && newVar.doc_type=='Organization') {
       
        this.$refs.iframe1.src = '/ecosystem/orgs/' + newVar._id + '?extension=1';
        this.$forceUpdate();
      }
      
      if(old_sidebar != this.sidebar_visible) window.dispatchEvent(new Event('resize'));
   
    },
    'selected_list': function() {
      

      if(this.selected_item == null && (!this.selected_list || this.selected_list.length == 0)) {
        this.sidebar_visible = false;
      }
      
      window.dispatchEvent(new Event('resize'));
     
    }

  },
  beforeMount: function() {
    
  },


  mounted: function() {
    this.host = Config.config.base_host;
    this.$store.state.sidebar_project.selected_item = null;
    window.dispatchEvent(new Event('resize'));
  },

  computed: {
      ...mapFields([
        'sidebar_project.selected_item',
        'sidebar_project.selected_list',
        'sidebar.selectedProjectStage',
        'sidebar_visible',
        'sidebar_help'
      ]),

  },

  methods: {

    save_project_topic(topic) {
       this.$store.dispatch("start_loading");
      let pt = {"_id": topic.relations.project._id + '::' + topic._id, "group":  topic.relations.pt.group, "custom_data": {},"stage": topic.relations.pt.stage};

       Vue.http.put(`${Config.config.base_host}/project_topics/${pt._id}`, pt).then(() => {
          
          this.$store.dispatch("stop_loading");

        });

    },

      add_to_project(selectedProjectStage, topic_list) {
        this.$store.dispatch("start_loading");
        let ids = topic_list.map((x) => { return x._id });
        Vue.http.post(`${Config.config.base_host}/projects/${selectedProjectStage}/project_topics/bulk`, {topic_ids: ids}).then(() => {
          
          this.$store.dispatch("stop_loading");
          
          location.href = "/projects/"+selectedProjectStage+'/usecases';
          this.$store.state.sidebar_project.selected_list = [];
          this.$bvToast.toast("The use cases have been added to the project", {
            title: 'Added to project',
            variant: 'primary',
            autoHideDelay: 1000,
          });

        });

      },

       export_items(item_ids) {
    
        this.$store.dispatch("start_loading");
        Vue.http
            .post(Config.config.base_host + "/watchlists/csv", {"items": item_ids})
            .then(resp => {
            
            var element = document.createElement("a");
                element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," + encodeURIComponent(resp.body)
                );
                element.setAttribute("download", Date.now().toString() + "_download_list.csv");
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

            
            this.$store.dispatch("stop_loading");
            });
      },

      remove_from_list(item) {
        var index = this.$store.state.sidebar_project.selected_list.map(x => {
          return x._id;
        }).indexOf(item._id);
        
        this.$store.state.sidebar_project.selected_list.splice(index, 1); 
      }
  },
  components: {
      ProjectStageSelect,
      WListModal,
      TaxonomyDisplay
  }
}

</script>
<style lang="scss" scoped>
.side-view {
  padding: 0px;
}

.help {
  font-size: 0.875em;
  li { 
    margin-top: 10px;
  }
}
.feed-image {
    height: 50px;
    width: 50px;
}

.text-clamp {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

</style>