<template lang="pug">
  .search-filter()
    b-form(autocomplete="off")

        //FilterGroup(title="Search in" :collpased="true").mt-1
          b-form-group()
            b-check(checked) Ecosystem
            b-check(checked) Scouting Database

        FilterGroup(title="Filter", :collpased="true").mt-3.mr-3
          //ProjectStageSelect(placeholder="Enter a name and press enter", size="sm", @input="getData()", v-model="project_id", :taggable="true", :multiple="true").small
          
          v-select(id="keyword", :multiple="true" :taggable="true" v-model="keywords", @input="getData()", placeholder="Enter keyword and press enter").small.mt-1
          
          .mt-3
           
            b-checkbox(v-model="by_me", @input="getData()") My surveys
            //b-checkbox(v-model="my_projects", @input="getData()") My projects
     
     
      

       
          

</template>

<script>
// @ is an alias to /src

import Config from '@/config'

import ProjectStageSelect from '@/components/ProjectStageSelect'
import FilterGroup from '@/components/FilterGroup'
import TaxonomySelect from '@/components/TaxonomySelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'ProjectFilter',

  data: function () {
    return {
     
      sources_filter: [{"text": "Ecosystem", "value": "ecosystem"}, {"text": "hy database", "value": "hy"}, {"text": "Crunchbase", "value": "cb"}],
      project_config: [{"name": "Industry", "topic_type": "industry"},{"name": "Enterprise Function", "topic_type": "enterprise_function"},{"name": "Vertical",  "topic_type": "vertical"}, {"name": "Sub-Vertical", "topic_type": "sub_vertical"}]
    }
  },

  mounted: function() {
      this.host = Config.config.base_host;
      if(this.$route.query.keyword) {
        this.keywords = [this.$route.query.keyword];
        this.getData();
      }
  },

  computed: {

    ...mapFields([
        'survey.query.filter.keywords',
        'survey.query.filter.project_id',
        'survey.query.filter.by_me',
        'survey.query.filter.my_projects'
        
    ]),
  },
  
  methods: {
    
    getData() {
        this.$emit('search');
        //this.$store.dispatch("projectSearch", this.$route.params.id);
    },

  },
  components: {
    FilterGroup,
    TaxonomySelect,
    TopicKeywordSelect,
    ProjectStageSelect
  }
}

</script>
<style lang="scss" scoped>

</style>
