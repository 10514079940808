<template lang="pug">
.main-project

    ProjectModalStandard(ref="projectModal")
    ImportModal(ref="importModal")
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu(:class="{shrink: shrink}", id="submenu")

            .container-xxl
                template(v-if="$route.params.id")            
                    .row.mt-0.no-gutters.submenu-inner
                        
                        .col-7
                             
                            .media(v-if="$store.getters.project")
                              template(v-if="$store.getters.project.custom_data && $store.getters.project.custom_data.client_url")
                                
                                  .d-inline-block
                                    Logo(:domain="$store.getters.project.custom_data.client_url", :size="25", :inline="true", :rounded="false", style).mr-2
                                  .d-inline-block.text-truncate
                                    span(style="font-size: 1.2em; line-height: 1.1em") {{$store.getters.project.name}}
                              template(v-else)
                                Logo(:name="$store.getters.project.name", :size="50", :inline="true", :rounded="false", style).mr-2
                               
                                .media-body
                                    div(v-if="$store.getters.project.deleted_at").alert.alert-danger Project is deleted
                                    template(v-if="$store.getters.project")
                                        .headline-submenu {{ $store.getters.project.name }} 
                                        //.text-muted.small(v-show="!shrink") {{ $store.getters.project.description }} 
                                     
                        .col-5(v-if="$store.getters.project")

                            .float-right
                                b-btn(variant="outline-primary", size="sm", @click="publish_project($store.getters.project)", v-if="!$store.getters.project.published_at").mr-4 Publish

                                b-btn(variant="outline-primary", size="sm", @click="join_project($store.getters.project)", v-if="!$store.getters.project.visible_members_only && $store.getters.project.member_ids.indexOf(auth.user.id) == -1").mr-4 Join project 

                                .member-list(v-if="$store.getters.project").d-inline-block.mr-3
                                    
                                    User(:user="$store.getters.project.relations.owner_id", :size="25").mr-1.d-inline
                                    label.mr-2 |
                                    span(v-for="member in $store.getters.project.relations.member_ids")
                                        User(:user="member", :size="25").d-inline


                                
                                template(v-if="$store.getters.project.member_ids.indexOf(auth.user.id) > -1")
                                    b-dropdown(id="dropdown-right" right text="" variant="outline-secondary" size="sm" class="m-2")

                                        b-dropdown-item(href="#", v-if="$auth.isInTeams", @click="$auth.openTeamChat($store.getters.project.relations.member_ids, $store.getters.project.name)") Open Team Chat
                                        b-dropdown-item(href="#", v-if="$auth.isInTeams", @click="$auth.openMeeting($store.getters.project.relations.member_ids, $store.getters.project.name)") Schedule Meeting
                                        b-dropdown-divider(v-if="$auth.isInTeams")
                                        b-dropdown-item(href="#", @click="showModal($store.getters.project)")#edit-project Edit project
                                        b-dropdown-item(href="setup") Data fields setup
                                        b-dropdown-item(@click="leave_project($store.getters.project)") Leave project
                                        b-dropdown-divider()
                                        b-dropdown-item(@click="delete_project($store.getters.project)") Delete project
                                        
                                        // b-dropdown-divider()
                                        
                                        //b-dropdown-item(href="#") Add company
                                        //b-dropdown-item(href="#", @click="showImportModal($store.getters.project)") Import companies
                                        
                                        //b-dropdown-divider()
                                        b-dropdown-item(href="#", @click="export_project($store.getters.project)", v-if="$auth.has_access('download_csv', null, 'read') && $auth.user.instance == 'stepstone'")#edit-project Export CSV
                                        //b-dropdown-item(href="#") Archive project

                template(v-if="!$route.params.id")
                    
                    .row.no-gutters.submenu-inner
                        .col-7    
                            .headline-submenu.mb-3 
                                //i(class="fas fa-project-diagram head-icon").mr-2
                                | Projects
                        .col-5
                            .float-right
                                b-btn(size="sm", variant="outline-primary", @click="showModal()")#project-add-edit Add Project

                   
                .row.mt-1(v-if="$route.params.id").no-gutters.submenu-inner

                       
                        .menu(v-if="$store.getters.project")
                            
                            b-navbar(toggleable="md" variant="link").p-0

                              b-navbar-nav.mr-auto.ml-0
                              
                                router-link(:to="'/projects/' + $store.getters.project._id + '/dashboard'").mr-5 Dashboard

                                .d-inline-block( @mouseover="onOver('dd5')", @mouseleave="onLeave('dd5')", v-if="$store.getters.project.general_use_board").mr-5
                                
                                  b-nav-item-dropdown(ref="dd5" text="Ideas" )
                                    //b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/general/report'") Overview
                                    b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/general/board'") Board
                                    //b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/general/list'") List
                                
                                HSubmenuLink(:links="[{'to': '/projects/' + $store.getters.project._id + '/board', 'name': 'Board'}, {'to': '/projects/' + $store.getters.project._id + '/list', 'name': 'List'}, {'to': '/projects/' + $store.getters.project._id + '/map', 'name': 'Map'}]", title="Companies").mr-5
                                //HSubmenuLink(:links="[{'to': '/projects/' + $store.getters.project._id + '/board', 'name': 'Board'}, {'to': '/projects/' + $store.getters.project._id + '/list', 'name': 'List'}, {'to': '/projects/' + $store.getters.project._id + '/map', 'name': 'Map'}]", title="Topics").mr-5
                                
                                  
                                //.d-inline-block( @mouseover="onOver('dd2')", @mouseleave="onLeave('dd2')").mr-5
                                  b-nav-item-dropdown(ref="dd2" text="Topics" )
                                    b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/topics'") List
                                    b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/radar'", v-if="$store.getters.project.topic_stages && $store.getters.project.topic_stages.length") Radar
                                    //b-dropdown-item(href="#") Funding Flow
                                
                              
                                    
                                //.d-inline-block( @mouseover="onOver('dd4')", @mouseleave="onLeave('dd4')").mr-5
                                  b-nav-item-dropdown(ref="dd4" text="Surveys" )
                                    b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/survey_templates'") Surveys
                                    b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/matching'") Tracking
                                    b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/surveys_standard'") Responses
                                    //b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/matching'") Matching
                            
                                    
                                .d-inline-block( @mouseover="onOver('dd3')", @mouseleave="onLeave('dd3')").mr-5
                                  b-nav-item-dropdown(ref="dd3" text="Analytics" )
                                    //b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/analytics'") Pivot
                                    b-dropdown-item(:to="'/projects/' + $store.getters.project._id + '/stage_analytics'") Stage Analytics
                                    //b-dropdown-item(href="#") Market Map
                                    //b-dropdown-item(href="#") Portfolio Analysis
                                    //b-dropdown-item(href="#") Engagement

                                .d-inline-block.mr-5
                                  router-link(:to="'/projects/' + $store.getters.project._id + '/members'") Members
                                //
                                  router-link(:to="'/projects/' + $store.getters.project.id + '/home'").mr-5 Dashboard
                                  router-link(:to="'/projects/' +  $store.getters.project._id + '/board'").mr-5 Companies


                                  router-link(:to="'/projects/' + $store.getters.project._id + '/list'").mr-5 List
                                  //router-link(:to="'/projects/' + $store.getters.project._id + '/analytics'").mr-5 Analytics
                                  router-link(:to="'/projects/' + $store.getters.project._id + '/surveys'").mr-5 Surveys
                                  router-link(:to="'/projects/' + $store.getters.project._id + '/stage_analytics'").mr-5 Stage Analytics
                                  //router-link(:to="'/projects/' + $store.getters.project._id + '/analytics'").mr-5 Save
                                  router-link(:to="'/projects/' + $store.getters.project._id + '/map'").mr-5 Map
                                  router-link(:to="'/projects/' + $store.getters.project._id + '/topics'").mr-5 Topics
                                  //router-link(:to="'/projects/' + $store.getters.project._id + '/similar'").mr-5 Discovery <span class="text-muted ml-1">12</span>
                                  //.float-right
                                      router-link(to="setup") Project Setup


                   

                                    
    .container-xxl(class="d-flex flex-column", v-if="!this.$route.params.id || this.$store.getters.project")
          .row.no-gutters.work-view.sticky-container()
              
              b-col(:cols="sidebar_visible ? 9 : 12 ")
                #affix-container
                
                  router-view(name="main").main-view
              b-col(:cols="12-mainCols", v-if="mainCols < 12")
                  
                  .mt-5
                    .sidebar-right(v-bind:class="{'visible': sidebar_visible}", id="sideright").col-3
                      .float-right
                        b-btn(variant="link", @click="toggleSidebar()", size="sm")
                          i.fas.fa-arrow-right(v-show="sidebar_visible")
                          i.fas.fa-arrow-left(v-show="!sidebar_visible")
                        //b-btn(variant="link", @click="toggleSidebar(true)", size="sm")
                          i.far.fa-question-circle
                      router-view(name="sideview").side-view(v-show="sidebar_visible")

</template>

<script>
import HSubmenuLink from "@/components/HSubmenuLink";
import Logo from '@/components/Logo';
import User from '@/components/User';
import ProjectModal from '@/components/ProjectModal';
import ProjectModalStandard from '@/components/ProjectModalStandard';
import ImportModal from '@/components/ImportModal';
import Config from "@/config";
import Vue from "vue";
import auth from '@/router/auth.js'
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'SubmenuProject',
  props: {
    title: String
  },
  

data: function () {
    return {
      auth: auth,
      shrink: false,
      mainCols: 9,
      filterCols: 2
    }
  },

  
  mounted() {
      if(this.$route.params.id) this.$store.dispatch("loadProject", this.$route.params.id);
      window.dispatchEvent(new Event('resize'));
      this.$auth.inTeams();
  },


   watch: {
    
    '$route.params.id': function () {
      this.$store.dispatch("loadProject", this.$route.params.id);
      window.dispatchEvent(new Event('resize'));
     
    },

    'selected_item': function(oldVar, newVar) {
        if(oldVar != newVar) {
            this.mainCols= 9;
             
        }
    }


  },
  



 computed: {

        ...mapFields([
            'instance_config',
            'selectedTopic',
            'sidebar_visible',
            'sidebar_project.selected_item',
            'sidebar_project.selected_list',
            'sidebar_help',
            'sidebar.selectedProjectStage',
            'project'         
        ]),
  },

 

  beforeMount() {
    this.handleDebouncedScroll = this.$_.debounce(this.handleScroll, 10);
    window.addEventListener('scroll', this.handleDebouncedScroll); 
    window.addEventListener('resize', this.handleDebouncedScroll);
   
  },


  beforeDestroy() {
      window.removeEventListener('scroll', this.handleDebouncedScroll);
      window.removeEventListener('resize', this.handleDebouncedScroll);
  },

  methods: {  
    
     onOver(dd) {
        this.$refs[dd].show();
      },
      onLeave(dd) {
        this.$refs[dd].hide();
      },

    toggleSidebar(help=false) {
      this.sidebar_visible = !this.sidebar_visible;
      this.sidebar_help = help;
      window.dispatchEvent(new Event('resize'));
    },
    handleScroll() {
      
      let el = document.getElementById("submenu");

      if(el) {
        let rect = el.getBoundingClientRect();
        let sel = document.getElementById("sideright")
        
        if(sel) {
          sel.style.top = rect.bottom +"px";
        }
        
        let searchsel = document.getElementById("searchmenu")
        let searchsel_orient = document.getElementById("searchmenu_orient")
        if(searchsel) {
         
          searchsel.style.top = rect.bottom +"px";
          searchsel.style.width = searchsel_orient.clientWidth + "px"
          //searchsel.style.height = "calc(100vh - " +searchsel.style.top + "px)"
        
        }
        
      }
      
    },
    

    delete_project(project) {
         this.$bvModal.msgBoxConfirm("Are you sure to delete this project?", {buttonSize: 'sm'}).then((value) => {
          

            if(value) {
                this.$store.dispatch("start_loading");
                Vue.http.delete(`${Config.config.base_host}/projects/${project._id}`).then(res => {
                    console.log(res);
                    location.href = "/projects";
                    this.$store.dispatch("stop_loading");
                });

            }
            
      
        });
    },

    publish_project(project) {
         this.$bvModal.msgBoxConfirm("Are you sure to publish this project?", {buttonSize: 'sm'}).then((value) => {
          

            if(value) {
                project.published_at = true;
                this.$store.dispatch("start_loading");
                Vue.http.put(`${Config.config.base_host}/projects/${project._id}`, project).then(res => {
                    console.log(res);
                    location.reload();// = "/projects";
                    this.$store.dispatch("stop_loading");
                });

            }
            
      
        });
    },

    export_project(project) {
    
        this.$store.dispatch("start_loading");
        Vue.http
            .get(Config.config.base_host + "/projects/" + project._id + "/csv")
            .then(resp => {
            
        
            var element = document.createElement("a");
                element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," + encodeURIComponent(resp.body)
                );
                element.setAttribute("download", project.name+".csv");
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

            this.$store.dispatch("stop_loading");
            });
    },

    showModal(project) {
        this.$refs.projectModal.open(project);
    },
    showImportModal(project) {
        this.$refs.importModal.open(project);
    },

    showSidebar() {
      this.mainCols=(this.mainCols==12 ? 9 : 12); 
      this.$eventHub.$emit('resize');
    },

    hideSidebar() {
      this.mainCols=(this.mainCols==12 ? 9 : 12); 
      this.$eventHub.$emit('resize');
    },



    join_project(project) {
        this.$bvModal.msgBoxConfirm("Would you like to join this project?", {buttonSize: 'sm', variant: 'outline-primary'}).then((value) => {
            
            if(value) {

                this.$store.dispatch("start_loading");
                  Vue.http.post(`${Config.config.base_host}/projects/${project._id}/membership`).then(() => {
                      location.reload();
                      this.$bvToast.toast(project.name + " has been joined", {
                        title: 'Join',
                        variant: 'outline-primary',
                        autoHideDelay: 1000,
                      });

                
                    this.$store.dispatch("stop_loading");
                  });

            }
        
        });
            
    },

     leave_project(project) {
        this.$bvModal.msgBoxConfirm("Would you like to leave this project?", {buttonSize: 'sm', variant: 'outline-primary'}).then((value) => {
            
            if(value) {

                this.$store.dispatch("start_loading");
                  Vue.http.delete(`${Config.config.base_host}/projects/${project._id}/membership`).then(() => {
                      location.reload();
                      this.$bvToast.toast(project.name + " has been joined", {
                        title: 'Join',
                        variant: 'outline-primary',
                        autoHideDelay: 1000,
                      });

                
                    this.$store.dispatch("stop_loading");
                  });

            }
        
        });
            
    },




    changed: function(event) {
      this.$store.commit('change', event)
    },
  
  },

  directives: {
    "sticky": VueStickyDirective
  },
  components: {
      Logo,
      ProjectModal,
      ProjectModalStandard,
      ImportModal,
      User,
      HSubmenuLink
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>