<template lang="pug">
  .home

    b-modal(  :id="'add-col-modal-' + this.id" :title="'Enter a name'" @ok="addNewColumn" )
      b-form-group( label="Name" label-for="col-name-input" )
        b-form-input( id="col-name-input", v-model="new_col_name" required )
    
    b-modal(  :id="'edit-col-modal-' + this.id" title="Edit the name for the column" @ok="updateColumnName" )
      b-form-group( label="Name" label-for="edit-col-name-input" )
        b-form-input( id="edit-col-name-input", v-model="edit_current_col_name", required )
    
    b-modal(  :id="'prompt-col-delete-' + this.id" title="Confirm column deletion." @ok="columnDeleteConfirmed")
      .heading.mb-2 There are cards present. Proceeding will move the cards to "Not assigned".
      //- .delete-option
      //-   input.ibm( id="delete_cards" type="radio" name="keep_cards" v-model="keep_cards" :value="true" )
      //-   label.cp.ibm.pl-2( for="delete_cards" ) Delete this column and keep the cards.
      //- .delete-option
      //-   input.ibm( id="keep_cards" type="radio" name="keep_cards" v-model="keep_cards" :value="false" )
      //-   label.cp.ibm.pl-2( for="keep_cards" ) Delete this column and delete the cards as well.

    .mt-0(v-if="$store.getters.projectRows")
      .row
        .col-12
          .float-right
            .b-form(inline).float-right
              b-input(type="search", size="sm", v-model="quick_search", placeholder="Filter...") Search

      .mt-1(style="overflow-x: auto; width: 100%; white-space: nowrap;")
        b-overlay(:show="$store.getters.loading")
          draggable(:list="init_stages" :id="'dragdrop-colset'"  @change="colChanged(init_stages.map(x => x.title))" animation="200", ghost-class="ghost-card", group="tasks1")
            b-card(v-for="stage in init_stages" no-body :header="stage.title" class="bg-gray-100 rounded-lg column-width rounded mr-4", :key="stage.title" ).d-inline-block.align-top
            
              template(v-slot:header)
                .d-flex.justify-content-between
                  b-link(@click="openEditColModal(stage)") {{stage.title}} ({{number[stage.title]}})
                  
                  div 
                    b-link(@click="removeColumn(stage.title)", title="Remove", v-b-tooltip.top).text-muted
                      i.fas.fa-times
                    template(v-if="$store.state.user.instance == 'hy' || $store.state.user.instance == 'development'")
                      b-link(@click="download({filter: {project_id_stage: [project._id + stage.title], sources: ['ecosystem'], add_low_quality_data: true}})", title="Download Excel", v-b-tooltip.top, v-if="  stage.orgs.length").text-muted.ml-2
                        i.fas.fa-download
                    
                    

              template(v-if="!stage.add") 
                b-btn(variant="link", @click="closeAdd();stage.add=true", size="sm").text-muted.form-control + Add
              template(v-else)
                  CompanySelect(:multiple="false", @input="addCompaniesToProject(stage, project._id)", v-model="stage.add_org", placeholder="Enter company name to add")
                  
              .max-height-column.column-width.more(v-infinite-scroll="orgs_by_stage(stage.title)" infinite-scroll-disabled="!has_more[stage.title]" infinite-scroll-distance="10")
                
                  draggable(:list="stage.orgs" :animation="200" style="min-height: 500px; white-space: normal;" ghost-class="ghost-card" :id="'dragdrop-'+stage.title" group="tasks", @change="changed(stage.title, $event)")
                        
                        b-card(v-for="task in stage.orgs", :key="task._id", no-body, :id="'card-'+task.organization_id", v-if="!task.deleted_at && (!quick_search || task.relations.organization_id.name.toLowerCase().indexOf(quick_search.toLowerCase()) > -1)").shadow-sm.cursor-move.m-2.p-2.card-drag
                            .media(v-if="task.relations && task.relations.organization_id")
              
                              Logo(:name="task.relations.organization_id.name", :web_entity="task.relations.organization_id.web_entity", size="20").mr-2
                              .media-body()
                                .form-row
                                  .col-10
                                    #router-link(:to="'/ecosystem/orgs/' + task.organization_id  + '/projects/' + $route.params.id")
                                    div(@click="selectOrg(task.relations.organization_id)")
                                      h6(style="vertical-align: middle;" v-bind:class="{small: project.board_show_compact}").text-nowrap.max-width-200 {{task.relations.organization_id.name}}
                                  .col-2
                                    .float-right.small()
                                      b-dropdown(id="dropdown-offset" offset="0" text="Offset Dropdown" size="sm" variant="link" no-caret).p-0.remove-project-btn
                                        template(v-slot:button-content)
                                          i.fas.fa-ellipsis-h.gray-info.p-0
                                        b-dropdown-item(size="sm" @click="delete_project_organization(task)") Remove from project
                              
                            template(v-if="project.board_show_assign_owner")
                              hr.mt-1.mb-0
                              UserSelect(v-if="task.relations", v-model="task.relations.account_owner_id", @input="(val)=>changed(stage.title, {user_id: val ? val._id : null, po: task})")

            

                  .text-center(v-if="busy[stage.title]").text-muted.small.mt-2 Loading ...

            div(style="display: inline-block").cursor-move.m-2.card-drag.mr-5.px-5.py-1
                b-btn(variant="outline-secondary", size="sm", @click="openAddColModal()") Add new column
          
              
              
            
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import CompanySelect from '@/components/CompanySelect.vue'
import UserSelect from '@/components/UserSelect.vue'
import Logo from '@/components/Logo.vue'
import draggable from "vuedraggable";

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Dashboard',

    data: function () {
    return {
        loading: {},
        page_per_stage: {},
        index_stage: {},
        has_more: {},
        cols_list: [],
        quick_search: null,
        busy: {},
        number: {},
        init_stages: [],
        mounted: false,
        new_col_name: null,
        edit_col_name: null,
        edit_current_col_name: null,
        edit_current_col: null,
        id: "board"
     }
  },

 computed: {
      
      ...mapFields([
          'project.project', 
          'instance_config',
          'sidebar_project.selected_item',
          'sidebar_visible'
      ]),
  },
  watch: {
    project (newCount) {
      this.has_more = {};
      this.page_per_stage = {};
       if(newCount) {

            this.init_stages = [];
            
           

            newCount.stages.forEach((x) => {
              this.loading[x] = false;
              this.init_stages.push({'title': x, add_org: null, add: false, orgs: []});
            });

            if(!this.init_stages.find(x => x.title == 'Not assigned')) {
              this.init_stages.push({'title': 'Not assigned', add:false, orgs: []});
            }

           

            this.init_stages.forEach((x, i)=>{
                
                this.has_more[x.title] = true;
                this.index_stage[x.title] = i;
                
                //this.orgs_by_stage(x.title, i);
            });
            this.cols_list = this.init_stages.map(x => x.title);
       }
      
    }
  },

  created: function(){
    
      this.$eventHub.$on('iframe_saved', () => {this.$store.dispatch("loadProject", this.$route.params.id)});

  },


  beforeDestroy() {

       this.$eventHub.$off('iframe_saved')
  },



  mounted: function() {
    
    this.host = Config.config.base_host;

    if(!this.init_stages.find(x => x.title == 'Not assigned')) {
        this.init_stages.push({'title': 'Not assigned', add:false, orgs: []});
    }
    
    if(this.$store.state.project.project) {
      
      

      this.$store.state.project.project.stages.forEach((x) => {
          if(!this.init_stages.find(y => y.title == x)) {
            this.init_stages.push({'title': x, add: false, orgs: []});
          }
      });

     

      this.init_stages.forEach((x, i)=>{
          this.has_more[x.title] = true;
          this.index_stage[x.title] = i;
          
          //this.orgs_by_stage(x.title, i);
      });
    }
    
    this.cols_list = this.init_stages.map(x => x.title);

    this.mounted = true;
    
  },

  methods: {

    download(filter) {
       this.downloadBusy = true;
      
      Vue.http.post(`${Config.config.base_host}/organizations/bulk_export`, filter, { responseType: 'arraybuffer'}).then((resp) => {
       
        this.downloadFile("results.xlsx", resp.body);

        this.downloadBusy = false;
      }, () => {
        this.downloadBusy = false;
      }
      );

    
    },




    downloadFile(filename, dataValue) {
      window.URL = window.webkitURL || window.URL;
      window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder;

      var a = document.createElement('a');
      a.download =  filename;
      var bb = null;
      if (window.BlobBuilder == undefined) {
          bb = new Blob([dataValue], { 'type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          a.href = window.URL.createObjectURL(bb);
      }
      else {
          bb = new window.BlobBuilder();
          bb.append(dataValue);
          a.href = window.URL.createObjectURL(bb.getBlob( "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"));
      }

      a.textContent = '';

      a.dataset.downloadurl = [ "application/octet-stream", a.download, a.href].join(':');
      a.draggable = true; // Don't really need, but good practice.
      a.classList.add('dragout');

      document.body.appendChild(a);

     

      a.click();
    },



    colChanged(eventData) {
      console.log(eventData);
  
      this.$store.state.project.project.stages = eventData;
      this.$api.save_project(this.$store.state.project.project, ( pd )=>{
        this.$store.dispatch("loadProjectProject", pd ); 
        this.cols_list = eventData;
      });
      
    } ,
    closeAdd() {

      this.init_stages.forEach((x)=>{
                
          x.add_org = null;
          x.add = false;
          
      });

    },

    openAddColModal( ){
      this.$bvModal.show('add-col-modal-' + this.id);
    },
    openEditColModal( stage ){
      this.edit_current_col = stage.title;
      this.edit_current_col_name = stage.title;
      this.$bvModal.show('edit-col-modal-' + this.id);
    },
    removeColumn( col_name ){
      

   
        this.$bvModal.msgBoxConfirm("Do you really wan't to remove this stage? All assigned companies will be moved to 'Not assigned.'", {buttonSize: 'sm'}).then((value) => {
          if(value) {
             Vue.http.put(`${Config.config.base_host}/projects/${this.project._id}/change_stage`, {"from": col_name, "to": null}).then((res) => { 
                 this.$store.dispatch("loadProjectProject", res.body);
              });          
          } 
        });

    },

    parseColsMap( col_map ){
      if( col_map ){
        this.cols_list = Object.keys( this.cols_map );
      }
      else{
        this.cols_list = this.$_.clone( this.cols );
      }

      this.cols_list.forEach( ( colName )=>{
        this.loadingMap[ colName ]      = false;
        this.addedCardMap[ colName ] = null;
      })
      
    },

    columnDeleteConfirmed(){
      if( this.column_to_be_deleted ){
        if( this.keep_cards ){
          if( this.cols_map[this.column_to_be_deleted].length ){
            this.cols_map[this.column_to_be_deleted].forEach( cardObj => {
              this.cols_map[ this.cols_list[0] ].push( cardObj );
              cardObj.stage = this.cols_list[0];

              let dataToPost = {
                          cols_map: this.cols_map,
                          action: "added_to_column",
                          card : cardObj
                        };
              this.emitColMapUpdated( dataToPost );  
            });
          }
          
        }
  
       
      }
      this.column_to_be_deleted = "";
    },

     addNewColumn(){
      if( !this.new_col_name || this.init_stages.find(x => x.title ==  this.new_col_name)){
        this.$bvToast.toast("There is already a column with the same name.", {
            title: 'Error creating column.',
            variant: 'warning',
            autoHideDelay: 2000,
        });
        return;
      }
      this.init_stages.push({'title': this.new_col_name, add: false, orgs: []});
      this.colChanged(this.init_stages.map(x => x.title));
      this.new_col_name = "";
    },

     updateColumnName(){
      if( !this.edit_current_col_name ){
        this.$bvToast.toast("Please input a valid column name to update.", {
            title: 'Error updating column name',
            variant: 'warning',
            autoHideDelay: 3000,
        });
        return;
      }
      
      Vue.http.put(`${Config.config.base_host}/projects/${this.project._id}/change_stage`, {"from": this.edit_current_col, "to": this.edit_current_col_name}).then((res) => {
        console.log(res.body);
        this.$store.dispatch("loadProjectProject", res.body ); 
        //this.colChanged(this.init_stages.map(x => x.title));
      });

      this.$forceUpdate();

    },


    generateUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },

    selectOrg(org) {
      this.sidebar_visible = true;
      console.log(org);
      this.selected_item = org;
    },

    addCompaniesToProject(stage, selectedProjectStage) {

      let selectedItem = [stage.add_org];
      let items = selectedItem.map(x => {
        return { organization_id: x._id, project_id: selectedProjectStage, stage: stage.title };
      });
      console.log(items, selectedProjectStage);
      this.$store.dispatch("start_loading");
      Vue.http
        .post(Config.config.base_host + "/project_organizations/bulk", items)
        .then(() => {
          this.$store.dispatch("deselectAll");
          this.closeAdd();
           stage.add = false;
           stage.add_org = null;
           this.page_per_stage[stage.title] = 0;
           this.init_stages[this.index_stage[stage.title]]['orgs'] = [];
           this.has_more[stage.title] = true;
           this.orgs_by_stage(stage.title);

           this.$bvToast.toast("Added company to project", {
              title: 'Added',
              variant: 'primary',
              autoHideDelay: 3000,
            });

       
          this.$store.dispatch("stop_loading");
          this.$store.dispatch("search");
        });
    },


    orgs_by_stage(stage, i = null, new_load = false){
     
      if(!this.has_more[stage] || this.busy[stage] || this.page_per_stage[stage] >= 5) return;
      if(i==null) i = this.index_stage[stage];
      this.loading[stage] = true;
      this.busy[stage] = true;
      if(!this.page_per_stage[stage]) this.page_per_stage[stage] = 0;
      this.page_per_stage[stage]++;
      return Vue.http.get(`${Config.config.base_host}/projects/${this.$route.params.id}?stage=${stage}&include_orgs=1&page=${this.page_per_stage[stage]}`).then(res => {
        if(new_load) this.init_stages[i].orgs = [];
        this.init_stages[i].orgs.push(...res.body.project_organizations);
        this.loading[stage] = false;
        this.has_more[stage] = res.body.has_more;
        
        this.busy[stage] = false;
        this.number[stage] = res.body.number;
      });
      
    },
        
    save_project_organization(po, stage_change = false){
    
      Vue.http.put(`${Config.config.base_host}/project_organizations/${po._id}`, po).then(() => {
        if(stage_change) {
           this.has_more[po.stage] = true;
       
          this.page_per_stage[po.stage] = 0;
       
          this.orgs_by_stage(po.stage, null, true);

          /*if(coming_from_stage) {
            this.has_more[coming_from_stage] = true;
            this.page_per_stage[coming_from_stage] = 0;
            this.orgs_by_stage(coming_from_stage, null, true);
          }*/

        }
        
      });
      
    }, 

     delete_project_organization(po){
      po.deleted_at = true;
      return this.save_project_organization(po);
      
    }, 

    

    changed(title, event) {
     
     

      if(event.removed) {

        //let po = event.removed.element;
      
        //let index = this.init_stages[this.index_stage[title]].orgs.indexOf(po);
        this.number[title] =  this.number[title] -1 ;
        //this.init_stages[this.index_stage[title]].orgs = this.init_stages[this.index_stage[title]].orgs.slice(index, 1);
        this.$forceUpdate();
      }

      if(event.added) {

        
        let po = event.added.element;
       
        console.log(po.stage, title, po.stage, "added");
       

        po.stage = title;

        po.board_position = event.added.newIndex;
        if(po.relations.account_owner_id && typeof po.relations.account_owner_id == "object") {
          po.account_owner_id = po.relations.account_owner_id._id;
         
        
        }
        
        this.number[title] =  this.number[title] + 1  ;
        this.save_project_organization(po, true);
       
        

      }
   


      if(event.moved) {

        let po = event.moved.element;
        console.log(po.stage, title, "moved");
        po.stage = title;
        
        
        if(po.relations.account_owner_id && typeof po.relations.account_owner_id == "object") {
          po.account_owner_id = po.relations.account_owner_id._id;
        
        
        }

        po.board_position = event.moved.newIndex;
        this.save_project_organization(po, true);

      

      }

      if(event.po) {
        let po = event.po;
        console.log("ddd", this.project.member_ids);
        po.account_owner_id = event.user_id;

         if(this.project.member_ids.indexOf(event.user_id) == -1) {

             this.$bvModal.msgBoxConfirm("Should the user been added to the project?", {buttonSize: 'sm'}).then((value) => {
          

                if(value) {
                    this.project.member_ids.push(event.user_id);
                    this.$api.save_project(this.project, ( )=>{
                      this.$store.dispatch("loadProject", this.project._id );
                      this.save_project_organization(po);
                    });
                }
            
            });

        } else {
           this.save_project_organization(po);
        }

       
      }

     
     
    }

  },

  components: {
    draggable,
    CompanySelect,
    UserSelect,
    Logo
  }
}

</script>
<style lang="scss" scoped>
@import '@/_vars.scss';


.column-width {
  min-width: 300px;
  width: 300px;

}

.max-width-200 {
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;

}

.max-height-column {
    
    max-height: calc(100vh - 380px);
    min-height: calc(100vh - 380px);

    overflow-x: hidden;
    width: 100%;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;

  background: #F7FAFC;
  border: 1px solid #4299e1;
}

.cursor-move{
  cursor: pointer;
}


.btn-group > .btn, .btn-group-vertical > .btn {
  padding: 0 !important;
  margin: 0 !important;
}


.card-drag  {
  height: 100%;
  
  .remove-project-btn {
   
    
  }
}
.card-drag:hover  {
  .remove-project-btn {
    .btn-sm {
      
    }
    display: inline-block;
  }
}

</style>