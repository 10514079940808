<template lang="pug">
.matching
  div(v-if="survey_templates && !survey_templates.length") You don't have created any surveys for this project.
  div(v-if="surveys != null && $store.state.user && project_organizations")
    
      
    b-form(style="max-width: 200px")
        b-form-group(label="Select Survey", v-if="survey_templates && survey_templates.length > 0")
           
            b-select(:options="survey_templates", value-field="_id", text-field="name", size="sm", v-model="selected_template", @input="get_templates()")

    template(v-if="survey_templates && survey_templates.length == 0")

        | You do not have created any survyes yet.
        a(:href="survey_templates") Create survey

    template(v-if="survey_templates && survey_templates.length > 0")
        .row(v-if="this.$route.path.indexOf('matching') > -1 ")
            .col-4(v-for="status in ['Considered', 'Sent', 'Responded']")
                b-card(style="min-height:500px; max-height: 500px; overflow-y: auto", no-body)
                    .p-2 
                        b {{status == "Responded" ? "Responded" : status}}
                    
                    b-list-group(v-if="surveys != null && status!='Considered'")
                        template(v-for="survey in get_group(status, surveys)")
                            b-list-group-item(:active="selected_survey == survey" button,  @click="selected_survey=survey; selected_item=survey.relations.organization_id").d-flex.justify-content-between.align-items-center.h-mouseover-parent
                                .media()
                                    b-btn-group()
                                        b-check(v-if="status=='Considered'", v-model="to_send", :value="survey")
                                    
                                    b-btn-group()
                                        b-check(v-if="status=='Responded'", v-model="to_match", :value="survey")

                                    b-btn-group()
                                        b-check(v-if="status=='Sent'", v-model="to_resend", :value="survey")

                                        .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + survey.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                                    .media-body()
                                        div {{survey.relations.organization_id.name}}
                                
                                        div.h-mouseover-hide
                                            template(v-if="survey.confirmed_at")
                                                .text-gray.small Matched {{survey.confirmed_at | utcFrom}}
                                            template(v-else-if="survey.responded_at")
                                                .text-gray.small Responded {{survey.responded_at | utcFrom}}
                                            template(v-else-if="survey.opened_at")
                                                .text-gray.small Opened {{survey.opened_at | utcFrom}}
                                            template(v-else-if="survey.sent_at")
                                                .text-gray.small Sent {{survey.sent_at | utcFrom}}
                                            template(v-else-if="survey.updated_at")
                                                .text-gray.small Created {{survey.updated_at | utcFrom}}

                                        b-link(variant="outline-secondary" size="sm", :to="'surveys_standard?survey_template_id=' + selected_template + '&survey_id=' + survey._id").small.h-mouseover-show Details
                    
                    b-list-group(v-if="surveys != null && status=='Considered'")
                        template(v-for="survey in get_group(status, surveys)")
                            b-list-group-item(:active="selected_item == survey" button,  ).d-flex.justify-content-between.align-items-center.h-mouseover-parent
                        
                                .media()

                                    b-btn-group()
                                        b-check(:disabled="!survey.email", v-model="to_send", :value="survey")
                                        .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + survey.web_entity + ') center 0% / cover no-repeat;'").mr-3
                                
                                    .media-body()
                                        span(@click="selected_item=survey") {{survey.name}}
                                        .small {{survey.email}}
                                        .small.text-danger(v-if="!survey.email") No email address
                                
                b-btn(variant="outline-primary", v-if="status=='Considered'", @click="send_surveys_first(to_send)", :disabled="to_send.length == 0").mt-1.form-control Send Surveys
                b-btn(variant="outline-primary", v-if="status=='Sent'", @click="send_surveys(to_resend, true)", :disabled="to_resend.length == 0").mt-1.form-control Resend Surveys
                //b-btn(variant="outline-primary", v-if="status=='Responded'", @click="match_surveys(to_match)", :disabled="to_match.length == 0").mt-1.form-control Match
    
    .div(v-if="isDemo()").small() Info: Survey email functionality is deactivated for demo instances.

       


</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import Survey from '@/components/Survey.vue'
import SurveyAggregation from '@/components/SurveyAggregation.vue'

import User from '@/components/User.vue'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Assessement',
  
  data: function () {
      return {
          to_send: [],
          to_match: [],
          to_resend: [],
          survey_template: null,
          survey_templates: null,
          selected_survey: null,
          selected_template: null,
          aggregation: null,
          surveys: null,
          save_selected_id: null,
          project_organizations: null,
          has_own_assessment: false,
          sort_by: 'name'
    
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();
    this.get_templates();
    this.get_orgs();
  },

  watch: {
      
        selected_template: function(val) {
          this.$router.push({query: {survey_template_id: val}});
        },
        "$route.query.survey_template_id": function() {
            this.get_templates();
            this.getData();
        }

  },

  computed: {
       
        ...mapFields([
            'sidebar_project.selected_item',
            'project.project'
        ]),
  },
  
  methods: {
      isDemo() {
          return location.href.indexOf('dm') > -1;
      },

      get_group(status, surveys) {
          
          if(status == 'Considered') return this.project_organizations;
          if(status == 'Sent') return surveys.filter(survey => survey.sent_at && !survey.responded_at);
          if(status == 'Responded') return surveys.filter(survey => survey.responded_at && !survey.confirmed_at);
          if(status == 'Matched') return surveys.filter(survey => survey.confirmed_at);
      },

      get_survey_link(survey) {
          return window.location.protocol + "//" + window.location.host + '/surveys_external/' +  survey.link_token;
      },

      send_surveys(surveys, resend = false) {
        this.$store.dispatch("start_loading");
        this.save_selected_id = this.selected_survey._id;
        Vue.http.post(`${Config.config.base_host}/surveys/send_bulk`, {"survey_ids": surveys.map(x => x._id), "resend": resend}).then(() => {
            this.getData();
            this.$store.dispatch("stop_loading");
        });
      },

        match_surveys(surveys) {
        this.$store.dispatch("start_loading");
        this.save_selected_id = this.selected_survey._id;
        Vue.http.post(`${Config.config.base_host}/surveys/match_bulk`, {"survey_ids": surveys.map(x => x._id)}).then(() => {
            this.getData();
            this.$store.dispatch("stop_loading");
        });
      },

    get_templates() {
      
        if(this.selected && this.selected._id) this.$router.replace({"path": "/projects/" + this.$route.params.id + "/survey_templates/"+this.selected._id});
        
        this.$store.dispatch("start_loading");
        this.filter = Object.assign({"project_id": [this.$route.params.id]}, this.filter);
        this.filter.project_id = [this.$route.params.id];
        Vue.http.post(`${Config.config.base_host}/survey_templates/search`, this.filter).then(res => {
            this.survey_templates = res.body;
            
            if(this.$route.query.survey_template_id) {
                this.selected_template = this.$route.query.survey_template_id;
            }

            else if(this.survey_templates && this.survey_templates.length > 0) {
                this.selected_template = this.survey_templates[0]._id;
            }
            
            this.$store.dispatch("stop_loading");
        });

      },

    get_orgs() {
     
      let params = {};
      params['project_id'] = [this.$route.params.id];
      params['limit'] = 200;
      
      this.$store.dispatch("start_loading");
      
      Vue.http
        .post(
          `${Config.config.base_host}/organizations/search`,
            {"filter": params}
        )
        .then(res => {
        
          this.project_organizations = [];
          res.body.rows.forEach(x => {
              console.log(x.index_space.surveys);
              this.project_organizations.push(x);
          });

          this.$store.dispatch("stop_loading");
       
        })
        .catch(() => {
          this.project_organizations = [];
        });

      return null;
    },


  send_surveys_first(to_send) {
      this.$store.dispatch("start_loading");
     
      Vue.http.post(`${Config.config.base_host}/surveys/send_bulk_to_orgs`, {"project_id": this.project._id, "organization_ids": to_send.map(x => x._id), "survey_template_id": this.selected_template}).then(() => {

          this.$bvToast.toast("Surveys send", {
              title: 'Saved',
              variant: 'outline-primary',
              autoHideDelay: 1000,
          });

            this.getData();
            this.get_templates();
            this.get_orgs();

          this.$store.dispatch("stop_loading");
      });
  },

    
      getData() {
        
        this.$store.dispatch("start_loading");
   

      Vue.http.post(`${Config.config.base_host}/surveys/search`, {"project_id": [this.$route.params.id], "survey_template_id": [this.$route.query.survey_template_id], "enrich": ['organization_id', 'survey_template_id']}).then(res => {
            this.surveys = res.body;

            if(this.save_selected_id) {
                try {
                    this.selected_survey = this.surveys.filter(s => this.save_selected_id == s._id)[0];
                    console.log(this.selected_survey);
                } catch {
                    this.selected_survey = null;
                }

                this.save_selected_id= null;
            } else {
                this.selected_survey = this.surveys[0];
            }

            this.has_own_assessment = this.surveys.filter(x => x.owner_id == this.$store.state.user.id).length > 0;
            this.$store.dispatch("stop_loading");
        });

 
        
      }
     
  },
  components: {
      Survey,
      SurveyAggregation,
      User
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>