<template lang="pug">
  
  .home
   
    .row.mt-1
      .col-md-3
        affix(:scroll-affix="true"  relative-element-selector="#news-list-group" :offset="{top: 100, bottom: 50}" style="width: 270px")
          NewsFilter(@reset="reset()", :show_project_select="false", :fix_project_id="$route.params.id")
    
      .col-md-9()
        //.row
            .col-12
                .float-right
                    b-select(:options="sort_options", v-model="sortBy", style="max-width: 150px", size="sm",  @change="getFeed()")
        .row
            .col-12
              
                b-overlay(:show="$store.state.loading")
                  
                    b-list-group(id="news-list-group").mt-3
                    
                        b-list-group-item(v-if="$store.getters.news.results && $store.getters.news.results.length > 0" v-for="entry in $store.getters.news.results")

                            .float-right
                              
                              b-btn(variant="link", v-if="in_newsletter(entry)", @click="remove_newsletter(entry)")
                                <i class="fas fa-bookmark"></i>
                              b-btn(variant="link", v-if="!in_newsletter(entry)", @click="add_newsletter(entry)")
                                <i class="far fa-bookmark"></i>

                            .media.mt-0(v-if="entry.doc_type == 'FeedEntryFunding'")
                              
                              a(:href="entry.link")
                                    img(:src="'https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/crunchbase.com'" class="mr-2").feed-image-news

                              .media-body(style="max-width: 800px")
                                a(:href="'/ecosystem/lookup/' + entry.source_data.related_organization.web_entity + '?source=news'", :disabled="!entry.source_data.related_organization.web_entity") 
                                  | {{entry.title}}

                                .text-muted.small(v-if="entry.text") {{entry.text}}
                                .text-muted.small(v-if="entry.investor_list") {{entry.investor_list.join(", ")}}
                                .text-muted.small {{entry.source_data.announced_on | utcFrom}} &mdash; <a :href="entry.source_data.cb_url" target="_blank">Crunchbase</a>

                            .media.mt-0(v-if="entry.doc_type == 'FeedEntryNews'")
                                
                                a(:href="entry.link")
                                    div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.source + ') center 0% / cover no-repeat;'").mr-3
                                     
                                .media-body(style="max-width: 800px")
                                    a(:href="entry.link", target="_blank")
                                        h6.mb-1 {{entry.title}}
                                        .text-muted.small {{entry.created_at | utcFrom}} &dash; {{entry.source}} 
                                        span.mr-1
                                          .badge.badge-secondary(v-if="entry.categories && entry.categories['INVESTMENT'] > 0.5") Investment
                                          .badge.badge-secondary(v-if="entry.categories && entry.categories['PARTNER'] > 0.5") Partner
                                      
                                    template(v-for="domain in entry.company_domains")
                                        label.small.d-inline-block.mr-2.mb-0 Mentions 
                                        a(:href="'https://'+domain", :alt="domain")
                                            .small.d-inline-block.mr-2 {{domain}} 
                        
                        infinite-loading(@infinite="getFeed", ref="inload")
                          <div slot="spinner">Loading...</div>
                          <div slot="no-more">No more news</div>
                          <div slot="no-results">No news yet. Add companies and topics to your Ecosystem to get releven news.</div>
                        
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import NewsFilter from '@/views/news/NewsFilter'
import OrgView from '@/components/OrgView.vue'
import InfiniteLoading from 'vue-infinite-loading';

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'home',
  
  data: function () {
    return {
        feed: [],
        busy: true,
        sort_options: [{"text": "Date", "value": "pub_date"}, {"text": "Relevance", "value": "_score"}],
        newsletter: {"items": []},
    }
  },

  mounted: function() {
    console.log(Config);
    this.host = Config.config.base_host;
    this.page = 1;
    this.$store.getters.news.results = []; 
    
    this.getNewsletter();
  },

   computed: {

        ...mapFields([
            'news.filter.q',
            'news.filter.sortBy',
            'news.filter.page'
        ]),
  },

  methods: {

    in_newsletter(entry) {
      
      return this.newsletter.items.map((it)=>{return it.link}).indexOf(entry.link) > -1;
    },

    remove_newsletter(entry) {
      this.newsletter.items = this.newsletter.items.filter(it => it.link != entry.link);
      this.save_newsletter(this.newsletter);
    },

    add_newsletter(entry) {
      console.log(entry);
      this.newsletter.items.push(entry);
      this.save_newsletter(this.newsletter);
    },

    save_newsletter(nl) {

      if(!nl) {
        nl = {"_id":  "newsletter:" + this.$store.state.user.id};
      }

      Vue.http.put(`${Config.config.base_host}/newsletters/${nl._id}`, nl).then((res) => {
           
            this.newsletter = res.body;
      });

    },

    getNewsletter(nl_id = null) {
        if(!nl_id) nl_id = "newsletter:" + this.$store.state.user.id;
        this.$store.dispatch('start_loading');
        Vue.http.get(`${Config.config.base_host}/newsletters/${nl_id}`).then((res) => {
            this.newsletter = res.body;
            this.$store.dispatch('stop_loading');
      });
    },
    
    reset() {
       if(this.$refs.inload){ 
          this.$refs.inload.stateChanger.reset(); 
      }
    },

    getFeed($state) {
              
        this.$store.dispatch("getNewsFeed", $state);
        this.page++;
        
    }

  },
  components: {
    OrgView,
    NewsFilter,
    InfiniteLoading
  }
}

</script>
<style lang="scss" scoped>


</style>