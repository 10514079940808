<template lang="pug">
  .home
    
    .row
      .col-2
        ProjectFilter()
        b-card().mt-3.shadow-sm
          b-form()
            b-form-group(label="Nodes")
              b-form-checkbox(v-for="option in options", v-model="selected", :key="option.value", :value="option.value") {{option.text}}

        b-card().mt-3.shadow-sm
          b-form()
            b-form-group(label="Cluster")
              b-select(:options="['None', 'Automatic']")
        
      .col-8
        div(ref="sigmacontainer", id="gcontainer")
      .col-2
        b-card().mt-2.shadow-sm
         
          table.table.small

            tr(v-for="(v,k) in selectedNode")
                td(width="100px") {{k}}
                td()
                  b-input(type="text", :value="v", size="sm").form-control   
        
        
        
   
      </b-form-checkbox>
    </b-form-group>
    
    
                    

</template>

<script>
// @ is an alias to /src

import Config from '@/config'

import { mapState } from 'vuex';
import ProjectFilter from '@/views/ProjectFilter'

export default {
  
  name: 'home',
  
  data: function () {
    return {
      currentPage: 0,
      s: {},
      q: "",
      query: "",
      uuid: null,
      event_results: [],
      selected: [],
      selectedNode: {},
      colors: {"retrieval": "rgba(200, 200, 200, 0.8)", "extraction": "rgba(200, 200, 200, 0.8)", "knowledge": "rgba(200, 200, 200, 0.8)", undefined: "rgba(200, 200, 200, 0.8)"},
      sizes: {"Org": 70, "Text": 10, "Topic": 20, "Website": 25, "Webpage": 15, "ImprintInfos": 10},
      current_actions: [{"label": "Remove", "name": "remove"}],
      graphName: "",
      options: [
          { text: 'Companies (Project)', value: 'company' },
          { text: 'Companies (Similar)', value: 'company_sim' },
          { text: 'Topics', value: 'topic' },
          { text: 'Investors', value: 'investor' },
          { text: 'Locations', value: 'location' }
      ]
    }
  },


  computed: mapState(['project']),
  
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'LOAD_GRAPH') {
        console.log(state);
        this.display();
      }
    });
  },


  mounted: function() {
   
    this.host = Config.config.base_host;

    this.s = new window.sigma({                                                                                      
        renderer: {                                                        
            container: "gcontainer",                                        
            type: "canvas"
        },                                                                 
        settings: {
            labelThreshold: 2,
            //minArrowSize: 1,
            minEdgeSize: 1,
            maxEdgeSize: 3,
            maxNodeSize: 100,
            edgeLabelSize: 'proportional'
       }                                                                  
    });

     this.display();
    

  },

  methods: {

     display() {

            let clusters = [];
            let height = window.innerHeight-300;
            if(!this.$refs.sigmacontainer) return;
            this.$refs.sigmacontainer.style.cssText = "height: " + (height) + "px;";
            
            this.s.kill();
    
            this.s = new window.sigma({                                                                                      
                renderer: {                                                        
                    container: "gcontainer",                                        
                    type: "canvas"
                },  

                settings: {
                    labelThreshold: 1,
                    minArrowSize: 1,
                    minEdgeSize: 1,
                    maxEdgeSize: 3,
                    maxNodeSize: 10,
                    edgeLabelSize: 'fixed'                
                }                                                                  
            });

            this.s.bind('clickNode doubleClickNode rightClickNode', (e) => {
              this.selectedNode = e.data.node.data;
              this.showNodeActions(this.selectedNode);
            });

            console.log(this.$store.getters.project.data);

            if(this.$store.getters.project.data) {
              
              for(let n of this.$store.getters.project.data) {
                  
                  let size = 10;
                    if(n.uuid.replace(/ /g, "")) {
                      
                      this.s.graph.addNode({
                            // Main attributes:
                            id: n.uuid.replace(/ /g, ""),
                            label: n.name,
                            data: n,
                            // Display attributes:
                            x: Math.random()*2,
                            y: Math.random(),
                            size: size,
                            color: "#003866"
                      });

                      if(n.cluster) {
                        if(!clusters.includes(n.cluster)) {
                          clusters.push(n.cluster);
                           this.s.graph.addNode({
                              // Main attributes:
                              id: "cluster"+n.cluster,
                              label: n.cluster,
                        
                              x: Math.random()*2,
                              y: Math.random(),
                              size: 20,
                              color: "#003866"
                            });
                        }

                        this.s.graph.addEdge({
                          // Main attributes:
                          id: Math.random(),
                          source: n.uuid.replace(/ /g, ""),
                          target: "cluster"+n.cluster,
                          weight: n.weight || 1,
                          label: "",
                          type: 'curve',
                          size: n.weight * 50,                       
                      });
                        
                      }
                  
                  }
                  
              }

            }

            /*for(let n of this.edges) {
               
                let label = "" + n.weight;
                
                this.s.graph.addEdge({
                        // Main attributes:
                        id: n.id.replace(/ /g, "") + Math.random(),
                        source: n.src.replace(/ /g, ""),
                        target: n.dst.replace(/ /g, ""),
                        weight: n.weight || 1,
                        label: label,
                        type: 'curve',
                        size: n.weight * 50,                       
                });

            }*/

            this.current_actions = [{"label": "Remove", "name": "remove"}];
          
            this.s.refresh();
            this.layoutGraph();

      },


    getData() {
        
        this.$store.dispatch("getData");
    },

     layoutGraph: function() {
          this.s.startForceAtlas2({scalingRatio: 20, iterationsPerRender: 5, minworker: true, barnesHutOptimize: false, edgeWeightInfluence: 1});
          setTimeout(()=>{
              this.s.stopForceAtlas2();
          }, 200 + this.$store.getters.project.data.length * 10);

      },


  },
  components: {
    ProjectFilter
  }
}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 700px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>