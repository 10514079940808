<template lang="pug">

  .home(v-if="project")
    .row
      .col-3
        b-card(no-body, img-top)

          b-card-body
            b {{project.name}}

            template(v-if="Array.isArray(project.custom_data.project_type)")
              template(v-for="tag in project.custom_data.project_type")
                div
                .text-muted.mr-1 {{tag}}
            template(v-else)
              .text-muted {{project.custom_data.project_type}}

            .mt-3
            template(v-for="tag in project.custom_data.tags")
              b-badge(variant="primary").mr-1 {{tag}}
            
          

            .mt-3(v-if="project.custom_data && project.custom_data.year")
              label Year
              .text-muted {{project.custom_data.year}}
        
            
            .mt-3(v-if="project.custom_data && project.custom_data.project_team")
              label Team 
              div
                span(v-for="member in project.custom_data.project_team")
                    b-badge(variant="secondary").mr-1 {{member}}
          
            .mt-3(v-if="project.custom_data && project.custom_data.project_directory")
              label Document/Folder
              div
                b-link(:href="project.custom_data.project_directory", target="_blank") {{project.custom_data.project_directory}}
            
            .mt-3(v-if="project.custom_data && project.custom_data.lop")
              label LoP 
              div
                b-link(:href="project.custom_data.lop", target="_blank") {{project.custom_data.lop}}
            
            .mt-3(v-if="project.custom_data && project.custom_data.final_document")
              label Final Document
              div
                b-link(:href="project.custom_data.final_document", target="_blank") {{project.custom_data.final_document}}
            
            .mt-3(v-if="project.custom_data && project.custom_data.project_duration")
              label Duration 
              div
                | {{project.custom_data.project_duration}} Weeks
                  
      .col-7
        b-card(no-body, img-top)

          b-card-body
            label Briefing
            .text(v-html="$store.state.project.project.description")
      
     



      
</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import TopicCard from '@/components/TopicCard.vue'
import TopicModal from '@/components/TopicModal.vue'
import TaxonomyDisplay from '@/components/TaxonomyDisplay.vue'
import User from '@/components/User.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'TopicOverview',
  
  data: function () {
      return {
        topics: [],
        feed: null,
        selected_topic: null,
        selected_org: null,
        organizations: null,
        pts: null,
        loading_organizations: false,
        pos_by_topic_ids: {},
        activity_types: {
          "comment_added": "added a new comment",
          "tags_changed": "added some tags",
          "contact_added": "added a new contact",
          "project_added": "added a new project",
          "organization_added_to_project": "added",
          "user_added_to_project": "has been added to"
        },
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    setTimeout(()=>{
      this.getData();
    }, 500);
    
    if(this.project) {
     
      this.getFeed();
    }
    this.$store.state.sidebar_project.selected_item = null;
    
  },

  computed: {
      
      ...mapFields([
          'project.project',
          'instance_config'
      ]),
  },
  watch: {
    project (newproject) {
       
       if(newproject) {
          this.$store.state.sidebar.selectedProjectStage = newproject._id;
           this.getData();
           this.getFeed();
       }
      
    }
  },


  methods: {

    get_image(project) {
      if(project.image_url_meta && project.image_url_meta.urls) return project.image_url_meta.urls.raw + '?crop=true&w=640&h=320&fit=crop'
      return 'https://images.unsplash.com/photo-1597476630301-0fa0a0cba61d?ixlib=rb-1.2.1' + '?crop=true&w=640&h=320&fit=crop'
    },


    match(topic) {
      console.log(topic);   
      //this.$store.state.query.filter.custom_data.use_case = [];
      //this.$store.state.query.filter.custom_data['use_case'].push({"op": null, "q": topic._id});
      this.$router.push({"path": "/ecosystem/list"})
    },

    select_topic(topic) {
      
      
      if(topic) {
        this.$store.state.sidebar_project.selected_item = topic;
        this.$store.state.sidebar.selectedUseCase = topic._id;
        this.getOrganizationsPt([this.$store.state.project.project._id  + '::' + topic._id]);
        this.selected_topic = topic;
      } else {
        this.getOrganizations(null);

      }
      this.selected_topic = topic;
      
      
    },

    select_org(org) {
    
      this.$store.state.sidebar_project.selected_item = org;
      this.selected_org = org;
      this.$forceUpdate();

    },

   getData() { 
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/projects/${this.$route.params.id}`).then(res => {
        this.pts = res.body.topics;
        this.pos_by_topic_ids = {};
        let pos = res.body.project_organizations;
        
        this.pts.sort((a, b) => (a.relations['topic_id'].name > b.relations['topic_id'].name) ? 1 : -1)
        this.topics_by_group = {};
        this.use_case_cluster = [];
        if(!this.pos_by_topic_ids[null]) {
          this.pos_by_topic_ids[null] = [];
        }
        this.pts.forEach(pt => {

          if(pt) {
            
                if(!this.pos_by_topic_ids[pt._id]) {
                    this.pos_by_topic_ids[pt._id] = [];
                }

                if(pt['group']){
                  
                  if(!(pt['group'] in this.topics_by_group)) {
                    if(pt.group) this.use_case_cluster.push(pt.group);
                    this.topics_by_group[pt['group']] = [];
                  }
                  this.topics_by_group[pt['group']].push(pt)
                } else {
                  
                  if(!("Not assigend" in this.topics_by_group)) {
                    this.topics_by_group["Not assigend"] = [];
                  }
                  this.topics_by_group["Not assigend"].push(pt);
                }
              }
        });
          
        pos.forEach((po) => {
          
              if(!this.pos_by_topic_ids[null]) {
                this.pos_by_topic_ids[null] = [];
              }
          
              this.pos_by_topic_ids[null].push(po);
             
              if(po.project_topic_ids && po.project_topic_ids.length > 0) {

                po.project_topic_ids.forEach((pt_id) => {
                  if(!this.pos_by_topic_ids[pt_id]) {
                    this.pos_by_topic_ids[pt_id] = [];
                  }

                  this.pos_by_topic_ids[pt_id].push(po);
                });

              }

    
        });


        this.$store.dispatch("stop_loading");
      });
    },

     getOrganizations(topic_ids) {
      this.loading_organizations = true;
      this.organizations = null;
      this.$store.dispatch("start_loading");
      let filter_params = {topic_id: topic_ids, "sources": ["ecosystem"], "project_id": [this.$store.state.project.project._id]};
      if(topic_ids == null)  {
        filter_params = {"sources": ["ecosystem"], "project_id": [this.$store.state.project.project._id]}
      }
    
      Vue.http.post(`${Config.config.base_host}/organizations/search`, {"filter": filter_params, limit: 1000}).then(res => {
        this.organizations = res.body.rows;

        this.organizations.sort((a, b) => (a.name > b.name) ? 1 : -1)
        this.$forceUpdate();
        this.$store.dispatch("stop_loading");
        this.loading_organizations = false;
      });      
    },

    getOrganizationsPt(project_topics) {
      this.loading_organizations = true;
      this.organizations = null;
      this.$store.dispatch("start_loading");
      let filter_params = {project_topic_id: project_topics, "sources": ["ecosystem"]};
      
      if(project_topics == null)  {
        filter_params = {"sources": ["ecosystem"], "project_id": [this.$store.state.project.project._id]}
      }
    
      Vue.http.post(`${Config.config.base_host}/organizations/search`, {"filter": filter_params, limit: 1000}).then(res => {
        this.organizations = res.body.rows;

        this.organizations.sort((a, b) => (a.name > b.name) ? 1 : -1)
        this.$forceUpdate();
        this.$store.dispatch("stop_loading");
        this.loading_organizations = false;
      });      
    },


    getFeed() { 
      this.$store.state.sidebar.selectedProjectStage =  this.$store.state.project.project._id;

      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/feed_query`, {project_id: this.$store.state.project.project._id}).then(res => {
        this.feed = res.body;
        this.$store.dispatch("stop_loading");
      });
    },

    showModal(topic) {
    
        this.$refs.topicModal.open(topic);
    },
  },

  components: {
      TopicCard,
      TopicModal,
      TaxonomyDisplay,
      User
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>